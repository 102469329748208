import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn } from "../../../AbstractElements";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import DatePicker from "react-datepicker";
import { Api_MeetingPlansList, Api_DeleteMeetingPlans, Api_ChangeIsDone } from "../../../api/Admin/MeetingPlans";
import MaterialReactTable from "material-react-table";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const MeetingPlansListTable = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [modaldec, setmodaldec] = useState(false);
  const [titlemodal, settitlemodal] = useState("");
  const [decmodal, setdecmodal] = useState("");
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [obj, setobj, objRef] = useState({
    id: 0,
    title: "",
    startDate: "",
    endDate: "",
  });
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      startDate: date,
    });
    LoadData(true);
  };
  const handleEndDate = (date) => {
    setendDate(date);
    setobj({
      ...obj,
      endDate: date,
    });
    LoadData(true);
  };
  useEffect(() => {
    LoadData(false);
  }, []);
  const LoadData = async (btnClick) => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      console.log(JSON.stringify(newobj));
      const response = await Api_MeetingPlansList(newobj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          if (btnClick == true) {
            toast.warning("No results found");
          }
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteMeetingPlans(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Meeting plan Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const toggle = () => setModal(!modal);
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const styless = {
    backgroundImage: `url(${blogSingle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Date: item.date.toString().substring(0, 10),
      Time: item.time,
      Title: item.title,
      Priority: item.priority,
      Status: item.isDone ? "Is Done" : null,
      Remarks: item.remarks,
    }));
    exportToExcel(customHeadings, "Meeting Plans");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
    });
    let RowNumber = 1;
    const customRows = tableData.map((item) => [
      RowNumber++,
      item.date.toString().substring(0, 10),
      item.time,
      item.title,
      item.priority,
      item.isDone ? "Is Done" : null,
      item.remarks,
    ]);
    autoTable(doc, {
      head: [["Row Number", "Date", "Time", "Title", "Priority", "Status", "Remarks"]],
      body: customRows,
    });
    doc.text("Meeting Plans", 14, 10);
    doc.save("Meeting Plans.pdf");
  };
  const columns = useMemo(() => [
    {
      header: "Date",
      size: 50,
      Cell: ({ cell }) => (
        <>
          <span>{cell.row.original.date.toString().substring(0, 10)}</span>
        </>
      ),
    },

    {
      accessorKey: "time",
      header: "Time",
      size: 50,
    },

    {
      accessorKey: "title",
      header: "Title",
    },
    {
      accessorKey: "priority",
      header: "Priority",
      size: 75,
      maxSize: 75,
    },
    {
      header: "Status",
      size: 50,
      Cell: ({ cell }) => <>{cell.row.original.isDone ? "Is Done" : null}</>,
    },
    {
      accessorKey: "remarks",
      header: "Remarks",
      size: 100,
      maxSize: 100,
    },

    {
      header: "Action",
      enableGlobalFilter: false,
      enableColumnDragging: false,
      enableExpanding: false,
      enableColumnActions: false,
      size: 100,
      Cell: ({ cell }) => (
        <>
          <span>
            <Button
              className="btn btn-success mr-5 btn-xs"
              onClick={() => {
                setdecmodal(cell.row.original.description);
                settitlemodal(cell.row.original.title);
                setmodaldec(true);
              }}
            >
              Des...
            </Button>
          </span>
          <span>
            <Button
              className="btn btn-info mr-5 btn-xs"
              onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/MeetingPlans/Edit/${cell.row.original.id}`)}
            >
              Edit
            </Button>
          </span>

          <span>
            <Button
              className="btn btn-danger btn-xs"
              onClick={() => {
                setSelectedRowID(cell.row.original.id);
                toggle();
              }}
            >
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ]);
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Meetings Plans" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label>Date From</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          dateFormat="MMMM d, yyyy"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>To</Label>
                        <DatePicker className="datepicker-here form-control" dateFormat="MMMM d, yyyy" selected={endDate} onChange={handleEndDate} />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Parameters"}</Label>
                        <InputGroup>
                          <Input name="title" className="form-control" value={obj.title} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <InputGroup>
                          <Btn
                            attrBtn={{
                              color: "success",
                              type: "button",
                              style: { marginTop: 40 },
                              onClick: () => LoadData(true),
                            }}
                          >
                            Search
                          </Btn>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableBodyCellProps={{
                        sx: (theme) => ({
                          fontSize: ".7rem",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding={false}
                      enablePagination={false}
                      enableColumnResizing={true}
                      enableColumnDragging={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modaldec}>
        <ModalHeader>{titlemodal}</ModalHeader>
        <ModalBody>
          <div className="box-readmore" dangerouslySetInnerHTML={{ __html: decmodal }}></div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => {
              setdecmodal("");
              settitlemodal("");
              setmodaldec(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default MeetingPlansListTable;
