import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Upload } from ".";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";

import useState from "react-usestateref";
import { Api_GetBlog, Api_EditBlog, Api_AddBlog } from "../../../api/Admin/Blog";
import { Api_GetBlogCategory_Parent } from "../../../api/Admin/BlogCategory";
import DeniReactTreeView from "deni-react-treeview";
const LegendAdd = () => {
  const { LegendID } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [ListTitle, setListTitle] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ParentCategoryId, setParentCategoryId] = useState(0);
  const [modalparent, setmodalparent] = useState(false);
  const [parentName, setparentName] = useState("");
  const [obj, setobj, objRef] = useState({
    id: 0,
    text: "",
    categoryID: 0,
    title: "",
    sort: 0,
  });
  const themes = ["classic"];
  useEffect(() => {
    LoadData();
    if (LegendID) {
      GetDataForEdit(LegendID);
    }
  }, []);

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_GetBlogCategory_Parent();
      setListTitle(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        setobj({
          ...obj,

          text: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;

        const response = await Api_AddBlog(backupobj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Legend has been added");

          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const backupobj = objRef.current;

        const response = await Api_EditBlog(SelectedRowID, backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
          navigate(`${process.env.PUBLIC_URL}/admin/LegendList`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onSelectItem = async (item) => {
    try {
      setparentName(item.text);
      setParentCategoryId(item.id);
      setobj({
        ...obj,
        categoryID: item.id,
      });
      setmodalparent(false);
    } catch (error) {}
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetBlog(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          text: response.data.data.text,
          title: response.data.data.title,
          categoryID: response.data.data.categoryID,
          sort: response.data.data.sort,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Advices`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      text: "",
      title: "",
      sort: 0,
    });
    setSelectedRowID(0);
  };
  const ShowUpload = async () => {
    setModal(true);
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title="Advice" Loading={Loading} />
          <Row>
            <Col md="12" className="project-list">
              <Card>
                <Row>
                  <Col md="12">
                    <Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/Admin/LegendList`}>
                      Legends
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="5">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Category"}</Label>
                        {ListTitle != null ? (
                          <Input
                            className="form-control"
                            value={parentName}
                            onClick={() => {
                              setmodalparent(true);
                            }}
                            readOnly={true}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="5">
                      <FormGroup>
                        <Label>{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="title" className="form-control" maxLength={255} value={obj.title} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="sort" className="form-control" type="number" maxLength={3} value={obj.sort} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Text"}</Label>
                        <br></br>
                        <Btn
                          attrBtn={{
                            color: "light",
                            type: "button",
                            className: "btn btn-outline-light btn-sm",
                            onClick: () => ShowUpload(),
                          }}
                        >
                          Show Files
                        </Btn>
                        <Editor
                          apiKey="97twnsawko365at3kw26izpuzdz92cln8uduxn75z1lt9zju"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            // plugins:
                            //   "directionality  ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                            // toolbar:
                            //   "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                            plugins:
                              "directionality anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                            toolbar:
                              "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                            tinycomments_mode: "embedded",

                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                          }}
                          initialValue={obj.text}
                          onChange={onInputChange}
                          name="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalHeader>Upload & Select File</ModalHeader>
        <ModalBody>
          <Upload setModal={setModal} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setModal(false)}>
            Cloase
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalparent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListTitle}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalparent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default LegendAdd;
