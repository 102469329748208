import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Api_MembersList, Api_DeleteMember, Api_GetImageByUserID } from "../../../api/Admin/Member";
import { Btn, H6, Image, H5 } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { toast } from "react-toastify";
import { URL, Version } from "../../../api/URL";
import Dropzone from "react-dropzone-uploader";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MaterialReactTable from "material-react-table";
import MemberTypeContext from "../../../_helper/MemberType/index";
const ListMembers = () => {
  const { companyID } = useParams();

  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [Loading, setLoading] = useState(false);
  const [ListCompany, setListCompany] = useState([]);
  const [SelectedMemberID, setSelectedMemberID] = useState(0);
  const [SelectCompanyID, setSelectCompanyID] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalAvatar, setmodalAvatar] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const [sumMember, setsumMember] = useState();
  const LoadData = async (id) => {
    try {
      setLoading(true);

      const response = await Api_MembersList(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setTableData(response.data.data);
        setLoading(false);
        setsumMember(response.data.data.length);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  useEffect(() => {
    if (companyID) {
      setSelectCompanyID(companyID);
      LoadData(companyID);
    } else {
      setSelectCompanyID(DefaultCompanyID);
      LoadData(DefaultCompanyID);
    }
    LoadCompanyList();
  }, []);
  const DeleteMember = async (SelectedMemberID) => {
    try {
      const response = await Api_DeleteMember(SelectedMemberID);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Member  Deleted");
        removeFromList(SelectedMemberID);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      toast.error("Error - 0");
    }
    setModal(false);
  };
  const removeFromList = async (SelectedMemberID) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== SelectedMemberID);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setSelectCompanyID(val);
      LoadData(val);
    } else {
      setTableData(null);
      setSelectCompanyID(0);
    }
  };
  const LoadCompanyList = async () => {
    const response = await Api_CompanyListBasic();
    setListCompany(response.data.data);
  };
  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Your avatar has been successfully uploaded");
        setmodalAvatar(false);

        const { data: response3 } = await Api_GetImageByUserID(SelectedMemberID);

        if (response3.data.img != "") {
          const AllData = [...tableData];
          const DataIndex = AllData.findIndex((p) => p.id == SelectedMemberID);
          const TempData = AllData[DataIndex];
          TempData.imageName = response3.data.img;
          AllData[DataIndex] = TempData;
          setTableData(AllData);
        }
        console.log(JSON.stringify(response3));
      } else {
        toast.error("Your avatar could not be loaded");
      }
    }
  };
  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");

    return {
      url: `${URL}${Version}/Users/UploadImage/${SelectedMemberID}`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };
  const toggle = () => setModal(!modal);
  const columns = useMemo(
    () => [
      {
        header: "Image",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <Image
            attrImage={{
              className: "img-40 rounded-circle",
              alt: "",
              src: `${cell.row.original.imageName}`,
              onClick: () => {
                setmodalAvatar(true);
                setSelectedMemberID(cell.row.id);
              },
            }}
          />
        ),
      },
      { header: "Name", accessorKey: "name", Cell: ({ cell }) => cell.row.original.name + " " + cell.row.original.family },
      {
        accessorKey: "companyDepartmentDescription",
        header: "Department",
      },
      {
        accessorKey: "jobPositionDescription",
        header: "Position",
      },
      {
        accessorKey: "personalCode",
        header: "User Code",
      },
      {
        accessorKey: "email",
        header: "Mail Box",
      },
      {
        accessorKey: "statue",
        header: "Status",
        Cell: ({ cell }) =>
          cell.row.original.status == true ? <span className="txt-success">Active</span> : <span className="txt-danger">Inactive</span>,
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/User/edit/${cell.row.id}`)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedMemberID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Users Management" title="Users List" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label>
                    {(companyID && ListCompany != "" && SelectCompanyID > 0) || ListCompany != "" ? (
                      <Input
                        type="select"
                        name="select"
                        className="form-control digits"
                        defaultValue={SelectCompanyID}
                        onChange={(txt) => {
                          onChangeCompany(txt.target.value);
                        }}
                      >
                        <option value={0} key={0}>
                          Select...
                        </option>
                        {ListCompany != ""
                          ? ListCompany.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })
                          : null}
                      </Input>
                    ) : null}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != "" && tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumMember}</Label>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      enableRowNumbers
                      rowNumberMode="static"
                      tableInstanceRef={tableInstanceRef}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>Are you sure?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => DeleteMember(SelectedMemberID)}>
              Yes
            </Button>
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modalAvatar}>
          <ModalBody>
            <Row>
              <Col sm="12">
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  accept="image/*"
                  inputContent="Drop files here or click to upload."
                  styles={{
                    dropzone: { width: "100%", height: 50 },
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setmodalAvatar(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    </Fragment>
  );
};

export default ListMembers;
