import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn } from "../../../AbstractElements";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { Api_TelBookList, Api_DeleteTelBook, Api_CheckAccess } from "../../../api/Admin/TelBook";
import MaterialReactTable from "material-react-table";
const CompanyTelBook = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const tableInstanceRef = useRef(null);
  const [rowSelection, setRowSelection] = useState({});
  const [obj, setobj, objRef] = useState({
    id: 0,
    name: "",
    remarks: "",
    position: "",
    officeName: "",
    telBookCategory: "CompanyTelBook",
    companyID: DefaultCompanyID,
  });
  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
  });
  useEffect(() => {
    LoadCompanyList();
    ActionBtn();
    LoadData(false);
  }, []);
  const LoadData = async (btnClick) => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      const response = await Api_TelBookList(newobj);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          if (btnClick == true) {
            toast.warning("No results found");
          }
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const ActionBtn = async () => {
    try {
      const response = await Api_CheckAccess("d10");

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteTelBook(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Company Tel Book Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const toggle = () => setModal(!modal);
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const styless = {
    backgroundImage: `url(${blogSingle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "companyName",
        header: "Company",
      },
      {
        header: "Name        Family",

        Cell: ({ cell }) => (
          <>
            <span>{cell.row.original.name}</span>
            {cell.row.original.family != "" ? " " + cell.row.original.family : null}
          </>
        ),
      },

      {
        accessorKey: "position",
        header: "Position",
      },

      {
        accessorKey: "officeTell",
        header: "OfficeTell",
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },

      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        enableHiding: true,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/TelBook/Edit/${obj.telBookCategory}/${cell.row.id}`)}
              >
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const columnsLimit = useMemo(() => [
    {
      accessorKey: "companyName",
      header: "Company",
    },
    {
      header: "Name        Family",

      Cell: ({ cell }) => (
        <>
          <span>{cell.row.original.name}</span>
          {cell.row.original.family != "" ? " " + cell.row.original.family : null}
        </>
      ),
    },

    {
      accessorKey: "position",
      header: "Position",
    },

    {
      accessorKey: "officeTell",
      header: "OfficeTell",
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
    },
    {
      accessorKey: "remarks",
      header: "Remarks",
    },
  ]);
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Company Tel. Books" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Name or Family"}</Label>
                        <InputGroup>
                          <Input name="name" className="form-control" value={obj.name} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Position"}</Label>
                        <InputGroup>
                          <Input name="position" className="form-control" value={obj.position} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Remarks"}</Label>
                        <InputGroup>
                          <Input name="remarks" className="form-control" value={obj.remarks} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <InputGroup>
                          <Btn
                            attrBtn={{
                              color: "success",
                              type: "button",
                              style: { marginTop: 40 },
                              onClick: () => LoadData(true),
                            }}
                          >
                            Search
                          </Btn>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={objShowBtnAction.edit == true ? columns : columnsLimit}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      enableColumnDragging={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CompanyTelBook;
