import React, { useEffect, Fragment, useContext } from "react";
import { Btn, Spinner, Ribbon } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Api_CompanyListBasic, Api_CompanyCode } from "../../../api/Admin/Company";
import { Api_ContractsList } from "../../../api/Admin/ContractsType";
import { Api_ProjectsList, Api_GetProjectCode } from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import { Api_ProducerCompanyList } from "../../../api/Admin/ProducerCompany";
import { ContractTableList } from ".";
import { Api_Search } from "../../../api/Admin/Contracts";
import { Api_CurrencyList, Api_NationalList } from "../../../api/Admin/National";
import { Api_GetUserAccessSecForDoc } from "../../../api/Admin/UserSecurityAccess";
import MemberTypeContext from "../../../_helper/MemberType/index";
const ContractReport = () => {
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [BreadcrumbsTitle, setBreadcrumbsTitle] = useState("");
  const [baMod, setbaMod] = useState("Bid");
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [disabledFiled, setdisabledFiled] = useState(false);
  const [securityList, setsecurityList] = useState([]);
  const [LisCurrency, setLisCurrency] = useState([]);
  const [NationalList, setNationalList] = useState([]);
  const [ContractTypeList, setContractTypeList] = useState([]);
  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    title: "",
    remarks: "",
    currency: "",
    contractTypeId: 0,
    position: "All",
    confrim: "Yes",
    contractStatus: "All",
    producerID: 0,
    producerode: "",
    duration: "",
    companyCode: "",
    projectCode: "",
    plantCode: "",
    kindCode: "P",
    characterCode: "B",
    security: "All",
    contractKindType: "All",
    partner: "",
    nationalID: "",
    costCenter: "",
    costCenterCode: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        LoadCompanyList();
        LoadCurrencyList();
        LoadNationalList();
        LoadContractTypeList();
        AllAccessSec();
        setBreadcrumbsTitle("Contract Management Report");
        onChangeCompany(obj.companyID, true);
      } catch (err) {}
    };

    fetchData();
  }, []);

  const AllAccessSec = async () => {
    try {
      setLoading(true);
      const response = await Api_GetUserAccessSecForDoc();

      setsecurityList(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("Api_GetUserAccessSecForDoc error:" + error);
      setLoading(false);
    }
  };
  const LoadContractTypeList = async () => {
    try {
      setLoading(true);
      const response = await Api_ContractsList();
      setContractTypeList(response.data.data);

      setLoading(false);
    } catch (error) {}
  };
  //Company
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);

      LoadProducerList(obj.companyID);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val, IsSetObj) => {
    LoadProjectList(val);
    LoadPlantList(val);
    GetCompanyCode(val, IsSetObj);
    LoadProducerList(val);
  };
  const GetCompanyCode = async (id, IsSetObj) => {
    try {
      if (id > 0 && IsSetObj == true) {
        const response = await Api_CompanyCode(id);
        setobj({
          ...obj,
          companyID: id,
          companyCode: response.data.data.code,
          projectID: 0,
          plantID: 0,
        });
      }
    } catch (error) {}
  };
  const onChangeContractType = async (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        contractTypeId: val,
      });
    } else {
      setobj({
        ...obj,
        contractTypeId: 0,
      });
    }
  };
  //---Company

  //Project
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeProject = async (val) => {
    if (val > 0) {
      const response = await Api_GetProjectCode(val);
      setobj({
        ...obj,
        projectID: val,
        projectCode: response.data.data.code,
        plantID: 0,
        plantCode: "",
      });
    } else {
      setobj({
        ...obj,
        projectID: 0,
        plantID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };
  //---Project

  //Plannt
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangePlant = async (val) => {
    if (val > 0) {
      const response = await Api_PlantsCode(val);
      setobj({
        ...obj,
        plantID: val,
        plantCode: response.data.data.code,
        projectID: 0,
        projectCode: "",
      });
    } else {
      setobj({
        ...obj,
        plantID: 0,
        projectID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };

  const LoadCurrencyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CurrencyList();

      setLisCurrency(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadNationalList = async () => {
    try {
      setLoading(true);
      const response = await Api_NationalList();
      setNationalList(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async (val) => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(val);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };

  const onChangeKind = (val) => {
    if (val == "Public") {
      setobj({
        ...obj,
        kindCode: "P",
        contractKindType: val,
      });
    } else if (val == "TowStages") {
      setobj({
        ...obj,
        kindCode: "T",
        contractKindType: val,
      });
    } else if (val == "Limited") {
      setobj({
        ...obj,
        kindCode: "L",
        contractKindType: val,
      });
    } else if (val == "F") {
      setobj({
        ...obj,
        kindCode: "F",
        contractKindType: val,
      });
    } else {
      setobj({
        ...obj,
        kindCode: "All",
        contractKindType: 0,
      });
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const Search = async () => {
    try {
      setLoading(true);

      const response = await Api_Search(obj);
      console.log(JSON.stringify(response));
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          toast.error("No  matching your search was found");
          setTableData(null);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (error) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };
  if (Loading == false) {
    return (
      <Fragment>
        <Container fluid={true}>
          <Breadcrumbs parent="Contract Management" title={BreadcrumbsTitle} Loading={Loading} />
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Company"}</Label>{" "}
                        <Input
                          type="select"
                          name="companyID"
                          className="form-control"
                          value={obj.companyID}
                          disabled={disabledFiled ? true : false}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value, true);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Project"}</Label>
                        <Input
                          type="select"
                          name="projectID"
                          disabled={disabledFiled ? true : false}
                          className="form-control"
                          value={obj.projectID != null ? obj.projectID : 0}
                          onChange={(txt) => {
                            onChangeProject(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListProject != null
                            ? ListProject.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Plant"}</Label>
                        <Input
                          type="select"
                          name="plantID"
                          disabled={disabledFiled ? true : false}
                          className="form-control"
                          value={obj.plantID != null ? obj.plantID : 0}
                          onChange={(txt) => {
                            onChangePlant(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListPlant != null
                            ? ListPlant.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Contract Type"}</Label>
                        <Input
                          type="select"
                          name="contractTypeId"
                          disabled={disabledFiled ? true : false}
                          className="form-control"
                          value={obj.contractTypeId != null ? obj.contractTypeId : 0}
                          onChange={(txt) => {
                            onChangeContractType(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ContractTypeList != null
                            ? ContractTypeList.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Kind"}</Label>

                        <Input
                          type="select"
                          name="contractKindType"
                          className="form-control"
                          value={obj.contractKindType}
                          onChange={(txt) => {
                            onChangeKind(txt.target.value);
                          }}
                        >
                          <option value="All" key="All">
                            All
                          </option>
                          <option value="Public" key="Public">
                            Public
                          </option>
                          <option value="TowStages" key="TowStages">
                            Tow Stages
                          </option>
                          <option value="Limited" key="Limited">
                            Limited
                          </option>
                          <option value="LeavingFormalities" key="LeavingFormalities">
                            Leaving Formalities
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="8"></Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{"Contract Titile"}</Label>

                        <InputGroup>
                          <Input
                            name="contractTitle"
                            maxLength={255}
                            className="form-control"
                            value={obj.contractTitle || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Currency"}</Label>
                        <Input
                          type="select"
                          name="currency"
                          disabled={disabledFiled ? true : false}
                          className="form-control"
                          value={obj.currency}
                          onChange={onObjChange}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {LisCurrency != null
                            ? LisCurrency.map((item) => {
                                return (
                                  <option key={item.abbreviation} value={item.abbreviation}>
                                    {item.abbreviation}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Priority"}</Label>

                        <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                          <option value="All" key="All">
                            All
                          </option>
                          <option value="Top" key="Top">
                            Top
                          </option>
                          <option value="Medium" key="Medium">
                            Medium
                          </option>
                          <option value="Low" key="Low">
                            Low
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Security"}</Label>

                        <Input type="select" name="security" className="form-control" value={obj.security} onChange={onObjChange}>
                          <option value="All" key="All">
                            All
                          </option>
                          {securityList != null && securityList != ""
                            ? securityList.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Partner"}</Label>
                        <InputGroup>
                          <Input
                            name="partner"
                            type="text"
                            maxLength={100}
                            className="form-control"
                            value={obj.partner || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Nationality"}</Label>
                        <Input
                          type="select"
                          name="nationalID"
                          disabled={disabledFiled ? true : false}
                          className="form-control"
                          value={obj.nationalID != null ? obj.nationalID : 0}
                          onChange={onObjChange}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {NationalList != null
                            ? NationalList.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Duration"}</Label>
                        <InputGroup>
                          <Input
                            name="duration"
                            type="number"
                            maxLength={7}
                            className="form-control"
                            value={obj.duration || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Position"}</Label>

                        <Input type="select" name="position" className="form-control" value={obj.position} onChange={onObjChange}>
                          <option value="All" key="All">
                            All
                          </option>
                          <option value="Active" key="Active">
                            Active
                          </option>
                          <option value="Inactive" key="Inactive">
                            Inactive
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Status"}</Label>

                        <Input type="select" name="contractStatus" className="form-control" value={obj.contractStatus} onChange={onObjChange}>
                          <option value="All" key="All">
                            All
                          </option>
                          <option value="NotStarted" key="NotStarted">
                            Not Started
                          </option>
                          <option value="Hold" key="Hold">
                            Hold
                          </option>
                          <option value="InProgress" key="InProgress">
                            In Progress
                          </option>
                          <option value="Finished" key="Finished">
                            Finished
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Confrim"}</Label>
                        <label className="txt-danger Required">{"*"}</label>
                        <Input type="select" name="confrim" className="form-control" value={obj.confrim} onChange={onObjChange}>
                          <option value="Yes" key="Yes">
                            Yes
                          </option>
                          <option value="No" key="No">
                            No
                          </option>
                          <option value="Waiting" key="Waiting">
                            Waiting
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Producer"}</Label>

                        <Input
                          type="select"
                          name="producerID"
                          className="form-control"
                          value={obj.producerID == null ? 0 : obj.producerID}
                          onChange={onObjChange}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListProducer != null
                            ? ListProducer.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Producer  Code"}</Label>
                        <InputGroup>
                          <Input
                            name="producerode"
                            maxLength={255}
                            className="form-control"
                            value={obj.producerode || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm="2">
                      <FormGroup>
                        <Label>{"User  Code"}</Label>
                        <InputGroup>
                          <Input
                            name="userCode"
                            maxLength={100}
                            className="form-control"
                            value={obj.userCode || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Cost Center"}</Label>
                        <InputGroup>
                          <Input
                            name="costCenter"
                            maxLength={100}
                            className="form-control"
                            value={obj.costCenter || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Cost Center  Code"}</Label>
                        <InputGroup>
                          <Input
                            name="costCenterCode"
                            maxLength={100}
                            className="form-control"
                            value={obj.costCenterCode || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Bid , Aucation Code"}</Label>
                        <InputGroup>
                          <Input
                            name="bidAucationCode"
                            maxLength={50}
                            className="form-control"
                            value={obj.bidAucationCode || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Btn
                          attrBtn={{
                            color: "success",
                            className: "m-r-15",
                            type: "button",
                            disabled: LoadingBtn,
                            onClick: () => Search(),
                          }}
                        >
                          {Loading ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Search"
                          )}
                        </Btn>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null && tableData != "" ? <ContractTableList tableData={tableData} titlepage="Contracts Reports" /> : null}
        </Container>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default ContractReport;
