import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Btn, H6, Image, P, Spinner, LI, UL, H5 } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, CardHeader } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import useState from "react-usestateref";
import { Api_EditBulletinBoard, Api_AddBulletinBoard, Api_GetBulletinBoard } from "../../../api/Admin/BulletinBoard";

const BulletinBoardAdd = () => {
  const { bulletinID, lang } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListCompany, setListCompany] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [deflanguage, setdeflanguage] = useState("en");
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [obj, setobj, objRef] = useState({
    id: 0,
    title: "",
    remarks: "",
    companyID: DefaultCompanyID,
    priority: "Medium",
    dateExpire: "",
    description: "",
    language: deflanguage,
  });

  useEffect(() => {
    LoadCompanyList();
    if (lang) {
      setdeflanguage(lang);
    }
    if (bulletinID) {
      GetDataForEdit(bulletinID);
    } else {
      const newDate = new Date(startDate.getTime());
      newDate.setDate(startDate.getDate() + 7);
      setstartDate(newDate);
      setobj({
        ...obj,
        dateExpire: newDate,
      });
    }
  }, []);
  const [startDate, setstartDate] = useState(new Date());
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      dateExpire: date,
    });
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
    }
  };

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        setobj({
          ...obj,
          description: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;
        const response = await Api_AddBulletinBoard(backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Bulletin has been added");

          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        setobj({
          ...obj,
          description: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;
        const response = await Api_EditBulletinBoard(SelectedRowID, backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetBulletinBoard(id);
      console.log(response.data.data.title);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          title: response.data.data.title,
          remarks: response.data.data.remarks,
          id: response.data.data.id,
          priority: response.data.data.priority,
          companyID: response.data.data.companyID,
          dateExpire: response.data.data.dateExpire,
          description: response.data.data.description,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const ResetForm = async () => {
    setobj({
      ...obj,
      title: "",
      remarks: "",
      priority: "Medium",
      description: "",
    });
    setSelectedRowID(0);
    navigate(`${process.env.PUBLIC_URL}/admin/BulletinBoard`);
  };

  return (
    <Fragment>
      <Form className={"theme-form" + " " + "form-" + deflanguage} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title="Bulletin Board" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="card-no-border">
                  <div className="header-top">
                    <div className="icon-box onhover-dropdown">
                      <span>{deflanguage}</span>
                      <div className="icon-box-show onhover-show-div w-100">
                        <UL>
                          <LI attrLI={{ onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/BulletinBoard/Add`) }}>
                            <span>en</span>
                          </LI>
                          <LI attrLI={{ onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/BulletinBoard/Add/fa`) }}>
                            <span>fa</span>
                          </LI>
                        </UL>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Remarks"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input
                            name="remarks"
                            className="form-control input-rtl"
                            maxLength={255}
                            onChange={onObjChange}
                            value={obj.remarks}
                            required
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Priority"}</Label>
                        <label className="txt-danger Required">{"*"}</label>
                        <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                          <option value="Top" key="Top">
                            Top
                          </option>
                          <option value="Medium" key="Medium">
                            Medium
                          </option>
                          <option value="Low" key="Low">
                            Low
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>Expire Date</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          dateFormat="MMMM d, yyyy"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="title" className="form-control input-rtl" value={obj.title} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <Editor
                          apiKey="97twnsawko365at3kw26izpuzdz92cln8uduxn75z1lt9zju"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            // plugins:
                            //   "directionality  ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                            // toolbar:
                            //   "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                            plugins:
                              "directionality anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                            toolbar:
                              "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                            tinycomments_mode: "embedded",

                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                          }}
                          initialValue={obj.description}
                          onChange={onInputChange}
                          name="description"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default BulletinBoardAdd;
