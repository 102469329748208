import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import {
  Api_DeleteContractFile,
  Api_AddContractFile,
  Api_EditContractFile,
  Api_GetContractFile,
  Api_ContractFileList,
} from "../../../api/Admin/ContractFiles";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";

const DirectAdd = ({ contractsID, ViewFileBA, documentType }) => {
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ContractDate, setContractDate] = useState(new Date());

  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    title: "",
    remarks: "",
    docDate: ContractDate,
    producerCode: "",
    userCode: "",
    systemCode: "",
    contractMID: contractsID,
    bidAuctionID: "",
    documentType: documentType,
    lettersType: "TheContractNotification",
    paymentsType: "AdvancePayment",
  });

  useEffect(() => {}, []);
  const onChangeLetterType = async (event) => {
    setobj({
      ...obj,
      lettersType: event.target.value,
    });
  };
  const onChangePaymentType = async (event) => {
    setobj({
      ...obj,
      paymentsType: event.target.value,
    });
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const handleDate = (date) => {
    setContractDate(date);
    setobj({
      ...obj,
      date: date,
    });
  };
  const toggle = () => setModal(!modal);

  const onSubmitRisk = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddContractFile(obj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A Row has been added");
          ViewFileBA();
          setobj({
            ...obj,
            title: "",
            remarks: "",
            date: "",
            producerCode: "",
            userCode: "",
            systemCode: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const response = await Api_EditContractFile(SelectedRowID, obj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ViewFileBA();
          setobj({
            ...obj,
            title: "",
            remarks: "",
            date: "",
            producerCode: "",
            userCode: "",
            systemCode: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
      console.log(error);
    }
  };

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteContractFile(id, "");
      setModal(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Risk Is Removed");
        ViewFileBA();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
        setModal(false);
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetContractFile(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          remarks: response.data.data.remarks,
          datesType: response.data.data.datesType,
          date: response.data.data.date,
          id: response.data.data.id,
          contractID: response.data.data.contractMId,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      remarks: "",
      id: 0,
    });
    setSelectedRowID(0);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
      },

      {
        accessorKey: "systemCode",
        header: "System Code",
      },
      {
        accessorKey: "producerCode",
        header: "Producer Code",
      },
      {
        accessorKey: "userCode",
        header: "User Code",
      },
      {
        header: "Date",
        Cell: ({ cell }) => (
          <>
            <span>{cell.row.original.date.toString().substring(0, 10)}</span>
          </>
        ),
      },

      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label>{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
              <InputGroup>
                <Input name="title" autoComplete="off" className="form-control" value={obj.title} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          {documentType == "Letter" ? (
            <Col sm="6">
              <FormGroup>
                <Label>{"Type"}</Label> <label className="txt-danger Required">{"*"}</label>
                <Input type="select" name="lettersType" className="form-control" onChange={onChangeLetterType}>
                  <option value="TheContractNotification" key="TheContractNotification">
                    The Contract Notification
                  </option>
                  <option value="TheContractCommencementDate" key="TheContractCommencementDate">
                    The Contract Commencement Date
                  </option>
                  <option value="TheContractChangeScope" key="TheContractChangeScope">
                    The ContractChange Scope
                  </option>
                  <option value="TheContractPayments" key="TheContractPayments">
                    The Contract Payments
                  </option>
                  <option value="TheContractFinishedWork" key="TheContractFinishedWork">
                    The Contract Finished Work
                  </option>
                  <option value="TheContractHandover" key="TheContractHandover">
                    The Contract Handover
                  </option>
                  <option value="Others" key="Others">
                    Others
                  </option>
                </Input>
              </FormGroup>
            </Col>
          ) : null}
          {documentType == "Payments" ? (
            <Col sm="6">
              <FormGroup>
                <Label>{"Type"}</Label> <label className="txt-danger Required">{"*"}</label>
                <Input type="select" name="paymentsType" className="form-control" onChange={onChangePaymentType}>
                  <option value="AdvancePayment" key="AdvancePayment">
                    Advance Payment
                  </option>
                  <option value="InvoicePayment" key="InvoicePayment">
                    Invoice Payment
                  </option>
                  <option value="SettiementPayment" key="SettiementPayment">
                    Settiement Payment
                  </option>
                  <option value="Others" key="Others">
                    Others
                  </option>
                </Input>
              </FormGroup>
            </Col>
          ) : null}
          <Col sm="6">
            <FormGroup>
              <Label>{"System Code"}</Label>
              <InputGroup>
                <Input name="systemCode" autoComplete="off" className="form-control" value={obj.systemCode} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label>{"Producer Code"}</Label>
              <InputGroup>
                <Input name="producerCode" autoComplete="off" className="form-control" value={obj.producerCode} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label>{"User Code"}</Label>
              <InputGroup>
                <Input name="userCode" autoComplete="off" className="form-control" value={obj.userCode} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label>Date</Label>
              <label className="txt-danger Required">{"*"}</label>
              <DatePicker className="datepicker-here form-control" dateFormat="MMMM d, yyyy" selected={ContractDate} onChange={handleDate} />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <Label>{"Remarks"}</Label>
              <InputGroup>
                <Input name="remarks" autoComplete="off" className="form-control" value={obj.remarks} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {SelectedRowID == 0 ? (
              <Button type="button" onClick={() => onSubmitRisk()} className="btn btn-success btn-md">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : (
                  "Add"
                )}
              </Button>
            ) : (
              <>
                <Button onClick={() => onSubmitRisk()} type="button" className="btn btn-success btn-md m-r-15">
                  {LoadingBtn ? (
                    <div className="loader-box2">
                      <Spinner attrSpinner={{ className: "loader-7" }} />
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
                {LoadingBtn == false ? (
                  <Btn
                    attrBtn={{
                      color: "light",
                      type: "button",
                      onClick: () => ResetForm(),
                    }}
                  >
                    Cancel
                  </Btn>
                ) : null}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DirectAdd;
