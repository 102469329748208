import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { Api_FilesList, Api_DeleteFile } from "../../../api/Admin/Blog";
import { H6, P, Image, H5, UL, LI, Btn } from "../../../AbstractElements";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { URL, Version } from "../../../api/URL";
import { CopyToClipboard } from "react-copy-to-clipboard";
const Upload = ({ setModal }) => {
  const [filelist, setfilelist] = useState(null);
  const [modalDelete, setmodalDelete] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = async () => {
    try {
      const response = await Api_FilesList();
      setfilelist(response.data.data);
    } catch (error) {}
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteFile(id);
      LoadData();
      setSelectedRowID(0);
      setmodalDelete(false);
    } catch (error) {}
  };
  const handleChangeStatus = async ({ meta, file, xhr, remove }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        remove();
        LoadData();
      } else {
        toast.error("Document could not be loaded");
      }
    }
  };
  const getUploadParams = async ({ meta }) => {
    try {
      const token = await localStorage.getItem("token");

      return {
        url: `${URL}${Version}/Blog/Upload`,
        headers: { Authorization: `Bearer ${token}` },
      };
    } catch (error) {
      toast.error("The file could not be uploaded");
    }
  };
  const Action = async (event) => {
    event.preventDefault();
  };
  const [state, setState] = useState({
    value: "",
    copied: false,
  });
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          {filelist != null ? (
            <UL>
              {filelist.map((item) => {
                return (
                  <LI
                    key={item.filename}
                    attrBtn={{
                      color: "light",
                      type: "button",
                      key: item.filename,
                    }}
                    attrLI={{ onClick: (event) => Action(event), className: "list-group-item-action", tag: "a", href: "#" }}
                  >
                    <CopyToClipboard
                      text={item.fileAddress}
                      onCopy={() => {
                        setState({ copied: true });
                        setModal(false);
                        toast.success("The file address was copied to the clipboard");
                      }}
                    >
                      <button className="btn btn-outline-light btn-sm">
                        <i className="fa fa-copy"></i> {item.filename}
                      </button>
                    </CopyToClipboard>
                    <i
                      onClick={() => {
                        setSelectedRowID(item.filename);
                        setmodalDelete(true);
                      }}
                      className="fa fa-trash float-right txt-danger"
                    ></i>
                  </LI>
                );
              })}
            </UL>
          ) : null}
        </Col>
        <Col>
          <Col sm="12">
            <FormGroup>
              <div className="dz-message needsclick">
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  multiple={true}
                  accept=".jpg,.jpeg"
                  inputContent="Drop files here or click to upload."
                  styles={{
                    dropzone: { width: "100%" },
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
              </div>
            </FormGroup>
          </Col>
        </Col>
      </Row>
      <Modal isOpen={modalDelete}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button
            color="light"
            onClick={() => {
              setSelectedRowID(0);
              setmodalDelete(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default Upload;
