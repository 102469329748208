import axios from "axios";
import { URL, Version } from "../URL";

const Api_BulletinBoardList = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/BulletinBoard/GetAll/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_BulletinBoardGetForDashboard = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/BulletinBoard/GetForDashboard/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddBulletinBoard = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/BulletinBoard`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetBulletinBoard = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BulletinBoard/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditBulletinBoard = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/BulletinBoard?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteBulletinBoard = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/BulletinBoard/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};
const Api_CheckAccess = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BulletinBoard/CheckAccess`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_BulletinBoardGetForDashboard,
  Api_CheckAccess,
  Api_DeleteBulletinBoard,
  Api_EditBulletinBoard,
  Api_GetBulletinBoard,
  Api_BulletinBoardList,
  Api_AddBulletinBoard,
};
