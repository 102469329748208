import React, { Fragment, useEffect, useState, useContext } from "react";
import { Bell, X } from "react-feather";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationData } from "../../../Data/LayoutData";
import { HeaderNotificationSvg } from "../../../Data/svgIcons";
import { H3, H5, LI, P, UL, H6 } from "../../../AbstractElements";
import { Api_ListAdvice } from "../../../api/Admin/UserSeeEvents";
import MemberTypeContext from "../../../_helper/MemberType/index";
const LastAdvice = () => {
  const { noti } = useContext(MemberTypeContext);
  const [tableData, setTableData] = useState(null);
  const [tablecount, settablecount] = useState(null);
  useEffect(() => {
    LoadData();
  }, [noti.notiAdvice]);

  const LoadData = async () => {
    try {
      const response = await Api_ListAdvice();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data.list);
          settablecount(response.data.data.count);
        } else {
          settablecount(0);
        }
      }
    } catch (error) {}
  };
  return tablecount != null ? (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown" }}>
        <div className="notification-box">
          <i className="icofont icofont-chat"></i>
          <span className="badge rounded-pill badge-warning">{tablecount}</span>
        </div>
        <div className={`onhover-show-div notification-dropdown`}>
          <div className="dropdown-title">
            <H3>Advices</H3>
            <a className="f-right" href="#javascript">
              <Bell />
            </a>
          </div>
          <UL attrUL={{ className: "simple-list custom-scrollbar" }}>
            {tableData != "" ? (
              tableData.map((item) => {
                return (
                  <LI key={item.id}>
                    <Media body>
                      <H6>
                        <Link to={`${process.env.PUBLIC_URL}/admin/Advices/Show/${item.id}`}>{item.title}</Link>
                      </H6>

                      <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    </Media>
                  </LI>
                );
              })
            ) : (
              <LI>
                <Media body>
                  <P>Empty</P>
                </Media>
              </LI>
            )}

            <LI attrLI={{ className: "p-0" }}>
              <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/admin/Advices`}>
                Show All
              </Link>
            </LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  ) : null;
};
export default LastAdvice;
