import React, { useEffect, useState } from "react";
import Context from "./index";
import { userRole } from "../../api/Auth/GetToken";
import { toast } from "react-toastify";
const MemberTypeProvider = (props) => {
  const [typeName, setTypeName] = useState({
    Family: "",
    Name: "",
    Position: "",
    CompanyName: "",
    DefaultCompanyID: "",
    Menu: [],
    agreeProtocol: false,
    Img: "",
  });
  const [noti, setnoti] = useState({
    notiMessage: "",
    notiAdvice: "",
    notiQuestion: "",
    notiBB: "",
  });

  const Reset = () => {
    setTypeName({
      Family: "",
      Name: "",
      Position: "",
      CompanyName: "",
      DefaultCompanyID: "",
      Menu: [],
      agreeProtocol: false,
      Img: "",
    });
  };
  const getToken = async () => {
    try {
      var token = await localStorage.getItem("token");
      if (token) {
        const response = await userRole();

        if (response == "404") {
          toast.error("Error connecting to the server");
        } else if (response.status == "200") {
          setTypeName({
            Family: response.data.family,
            Name: response.data.name,
            Position: response.data.jobPositionDescription,
            CompanyName: response.data.companyName,
            DefaultCompanyID: response.data.companyID,
            Menu: response.data.menu,
            agreeProtocol: response.data.agreeProtocol,
            Img: response.data.img,
          });
        } else {
          toast.error("Error connecting to the server");
        }
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  useEffect(() => {
    if (typeName.DefaultCompanyID == "") {
      getToken();
    }
  }, [typeName.DefaultCompanyID]);
  return (
    <Context.Provider
      value={{
        ...props,
        typeName,
        noti,
        Reset,
        setTypeName,
        setnoti,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default MemberTypeProvider;
