import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import MemberTypeProvider from "./_helper/MemberType/MemberTypeProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/customizer/CustomizerProvider";
import EmailProvider from "./_helper/email/EmailProvider";
function App() {
  return (
    <Fragment>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <EmailProvider>
            <MemberTypeProvider>
              <Routers />
            </MemberTypeProvider>
          </EmailProvider>
        </AnimationThemeProvider>
      </CustomizerProvider>
    </Fragment>
  );
}
export default App;
