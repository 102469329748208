import React, { Fragment } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { AdminRoutes } from "./AdminRotes";
import Layout from "../Layout/Layout";
import { useContext } from "react";
import MemberTypeContext from "../_helper/MemberType/index";
const LayoutRoutes = (props) => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  return (
    <Fragment>
      {typeName.DefaultCompanyID != "" && typeName.agreeProtocol == true ? (
        <Routes>
          {AdminRoutes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ))}
          <Route path={`/*`} element={<Navigate to={`${process.env.PUBLIC_URL}/pages/error/error-page1`} />} />
        </Routes>
      ) : typeName.DefaultCompanyID != "" && typeName.agreeProtocol == false ? (
        navigate(`${process.env.PUBLIC_URL}/admin/AgreePorotocol`)
      ) : null}
    </Fragment>
  );
};

export default LayoutRoutes;
