import axios from "axios";
import { URL, Version } from "../URL";
const Api_GetUserAccessSec = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/UserSecurityAccess?userId=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetUserAccessSecForDoc = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/UserSecurityAccess/GetAll`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_GetUserAccessSec, Api_GetUserAccessSecForDoc };
