import axios from "axios";
import { URL, Version } from "../URL";

const Api_AddMessage = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Messenger`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditMessage = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Messenger?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_MessengerListSent = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Messenger/GetAllSent/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MessengerGetForDashboard = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/GetForDashboard/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_MessengerList = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Messenger/GetAll/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetMessage = async (id, mod) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/${mod}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_setAction = async (mod, act, id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/${mod}/${act}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_setActionMulti = async (mod, act, id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Messenger/SetActionMulit/${mod}/${act}`, id, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_MoveToFloderMulti = async (c, folderid, id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Messenger/MoveToFloderMulti/${c}/${folderid}`, id, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MoveToFloder = async (c, folderid, id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/MoveToFloder/${c}/${folderid}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetMessageSender = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/GetMessageSender/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetAllByFolder = async (folderName) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/GetAllByFolder/${folderName}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_LastUnRead = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Messenger/LastUnRead`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_MoveToFloder,
  Api_GetMessageSender,
  Api_AddMessage,
  Api_EditMessage,
  Api_MessengerList,
  Api_MessengerListSent,
  Api_GetMessage,
  Api_setAction,
  Api_GetAllByFolder,
  Api_LastUnRead,
  Api_MessengerGetForDashboard,
  Api_setActionMulti,
  Api_MoveToFloderMulti,
};
