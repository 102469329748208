import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import {
  Api_AddProducerCompany,
  Api_DeleteProducerCompany,
  Api_EditProducerCompany,
  Api_GetProducerCompany,
  Api_ProducerCompanyList,
} from "../../../api/Admin/ProducerCompany";
import { Api_NationalList } from "../../../api/Admin/National";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
import { Api_GetChildsByCode } from "../../../api/Admin/Filling";
import { Api_DisciplineList } from "../../../api/Admin/Discipline";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
const ProducerCompany = () => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListCompany, setListCompany] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [ListNatioanl, setListNatioanl] = useState([]);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [ListKind, setListKind] = useState(null);
  const [ListDiscipline, setListDiscipline] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    name: "",
    code: "",
    website: "",
    contactNo: "",
    companyID: DefaultCompanyID,
    nationalID: 0,
    kindID: null,
    disciplineID: null,
    disciplineCategory: "Contractors",
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadCompanyList();
    LoadData(DefaultCompanyID);
  }, []);

  const LoadData = async (id) => {
    try {
      setLoading(true);
      const response2 = await Api_NationalList();
      setListNatioanl(response2.data.data);

      LoadKind(id);

      const response = await Api_ProducerCompanyList(id);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const LoadKind = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetChildsByCode(id, "V");
      setListKind(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onChangeKind = (val) => {
    setLoading(true);
    if (val > 0) {
      LoadListDescipline(val);
      setobj({
        ...obj,
        kindID: val,
        disciplineID: null,
      });
    } else {
      setobj({
        ...obj,
        kindID: null,
        disciplineID: null,
      });
      setListDiscipline(null);
    }

    setLoading(false);
  };
  const LoadListDescipline = async (val) => {
    setLoading(true);
    const response = await Api_DisciplineList(val, obj.disciplineCategory);

    if (response.data.data != "") {
      setListDiscipline(response.data.data);
    } else {
      setListDiscipline(null);
    }
    setLoading(false);
  };
  const onChangeDiscipline = (val) => {
    setobj({
      ...obj,
      disciplineID: val,
    });
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
      LoadData(val);
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
      LoadData(null);
    }
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddProducerCompany(obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("A nInteracting Company has been added");
          LoadData(obj.companyID);
          setobj({
            ...obj,
            name: "",
            id: 0,
            code: "",
            website: "",
            contactNo: "",
            companyID: obj.companyID,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_EditProducerCompany(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadData(obj.companyID);
          setobj({
            ...obj,
            name: "",
            nationalID: 0,
            id: 0,
            code: "",
            website: "",
            contactNo: "",
            companyID: obj.companyID,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoadingBtn(false);
    } catch (error) {
      toast.error("Error");
      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteProducerCompany(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Interacting  Company Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };

  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    } finally {
      setSelectedRowID(0);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetProducerCompany(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          name: response.data.data.name,
          nationalID: response.data.data.nationalID,
          id: response.data.data.id,
          code: response.data.data.code,
          kindID: response.data.data.kindID,
          disciplineID: response.data.data.disciplineID,
          website: response.data.data.website,
          contactNo: response.data.data.contactNo,
          disciplineCategory: "Contractors",
          companyID: response.data.data.companyID,
        });
        if (response.data.data.kindID != null) {
          LoadListDescipline(response.data.data.kindID);
        } else {
          setListDiscipline(null);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      name: "",
      code: "",
      id: 0,
      kindID: null,
      website: "",
      contactNo: "",
      disciplineID: null,
    });
    setSelectedRowID(0);
    setListDiscipline(null);
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      CompanyName: item.name,
      Code: item.code,
      Nationality: item.nationalName,
      Kind: item.fillingMainStructureDescription,
      Discipline: item.disciplineDescription,
      Website: item.website,
      ContactNo: item.contactNo,
    }));
    exportToExcel(customHeadings, "Interacting-Companies");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [
      RowNumber++,
      item.name,
      item.code,
      item.nationalName,
      item.fillingMainStructureDescription,
      item.disciplineDescription,
      item.website,
      item.contactNo,
    ]);
    autoTable(doc, {
      head: [["Row Number", "CompanyName", "Code", "Nationality", "Kind", "Discipline", "Website", "ContactNo"]],
      body: customRows,
    });
    doc.text("Interacting Companies", 14, 10);
    doc.save("Interacting-Companies.pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Company Name",
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        accessorKey: "nationalName",
        header: "Nationality",
      },
      {
        accessorKey: "fillingMainStructureDescription",
        header: "Kind",
      },
      {
        accessorKey: "disciplineDescription",
        header: "Discipline",
      },
      {
        accessorKey: "website",
        header: "Website",
      },
      {
        accessorKey: "contactNo",
        header: "Contact No",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Interacting Companies" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Company Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="name" className="form-control" maxLength={255} value={obj.name} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="code" className="form-control" maxLength={10} value={obj.code} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Nationality"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input type="select" name="nationalID" className="form-control" value={obj.nationalID} onChange={onInputChange}>
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListNatioanl != null
                            ? ListNatioanl.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Kind"}</Label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.kindID == null ? 0 : obj.kindID}
                          onChange={(txt) => {
                            onChangeKind(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListKind != null
                            ? ListKind.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Discipline"}</Label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.disciplineID == null ? 0 : obj.disciplineID}
                          onChange={(txt) => {
                            onChangeDiscipline(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListDiscipline != null
                            ? ListDiscipline.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Website"}</Label>
                        <InputGroup>
                          <Input name="website" className="form-control" maxLength={100} value={obj.website || ""} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Contact No"}</Label>
                        <InputGroup>
                          <Input name="contactNo" className="form-control" value={obj.contactNo || ""} maxLength={50} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      className="Table-Style-1"
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableRowNumbers
                      rowNumberMode="static"
                      enableExpanding
                      onRowSelectionChange={setRowSelection}
                      enablePagination={false}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ProducerCompany;
