import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import CKEditors from "react-ckeditor-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  Media,
} from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn } from "../../../AbstractElements";
import MemberTypeContext from "../../../_helper/MemberType/index";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import DatePicker from "react-datepicker";
import { Api_BulletinBoardList, Api_DeleteBulletinBoard, Api_CheckAccess } from "../../../api/Admin/BulletinBoard";
import { Api_SetActionBB } from "../../../api/Admin/UserSeeEvents";
const BulletinBoardList = () => {
  const { bulletinID } = useParams();
  const navigate = useNavigate();
  const { typeName, setnoti, noti } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListCompany, setListCompany] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [startDate, setstartDate] = useState(new Date());
  const [obj, setobj, objRef] = useState({
    id: 0,
    title: "",
    date: "",
    companyID: DefaultCompanyID,
    bulletinID: 0,
  });
  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
  });
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      date: date,
      bulletinID: 0,
    });
    LoadData();
  };
  useEffect(() => {
    if (bulletinID) {
      setobj({
        ...obj,
        bulletinID: bulletinID,
      });
    }
    LoadCompanyList();
    LoadData();
    ActionBtn();
  }, []);

  const ActionBtn = async () => {
    try {
      const response = await Api_CheckAccess();

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const LoadData = async () => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      const response = await Api_BulletinBoardList(newobj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          toast.warning("No results found");
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
        bulletinID: 0,
      });
      LoadData();
    } else {
      setobj({
        ...obj,
        companyID: 0,
        bulletinID: 0,
      });
      LoadData();
    }
  };

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteBulletinBoard(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Bulletin Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const toggle = () => setModal(!modal);
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const showMore = async (id, setAction) => {
    try {
      const AllData = [...tableData];
      const DataIndex = AllData.findIndex((p) => p.id == id);
      const TempData = AllData[DataIndex];
      TempData.show = !TempData.show;
      AllData[DataIndex] = TempData;
      setTableData(AllData);
      if (setAction == true) {
        const response = await Api_SetActionBB(id);
        setnoti({
          ...noti,
          notiBB: id,
        });
      }
    } catch (err) {
      toast.error("Error");
    }
  };
  const styless = {
    backgroundImage: `url(${blogSingle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Bulletin Board" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>Date</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          dateFormat="MMMM d, yyyy"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Parameters"}</Label>
                        <InputGroup>
                          <Input name="title" className="form-control" value={obj.title} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <InputGroup>
                          <Btn
                            attrBtn={{
                              color: "success",
                              type: "button",
                              style: { marginTop: 40 },
                              onClick: () => {
                                setobj({
                                  ...obj,
                                  bulletinID: 0,
                                });
                                LoadData();
                              },
                            }}
                          >
                            Search
                          </Btn>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <div className="activity-media">
              {tableData.map((item) => {
                return (
                  <Row key={item.id}>
                    <Col sm="12">
                      <div className="blog-single">
                        <div className="blog-box blog-details">
                          <Card>
                            <CardBody>
                              <div className={"blog-details" + " " + "blog-" + item.language}>
                                <UL
                                  attrUL={{
                                    className: "blog-social plan flex-row simple-list",
                                  }}
                                >
                                  <LI>Date : {item.dateAdd}</LI>
                                  <LI>Priority : {item.priority}</LI>
                                  <LI>Remarks : {item.remarks}</LI>
                                </UL>
                                <H4
                                  attrH4={{
                                    className: "plan-Title",
                                  }}
                                >
                                  <label>Title :</label> <span>{item.title}</span>
                                </H4>
                                {item.show == true ? (
                                  <div className="plan-Title">
                                    <H4>
                                      <span> Description :</span>
                                    </H4>
                                    <div className="box-readmore" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    <div onClick={() => showMore(item.id, false)} className="ShowMore">
                                      <i className="fa fa-angle-up"></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div onClick={() => showMore(item.id, true)} className="ShowMore">
                                    <i className="fa fa-angle-down"></i>
                                  </div>
                                )}

                                <div style={{ marginTop: 20, textAlign: "right" }}>
                                  {objShowBtnAction.edit == true ? (
                                    <span>
                                      <Button
                                        className="btn btn-info mr-5 btn-xs"
                                        onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/BulletinBoard/Edit/${item.id}`)}
                                      >
                                        Edit
                                      </Button>
                                    </span>
                                  ) : null}
                                  {objShowBtnAction.delete == true ? (
                                    <span>
                                      <Button
                                        className="btn btn-danger btn-xs"
                                        onClick={() => {
                                          setSelectedRowID(item.id);
                                          toggle();
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default BulletinBoardList;
