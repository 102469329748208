import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";

import { Api_ProjectUnitCategoryList } from "../../../api/Admin/ProjectUnitCategory";
import {
  Api_ProjectUnitListProject,
  Api_GetProjectUnit,
  Api_EditProjectUnit,
  Api_AddProjectUnit,
  Api_DeleteProjectUnit,
} from "../../../api/Admin/ProjectUnit";
import { Api_GetProjects } from "../../../api/Admin/Projects";
import { Btn, H6, Image, P, Spinner, H5 } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import MaterialReactTable from "material-react-table";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";

const ProjectUnits = () => {
  const { ProjectID } = useParams();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [CompanyID, setCompanyID] = useState(0);
  const [ProjectName, setProjectName] = useState("");
  const [FileID, setFileID] = useState("");
  const [fileext, setfileext] = useState("");
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [ListParent, setListParent] = useState(null);
  const [modalupload, setmodalupload] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    title: "",
    sort: 0,
    categoryID: 0,
    code: "",
    projectID: ProjectID,
    plantID: null,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    ProjectInfo();
    LoadData();
    LoadUnitList();
  }, []);

  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitCategoryList();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setListParent(response.data.data);
        setLoading(false);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const ProjectInfo = async () => {
    try {
      setLoading(true);
      const response = await Api_GetProjects(ProjectID);
      setProjectName(response.data.data.description);
      setCompanyID(response.data.data.companyID);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const LoadUnitList = async () => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitListProject(ProjectID);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          console.log("list : " + JSON.stringify(response.data.data));
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangeParnet = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        categoryID: val,
      });
      //setSelectedParentID(val);
    } else {
      setobj({
        ...obj,
        categoryID: null,
      });
      //setSelectedParentID(0);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddProjectUnit(obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new unit has been added");
          LoadUnitList();
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoadingBtn(false);
      } else {
        const response = await Api_EditProjectUnit(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadUnitList();
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteProjectUnit(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Unit Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };

  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    } finally {
      setSelectedRowID(0);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetProjectUnit(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          title: response.data.data.title,
          code: response.data.data.code,
          id: response.data.data.id,
          sort: response.data.data.sort,
          categoryID: response.data.data.categoryID,
          projectID: ProjectID,
          plantID: null,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      title: "",
      sort: 0,
      code: "",
      id: 0,
      categoryID: 0,
    });
    setSelectedRowID(0);
  };
  const BackToProjects = async () => {
    navigate(`${process.env.PUBLIC_URL}/admin/Projects/${CompanyID}`);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        accessorKey: "projectUnitCategoryTitle",
        header: "Category",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent={"Basic Data Management"} title={ProjectName} title2={"Company Projects"} title3={"Project Units"} Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="title" autoComplete="off" className="form-control" value={obj.title} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="code" autoComplete="off" className="form-control" value={obj.code} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Category"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="categoryID"
                          className="form-control digits"
                          value={obj.categoryID}
                          onChange={(txt) => {
                            onChangeParnet(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListParent != null
                            ? ListParent.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="sort" className="form-control" value={obj.sort} onChange={onInputChange} required type="number" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Add"
                            )}
                          </Button>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () => BackToProjects(),
                            }}
                          >
                            Back
                          </Btn>
                        </>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null && tableData != "" ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ProjectUnits;
