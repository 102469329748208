import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import imgg from "../assets/images/login/3.jpg";
import LoginTab from "./Tabs/LoginTab";
const Logins = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12 p-0">
            <div className="login-card1">
              <LoginTab selected={true} />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Logins;
