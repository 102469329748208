import axios from "axios";
import { URL, Version } from "../URL";

const Api_GethasTopChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/HasTopChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetViewTopChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ViewTopChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GethasDepartmentChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/HasDepartmentChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetHasProjectChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/HasProjectChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetHasPlantChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/HasPlantChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetViewDepartmentChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ViewDepartmentChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetViewViewProjectChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ViewProjectChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetViewViewPlantChart = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ViewPlantChart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetDocumentFiles = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/DocumentFiles?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetContractMFiles = async (id, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ContractMFiles?ContractFileID=${id}&documentType=${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetMessageFiles = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/MessageFiles?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetViewDoucments = async (id, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/ViewDoucments/${id}/${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteDoucments = async (fileid, docid, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/DeleteDoucments/${fileid}/${docid}/${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteContractsFile = async (fileid, docid, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/DeleteContractsFile/${fileid}/${docid}/${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteFileMessage = async (fileid, docid, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Document/DeleteFileMessage/${fileid}/${docid}/${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    return response;
  } catch (er) {
    return er;
  }
};

const Api_BidAucationFileAttach = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/DocumentCopy/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetBidAucationFiles = async (bidAucationID, documentType) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/DocumentCopy/DocListForBA/${bidAucationID}/${documentType}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetContractsFiles = async (contractMId, bidAuctionID, documentType) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/DocumentCopy/DocListForContract/${contractMId}/${bidAuctionID}/${documentType}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteBidAucationFile = async (docID, AttchchID, mod, documentType) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.delete(`${URL}${Version}/DocumentCopy/Delete/${docID}/${AttchchID}/${mod}/${documentType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_DeleteFileMessage,
  Api_DeleteDoucments,
  Api_GetViewDoucments,
  Api_GetDocumentFiles,
  Api_GethasTopChart,
  Api_GetViewTopChart,
  Api_GethasDepartmentChart,
  Api_GetViewDepartmentChart,
  Api_GetContractsFiles,
  Api_GetMessageFiles,
  Api_GetHasPlantChart,
  Api_GetHasProjectChart,
  Api_GetViewViewPlantChart,
  Api_GetViewViewProjectChart,
  Api_GetBidAucationFiles,
  Api_BidAucationFileAttach,
  Api_DeleteBidAucationFile,
  Api_GetContractMFiles,
  Api_DeleteContractsFile,
};
