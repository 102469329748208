import React, { useEffect, Fragment, useState, useContext } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import DeniReactTreeView from "deni-react-treeview";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Form, CardHeader, Collapse } from "reactstrap";
import { Api_GetBlogCategory_Parent } from "../../../api/Admin/BlogCategory";
import { Api_GetBlog } from "../../../api/Admin/Blog";
import { H4 } from "../../../AbstractElements";
const Legend = () => {
  const [isOpen, setIsOpen] = useState(0);
  const [ListTitle, setListTitle] = useState(null);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    LoadData();
  }, []);
  const themes = ["classic"];
  const LoadData = async () => {
    try {
      setLoading(true);

      const response = await Api_GetBlogCategory_Parent();
      setListTitle(response.data.data);

      // const response = await Api_GetBlogList();
      // if (response.status && response.status == 200 && response.data.isSuccess == true) {
      //   if (response.data.data != "") {
      //     setTableData(response.data.data);
      //   } else {
      //     setTableData(null);
      //   }
      // } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
      //   toast.error("You do not have access permission");
      // } else if (response.response && response.response.data.isSuccess == false) {
      //   toast.error(response.response.data.message);
      // } else {
      //   toast.error("Error - 1");
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onSelectItem = async (item) => {
    try {
      setLoading(true);
      const response = await Api_GetBlog(item.id);
      setTableData(response.data.data);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true} className="accordian-page">
          <Breadcrumbs parent="Services Management" title="Legend" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {ListTitle != null ? (
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      {themes.map((theme, index) => {
                        return (
                          <DeniReactTreeView
                            getRowId={(originalRow) => originalRow.id}
                            onSelectItem={onSelectItem.bind(this)}
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            key={index}
                            showCheckbox={false}
                            theme={theme}
                            items={ListTitle}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="blog-details">
                      <H4>{tableData.title}</H4>
                      <div className="single-blog-content-top">
                        <div dangerouslySetInnerHTML={{ __html: tableData.text }}></div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};

export default Legend;
