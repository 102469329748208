import axios from "axios";
import { URL, Version } from "../URL";
const Api_ListBB = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/UserSeeEvents/ListBB`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_ListAdvice = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/UserSeeEvents/ListAdvice`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ListQuestions = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/UserSeeEvents/ListQuestions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_SetActionBB = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/UserSeeEvents/SetActionBB/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_SetActionAQ = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/UserSeeEvents/SetActionAQ/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_ListBB, Api_SetActionBB, Api_SetActionAQ, Api_ListAdvice, Api_ListQuestions };
