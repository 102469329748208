import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Label, Col, Row, Card, CardBody } from "reactstrap";
import BulletinBoard from "./BulletinBoard";
import DailyPlans from "./DailyPlans";
import MeetingPlans from "./MeetingPlans";
import MessageList from "./MessageList";
import Advice from "./Advice";
import Questions from "./Questions";
import { Breadcrumbs } from "../../../AbstractElements";
const QuickAccess = (props) => {
  const navigate = useNavigate();
  const styles = { width: "100%" };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Quick Access" title="Quick Access" />
        <Row>
          <Col sm="6" style={{ marginTop: "20px" }}>
            <Card>
              <MessageList />
            </Card>
          </Col>
          <Col sm="6" style={{ marginTop: "10px" }}>
            <Card>
              <BulletinBoard />
            </Card>
          </Col>
          <Col sm="6" style={{ marginTop: "10px" }}>
            <Card>
              <DailyPlans />
            </Card>
          </Col>
          <Col sm="6" style={{ marginTop: "10px" }}>
            <Card>
              <MeetingPlans />
            </Card>
          </Col>
          <Col sm="6" style={{ marginTop: "10px" }}>
            <Card>
              <Advice />
            </Card>
          </Col>
          <Col sm="6" style={{ marginTop: "10px" }}>
            <Card>
              <Questions />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default QuickAccess;
