import React, { useEffect, Fragment, useContext, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType/index";
import useState from "react-usestateref";
import { Api_AddDailyPlans, Api_EditDailyPlans, Api_GetDailyPlans } from "../../../api/Admin/DailyPlans";

const DailyPlansAdd = () => {
  const { dailyPlanID } = useParams();
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [obj, setobj, objRef] = useState({
    title: "",
    remarks: "",
    isDone: false,
    priority: "Medium",
    date: "",
    description: "",
    repeatableType: "norepetition",
    time: "",
  });
  const editorRef = useRef(null);
  useEffect(() => {
    if (dailyPlanID) {
      GetDataForEdit(dailyPlanID);
    } else {
      const newDate = new Date(startDate.getTime());
      newDate.setDate(startDate.getDate());
      const hours = String(startDate.getHours()).padStart(2, "0");
      const minutes = String(startDate.getMinutes()).padStart(2, "0");
      setstartDate(newDate);
      setobj({
        ...obj,
        date: newDate,
        time: hours + ":" + minutes,
      });
    }
  }, []);
  const [startDate, setstartDate] = useState(new Date());
  const [startTime, setstartTime] = useState(new Date());
  const handleStartDate = (date) => {
    console.log("date:" + date);
    setstartDate(date);
    setobj({
      ...obj,
      date: date,
    });
  };
  const handleStartTime = (date) => {
    if (date != null && date != "") {
      setstartTime(date);

      const newDate = new Date(date.getTime());
      newDate.setDate(date.getDate() + 7);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      setobj({
        ...obj,
        time: hours + ":" + minutes,
      });
    } else {
      setobj({
        ...obj,
        time: null,
      });
      setstartTime("");
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        setobj({
          ...obj,
          description: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;
        const response = await Api_AddDailyPlans(backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Plan has been added");
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        setobj({
          ...obj,
          description: editorRef.current.getContent(),
        });

        const backupobj = objRef.current;

        const response = await Api_EditDailyPlans(SelectedRowID, backupobj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });

    console.log(obj.description);
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetDailyPlans(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);

        const myDate = new Date(response.data.data.date.toString().substring(0, 10) + " " + response.data.data.date.toString().substring(11, 16));
        setstartDate(myDate);
        if (response.data.data.time != null) {
          setstartTime(myDate);
        } else {
          setstartTime("");
        }
        setobj({
          ...obj,
          isDone: response.data.data.isDone,
          repeatableType: response.data.data.repeatableType,
          title: response.data.data.title,
          remarks: response.data.data.remarks,
          priority: response.data.data.priority,
          date: response.data.data.date,
          time: response.data.data.time,
          description: response.data.data.description,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
    console.log(obj.description);
  };
  const ResetForm = async () => {
    setobj({
      ...obj,
      title: "",
      remarks: "",
      priority: "Medium",
      description: "",
      repeatable: false,
      isDone: false,
    });

    if (SelectedRowID != 0) {
      navigate(`${process.env.PUBLIC_URL}/admin/DailyPlansReport`);
    } else {
      setSelectedRowID(0);
    }
  };

  const onChangeIsDone = (val) => {
    console.log(val);
    setobj({
      ...obj,
      isDone: val,
    });
  };
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title="Daily Plans" Loading={Loading} />
          {Loading == false ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input name="title" className="form-control" value={obj.title} onChange={onInputChange} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4" md="4" lg="2">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Priority"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                            <option value="Top" key="Top">
                              Top
                            </option>
                            <option value="Medium" key="Medium">
                              Medium
                            </option>
                            <option value="Low" key="Low">
                              Low
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4" md="4" lg="2">
                        <FormGroup>
                          <Label> Date</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={startDate}
                            onChange={handleStartDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" md="4" lg="2">
                        <FormGroup>
                          <Label> Time</Label>
                          <InputGroup className="date" id="dt-minimum" data-target-input="nearest">
                            <DatePicker
                              className="form-control datetimepicker-input digits"
                              selected={startTime != null ? startTime : ""}
                              onChange={handleStartTime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Remarks"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input name="remarks" className="form-control" maxLength={255} onChange={onObjChange} value={obj.remarks} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {!dailyPlanID ? (
                        <Col sm="4" md="4" lg="2">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Repetition"}</Label>
                            <label className="txt-danger Required">{"*"}</label>
                            <Input type="select" name="repeatableType" className="form-control" value={obj.repeatableType} onChange={onObjChange}>
                              <option value="norepetition" key="norepetition">
                                No repetition
                              </option>
                              <option value="daily" key="daily">
                                Daily
                              </option>
                              <option value="everyotherday" key="everyotherday">
                                Every other day
                              </option>
                              <option value="everytwodays" key="everytwodays">
                                Every two days
                              </option>
                              <option value="weekly" key="weekly">
                                Weekly
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      ) : null}

                      <Col
                        sm="2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          alignSelf: "center",
                        }}
                      >
                        <FormGroup className="form-group">
                          <div className="checkbox">
                            <Input id="checkbox2" type="checkbox" defaultChecked={obj.isDone} onClick={() => onChangeIsDone(!obj.isDone)} />
                            <Label className="text-muted" for="checkbox2">
                              Is Done
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Description"}</Label>
                          <Editor
                            apiKey="97twnsawko365at3kw26izpuzdz92cln8uduxn75z1lt9zju"
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            init={{
                              plugins:
                                "directionality anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                              toolbar:
                                "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                              tinycomments_mode: "embedded",
                              ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            }}
                            initialValue={obj.description}
                            onChange={onInputChange}
                            name="description"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {SelectedRowID == 0 ? (
                          <Button type="submit" className="btn btn-success btn-md">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Add"
                            )}
                          </Button>
                        ) : (
                          <>
                            <Button type="submit" className="btn btn-success btn-md m-r-15">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Save"
                              )}
                            </Button>
                            {LoadingBtn == false ? (
                              <Btn
                                attrBtn={{
                                  color: "light",
                                  type: "button",
                                  onClick: () => ResetForm(),
                                }}
                              >
                                Cancel
                              </Btn>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};

export default DailyPlansAdd;
