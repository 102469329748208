import React, { Fragment, useState } from "react";
import { Col, Container, Row, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { P, Btn } from "../../AbstractElements";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const [popover, setPopover] = useState(false);
  const Toggle = () => setPopover(!popover);
  const Popoverbody = (
    <>
      <table className="table-primary">
        <tr>
          <td>System Title : </td>
          <td>Inteligent Data Management System / IDMS</td>
        </tr>
        <tr>
          <td>System Ver. :</td>
          <td>Ver.E 01</td>
        </tr>
        <tr>
          <td>System Code :</td>
          <td>IDMS</td>
        </tr>
        <tr>
          <td>System Designer : </td>
          <td>Mojtaba Tavakol</td>
        </tr>
        <tr>
          <td>System Development :</td>
          <td>Armin Narmafzar Co.</td>
        </tr>
        <tr>
          <td>Licence :</td>
          <td>Planet Zamina Co.</td>
        </tr>
        <tr>
          <td>Specificaltion :</td>
          <td>A total Intelligent Data Management System</td>
        </tr>
      </table>
    </>
  );

  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="8" className="footer-copyright text-left">
              <P attrPara={{ className: "mb-0" }}>Copyright @2023 Planet Zamina</P>
            </Col>
            <Col md="4">
              <div className="btn-showcase">
                <Btn attrBtn={{ color: "primary", className: "example-popover", onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/Legend`) }}>
                  Legend
                </Btn>
                <Btn attrBtn={{ color: "primary", className: "example-popover", id: "Popover-8" }}>IDMS</Btn>
                <Popover className="popover-Footer" placement="top" isOpen={popover} target="Popover-8" toggle={Toggle}>
                  <PopoverHeader>Inteligent Data Management System / IDMS</PopoverHeader>
                  <PopoverBody>{Popoverbody}</PopoverBody>
                </Popover>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
export default Footer;
