import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import {
  Api_ContractDatesList,
  Api_GetContractDates,
  Api_EditContractDates,
  Api_AddContractDates,
  Api_DeleteContractDates,
} from "../../../api/Admin/ContractDates";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DocumentAttach } from ".";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
const Dates = ({ setDatesCount, documentType, objContract, contractsID }) => {
  const navigate = useNavigate();

  const [modalFile, setmodalFile] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ContractDate, setContractDate] = useState(new Date());
  const [modaldelete, setmodaldelete] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    description: "",
    remarks: "",
    date: ContractDate,
    datesType: "TheProposalDate",
    contractID: contractsID,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadData();
  }, []);
  const [obj2, setobj2] = useState({
    id: 0,
    documentName: "",
    isDirect: true,
  });
  const LoadData = async () => {
    try {
      const response = await Api_ContractDatesList(contractsID);
      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setDatesCount(response.data.data.length);
        } else {
          setTableData(null);
        }
      } else if (response.data.isSuccess == false && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      }
    } catch (error) {
      toast.error("Error - 0");
      console.log(error);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const handleDate = (date) => {
    setContractDate(date);
    setobj({
      ...obj,
      date: date,
    });
  };
  const toggle = () => setModal(!modal);

  const onSubmitRisk = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddContractDates(obj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Date has been added");
          LoadData();
          setobj({
            ...obj,
            description: "",
            remarks: "",
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const response = await Api_EditContractDates(SelectedRowID, obj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadData();
          setobj({
            ...obj,
            description: "",
            remarks: "",
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
      console.log(error);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteContractDates(id, "");
      setModal(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Risk Is Removed");
        LoadData();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
        setModal(false);
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetContractDates(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          remarks: response.data.data.remarks,
          datesType: response.data.data.datesType,
          date: response.data.data.date,

          contractID: response.data.data.contractMId,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      remarks: "",
    });
    setSelectedRowID(0);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        header: "Description",
      },

      {
        accessorKey: "datesType",
        header: "Dates Type",
      },
      {
        header: "Date",
        Cell: ({ cell }) => (
          <>
            <span>{cell.row.original.date.toString().substring(0, 10)}</span>
          </>
        ),
      },

      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Files",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    documentName: cell.row.original.description,
                    id: cell.row.original.id,
                    isDirect: true,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="3">
            <FormGroup>
              <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
              <InputGroup>
                <Input name="description" autoComplete="off" className="form-control" value={obj.description} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label>{"Type"}</Label>
              <label className="txt-danger Required">{"*"}</label>
              <Input type="select" name="datesType" className="form-control" value={obj.likelHood} onChange={onInputChange}>
                <option value="TheProposalDate" key="TheProposalDate">
                  The Proposal Date
                </option>
                <option value="TheContractNotification" key="TheContractNotification">
                  The Contract Notification
                </option>
                <option value="TheContractCommencementDate" key=" TheContractCommencementDate">
                  The Contract Commencement Date
                </option>
                <option value="TheContractChangeScope" key="TheContractChangeScope">
                  The Contract Change Scope
                </option>
                <option value="TheContractPayments" key="TheContractPayments">
                  The Contract Payments
                </option>
                <option value="TheContractFinishedWork" key="TheContractFinishedWork">
                  The Contract Finished Work
                </option>
                <option value="TheContractHandover" key="TheContractHandover">
                  The Contract Handover
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label>Date</Label>
              <label className="txt-danger Required">{"*"}</label>
              <DatePicker className="datepicker-here form-control" dateFormat="MMMM d, yyyy" selected={ContractDate} onChange={handleDate} />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup>
              <Label>{"Remarks"}</Label>
              <InputGroup>
                <Input name="remarks" autoComplete="off" className="form-control" value={obj.remarks} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {SelectedRowID == 0 ? (
              <Button type="button" onClick={() => onSubmitRisk()} className="btn btn-success btn-md">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : (
                  "Add"
                )}
              </Button>
            ) : (
              <>
                <Button onClick={() => onSubmitRisk()} type="button" className="btn btn-success btn-md m-r-15">
                  {LoadingBtn ? (
                    <div className="loader-box2">
                      <Spinner attrSpinner={{ className: "loader-7" }} />
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
                {LoadingBtn == false ? (
                  <Btn
                    attrBtn={{
                      color: "light",
                      type: "button",
                      onClick: () => ResetForm(),
                    }}
                  >
                    Cancel
                  </Btn>
                ) : null}
              </>
            )}
          </Col>
        </Row>

        {tableData != null ? (
          <Row>
            <Col sm="12">
              <MaterialReactTable
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    color: "#fff",
                    borderRadius: "0",
                    border: "0",
                    "& tbody tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                    "& thead tr": {
                      backgroundColor: "#16449D",
                      color: "#fff",
                    },
                  },
                }}
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellDragHandleProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellColumnActionsButtonProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellFilterTextFieldProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                getRowId={(originalRow) => originalRow.id}
                columns={columns}
                data={tableData}
                enableColumnOrdering
                enableExpanding
                enablePagination={false}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                tableInstanceRef={tableInstanceRef}
                enableRowNumbers
                rowNumberMode="static"
              />
            </Col>
          </Row>
        ) : null}
      </Container>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFile} size="lg">
        <ModalHeader>{obj2.documentName}</ModalHeader>
        <ModalBody>
          <DocumentAttach
            obj={objContract}
            documentType={documentType}
            contractsID={contractsID}
            disabledbtn={false}
            documentID={obj2.id}
            isDirect={obj2.isDirect}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFile(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Dates;
