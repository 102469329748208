import axios from "axios";
import { URL, Version } from "../URL";

const Api_ProjectUnitListProject = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/ProjectUnits/GetAllProject/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ProjectUnitListProject_Parent = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/ProjectUnits/GetAllProject_Parent/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_ProjectUnitListPlant = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/ProjectUnits/GetAllPlant/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetProjectUnit = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/ProjectUnits/Get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditProjectUnit = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/ProjectUnits?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddProjectUnit = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/ProjectUnits`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteProjectUnit = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/ProjectUnits/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};
const Api_ProjectUnitsCode = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/ProjectUnits/GetCode/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_ProjectUnitsCode,
  Api_ProjectUnitListProject_Parent,
  Api_ProjectUnitListProject,
  Api_ProjectUnitListPlant,
  Api_GetProjectUnit,
  Api_EditProjectUnit,
  Api_AddProjectUnit,
  Api_DeleteProjectUnit,
};
