import React, { Fragment, useContext, useEffect, useState } from "react";
import { LogIn, Settings } from "react-feather";
import { Media } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { H6, Image, LI, UL } from "../../AbstractElements";
import UserImg from "../../assets/images/avtar/man.png";
import MemberTypeContext from "../../_helper/MemberType/index";
import { format } from "date-fns";
import Notification from "./Notification";
import LastBB from "./LastBB";
import LastAdvice from "./LastAdvice";
import LastQuestion from "./LastQuestion";
const HeaderContain = () => {
  const date = new Date();
  const [curDate, setcurDate] = useState("");
  const { typeName, Reset } = useContext(MemberTypeContext);
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [position, setposition] = useState("");
  useEffect(() => {
    setcurDate(`${format(date, "do  MMMM yyyy")}`);
    setProfile(localStorage.getItem("profileURL") || UserImg);
  }, [typeName.Name]);

  const history = useNavigate();
  const Logout = async () => {
    await localStorage.removeItem("token");
    Reset();
    history(`${process.env.PUBLIC_URL}/pages/authentication/login`);
  };
  return (
    <Fragment>
      <div className="nav-right col-9 col-sm-9 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          {typeName.Name != "" ? (
            <>
              <LI>
                <Media className="profile-media">
                  <Media body>{curDate}</Media>
                </Media>
              </LI>
              <LI>
                <Media className="profile-media">
                  <Media body>
                    <span>User : </span> <span className="m-r-15">{typeName.Position}</span>
                  </Media>
                </Media>
              </LI>
              <LI
                attrLI={{
                  className: "profile-nav onhover-dropdown pe-0 pt-0 me-0",
                }}
              >
                <Media className="profile-media">
                  <Image
                    attrImage={{
                      className: "rounded-circle",
                      src: typeName.Img,
                      alt: "",
                    }}
                  />
                  <Media body>
                    <span className="m-r-15">{typeName.Name + " " + typeName.Family}</span>
                  </Media>
                </Media>
                <UL
                  attrUL={{
                    className: `simple-list profile-dropdown onhover-show-div`,
                  }}
                >
                  <LI>
                    <Link to={`${process.env.PUBLIC_URL}/ChangePassword`}>
                      <i>
                        <Settings />
                      </i>
                      <span>Change Passowrd</span>
                    </Link>
                  </LI>
                  <LI attrLI={{ onClick: Logout }}>
                    <Link to={`${process.env.PUBLIC_URL}/pages/authentication/login`}>
                      <LogIn />
                      <span>LogOut</span>
                    </Link>
                  </LI>
                </UL>
              </LI>
            </>
          ) : (
            <LI attrLI={{ onClick: Logout }}>
              <Link to={`${process.env.PUBLIC_URL}/pages/authentication/login`}>
                <span>LogOut</span>
              </Link>
            </LI>
          )}
        </UL>
      </div>
      <div className="nav-right col-2 col-sm-2 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          {typeName.Name != "" ? (
            <>
              <Notification />
              <LastBB />
              <LastAdvice />
              <LastQuestion />
            </>
          ) : null}
        </UL>
      </div>
    </Fragment>
  );
};
export default HeaderContain;
