import axios from "axios";
import { URL, Version } from "../URL";

const Api_ContractsList = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Contracts/GetAll/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetContracts = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Contracts/Get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditContracts = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}${Version}/Contracts?id=${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddContracts = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Contracts`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteContracts = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/Contracts/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};
const Api_ContractTypeCode = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${URL}${Version}/Contracts/GetCode/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_ContractsList,
  Api_GetContracts,
  Api_EditContracts,
  Api_AddContracts,
  Api_DeleteContracts,
  Api_ContractTypeCode,
};
