import axios from "axios";
import { URL, Version } from "../URL";

const Api_VendorsCompanyList = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/VendorsCompany?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetVendorsCompany = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/VendorsCompany/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetVendorsCompanyGetCode = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/VendorsCompany/GetCode/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditVendorsCompany = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/VendorsCompany?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddVendorsCompany = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/VendorsCompany`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteVendorsCompany = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/VendorsCompany/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_GetVendorsCompanyGetCode,
  Api_VendorsCompanyList,
  Api_GetVendorsCompany,
  Api_EditVendorsCompany,
  Api_AddVendorsCompany,
  Api_DeleteVendorsCompany,
};
