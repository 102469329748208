import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Api_AddRisk, Api_EditRisk, Api_RiskListBA, Api_DeleteRisk, Api_GetRisk } from "../../../api/Admin/Risks";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
const Risk = ({ setriskCount }) => {
  const navigate = useNavigate();
  const { bidAuctionID } = useParams();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    likelHood: "High",
    remarks: "",
    rating: "Frequently",
    impactRate: "0",
    bidAucationID: bidAuctionID,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    try {
      const response = await Api_RiskListBA(bidAuctionID);
      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setriskCount(response.data.data.length);
        } else {
          setTableData(null);
        }
      } else if (response.data.isSuccess == false && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const onSubmitRisk = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddRisk(obj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Risk has been added");
          LoadData();
          setobj({
            ...obj,
            description: "",
            remarks: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const response = await Api_EditRisk(SelectedRowID, obj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadData();
          setobj({
            ...obj,
            description: "",
            remarks: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
      console.log(error);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteRisk(id);
      setModal(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Risk Is Removed");
        LoadData();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
        setModal(false);
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetRisk(id);
      console.log(response.data.data);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          likelHood: response.data.data.likelHood,
          description: response.data.data.description,
          remarks: response.data.data.remarks,
          impactRate: response.data.data.impactRate,
          rating: response.data.data.rating,
          id: response.data.data.id,
          bidAuctionID: response.data.data.bidAuctionID,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      remarks: "",
      id: 0,
    });
    setSelectedRowID(0);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        header: "Description",
      },

      {
        accessorKey: "likelHood",
        header: "LikelHood",
      },
      {
        accessorKey: "rating",
        header: "Rating",
      },
      {
        accessorKey: "impactRate",
        header: "Impact Rate",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="3">
            <FormGroup>
              <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
              <InputGroup>
                <Input name="description" autoComplete="off" className="form-control" value={obj.description} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Label>{"Likelhood"}</Label>
              <label className="txt-danger Required">{"*"}</label>
              <Input type="select" name="likelHood" className="form-control" value={obj.likelHood} onChange={onInputChange}>
                <option value="High" key="High">
                  High
                </option>
                <option value="MediumHigh" key="MediumHigh">
                  Medium / High
                </option>
                <option value="LowMedium" key="LowMedium">
                  Low / Medium
                </option>
                <option value="Low" key="Low">
                  Low
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Label>{"Rating"}</Label>
              <label className="txt-danger Required">{"*"}</label>
              <Input type="select" name="rating" className="form-control" value={obj.rating} onChange={onInputChange}>
                <option value="Frequently" key="Frequently">
                  Frequently
                </option>
                <option value="Likely" key="Likely">
                  Likely
                </option>
                <option value="Possible" key="Possible">
                  Possible
                </option>
                <option value="Unlikely" key="Unlikely">
                  Unlikely
                </option>
                <option value="Rare" key="Rare">
                  Rare
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Label>{"Impact Rate"}</Label>
              <label className="txt-danger Required">{"*"}</label>
              <Input type="select" name="impactRate" className="form-control" value={obj.impactRate} onChange={onInputChange}>
                <option value="0" key="0">
                  0
                </option>
                <option value="1" key="1">
                  1
                </option>
                <option value="2" key="2">
                  2
                </option>
                <option value="3" key="3">
                  3
                </option>
                <option value="4" key="4">
                  4
                </option>
                <option value="5" key="5">
                  5
                </option>
                <option value="6" key="6">
                  6
                </option>
                <option value="7" key="7">
                  7
                </option>
                <option value="8" key="8">
                  8
                </option>
                <option value="9" key="9">
                  9
                </option>
                <option value="10" key="10">
                  10
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Label>{"Remarks"}</Label>

              <InputGroup>
                <Input name="remarks" autoComplete="off" className="form-control" value={obj.remarks} onChange={onInputChange} />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {SelectedRowID == 0 ? (
              <Button type="button" onClick={() => onSubmitRisk()} className="btn btn-success btn-md">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : (
                  "Add"
                )}
              </Button>
            ) : (
              <>
                <Button onClick={() => onSubmitRisk()} type="button" className="btn btn-success btn-md m-r-15">
                  {LoadingBtn ? (
                    <div className="loader-box2">
                      <Spinner attrSpinner={{ className: "loader-7" }} />
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
                {LoadingBtn == false ? (
                  <Btn
                    attrBtn={{
                      color: "light",
                      type: "button",
                      onClick: () => ResetForm(),
                    }}
                  >
                    Cancel
                  </Btn>
                ) : null}
              </>
            )}
          </Col>
        </Row>

        {tableData != null ? (
          <Row>
            <Col sm="12">
              <MaterialReactTable
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    color: "#fff",
                    borderRadius: "0",
                    border: "0",
                    "& tbody tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                    "& thead tr": {
                      backgroundColor: "#16449D",
                      color: "#fff",
                    },
                  },
                }}
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellDragHandleProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellColumnActionsButtonProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                muiTableHeadCellFilterTextFieldProps={{
                  sx: (theme) => ({
                    color: "#fff",
                  }),
                }}
                getRowId={(originalRow) => originalRow.id}
                columns={columns}
                data={tableData}
                enableColumnOrdering
                enableExpanding
                enablePagination={false}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                tableInstanceRef={tableInstanceRef}
                enableRowNumbers
                rowNumberMode="static"
              />
            </Col>
          </Row>
        ) : null}
      </Container>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Risk;
