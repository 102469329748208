import React, { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import { DataTypeAccessList } from "../Module/DataTypeAccessList";
import { DocSearch } from ".";
import useState from "react-usestateref";
import {
  Api_GetBidAucationFiles,
  Api_GetViewDoucments,
  Api_DeleteDoucments,
  Api_BidAucationFileAttach,
  Api_GetDocumentFiles,
  Api_GetContractMFiles,
} from "../../../api/Admin/Document";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  InputGroupText,
} from "reactstrap";
const DocumentAttach = ({ bidAuctionID, documentID, disabledbtn, obj, ViewFileBA, isDirect, documentType }) => {
  const [modalupload, setmodalupload] = useState(false);
  const [modaldelete, setmodaldelete] = useState(false);
  const [HeaderUploader, setHeaderUploader] = useState(false);
  const [FileID, setFileID] = useState("");
  const [DocFileList, setDocFileList] = useState(null);
  const [TransmitalList, setTransmitalList] = useState(null);
  const [LetterList, setLetterList] = useState(null);
  const [DataTypeUploader, setDataTypeUploader] = useState([]);
  const [DocFileList2, setDocFileList2] = useState(null);
  const [InvoiceList, setInvoiceList] = useState(null);
  const [RefrencesList, setRefrencesList] = useState(null);
  const HandleUpload = (header) => {
    setHeaderUploader(header);
    setmodalupload(true);
  };

  const [obj2, setobj2] = useState({
    id: 0,
    filename: "",
    type: "",
  });

  const SetDataTypeAccess = async () => {
    const _data = await DataTypeAccessList();
    setDataTypeUploader(_data);
  };

  const ViewFile = async () => {
    try {
      if (isDirect == true) {
        const response = await Api_GetContractMFiles(documentID, documentType);

        CreateListOtherUploded(response.data.data);
      } else {
        const response = await Api_GetDocumentFiles(documentID);
        CreateListDocFile(response.data.data);
        CreateListTransmital(response.data.data);
        CreateListLetter(response.data.data);
        CreateListInvoice(response.data.data);
        CreateRefrencesList(response.data.data);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const CreateRefrencesList = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Refrences");
      setRefrencesList(updatedContact);
    } catch (err) {}
  };
  const CreateListDocFile = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "DocumentFile");
      setDocFileList(updatedContact);
    } catch (err) {}
  };
  const CreateListTransmital = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Transmital");
      setTransmitalList(updatedContact);
    } catch (err) {}
  };
  const CreateListLetter = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Letter");
      setLetterList(updatedContact);
    } catch (err) {}
  };
  const CreateListInvoice = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Invoice");
      setInvoiceList(updatedContact);
    } catch (err) {}
  };
  const CreateListOtherUploded = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == documentType);
      setDocFileList2(updatedContact);
    } catch (err) {}
  };
  const ShowModalDelete = async (id, documentType, FileName) => {
    setobj2({
      ...obj2,
      filename: FileName,
      id: id,
      type: documentType,
    });
    setmodaldelete(true);
  };
  useEffect(() => {
    if (bidAuctionID != "") {
      ViewFile();
    } else {
    }
    SetDataTypeAccess();
  }, [bidAuctionID]);
  const DownloadFile = async (id, documentType, FileName) => {
    const response = await Api_GetViewDoucments(id, documentType);

    if (response.status == "200") {
      fileDownload(response.data, FileName);
    } else {
      toast.error("Error");
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                {isDirect == true ? (
                  <Col sm="6">
                    <Button
                      disabled={obj.confrim == "Yes" ? true : false}
                      className={DocFileList2 != null && DocFileList2 != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                      onClick={() => HandleUpload("Letter")}
                    >
                      Direct upload
                    </Button>
                    <Row>
                      <Col sm="12">
                        <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                          {DocFileList2 != null
                            ? DocFileList2.map((item) => {
                                return (
                                  <li key={item.id} className="list-group-item">
                                    <button
                                      className="btn btn-outline-light btn-sm"
                                      onClick={() => {
                                        DownloadFile(item.id, item.documentType, item.fileName);
                                      }}
                                    >
                                      <i className="fa fa-cloud-download txt-primary me-2"></i>
                                      {item.fileName}
                                    </button>
                                    {obj.confrim != "Yes" ? (
                                      <i
                                        onClick={() => {
                                          ShowModalDelete(item.id, item.documentType, item.fileName);
                                        }}
                                        className="fa fa-trash-o  ml-10 txt-danger me-2"
                                      ></i>
                                    ) : null}
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <>
                    {" "}
                    <Col sm="4">
                      <Button
                        disabled={disabledbtn == true ? true : false}
                        className={DocFileList != null && DocFileList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                        onClick={() => HandleUpload("Document File")}
                      >
                        Document File
                      </Button>
                      <Row>
                        <Col sm="12">
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {DocFileList != null
                              ? DocFileList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Button
                        disabled={disabledbtn == true ? true : false}
                        className={TransmitalList != null && TransmitalList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                        onClick={() => HandleUpload("Transmital")}
                      >
                        Transmital
                      </Button>
                      <Row>
                        <Col sm="12">
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {TransmitalList != null
                              ? TransmitalList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Button
                        disabled={disabledbtn == true ? true : false}
                        className={LetterList != null && LetterList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                        onClick={() => HandleUpload("Letter")}
                      >
                        Letter
                      </Button>
                      <Row>
                        <Col sm="12">
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {LetterList != null
                              ? LetterList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12">
                      <hr></hr>
                    </Col>
                    <Col sm="3">
                      <Button
                        disabled={disabledbtn == true ? true : false}
                        className={InvoiceList != null && InvoiceList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                        onClick={() => HandleUpload("Invoice")}
                      >
                        Invoice
                      </Button>
                      <Row>
                        <Col sm="12">
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {InvoiceList != null
                              ? InvoiceList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="3">
                      <Button
                        disabled={true}
                        className={RefrencesList != null && RefrencesList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                        onClick={() => HandleUpload("Letter")}
                      >
                        Refrences
                      </Button>
                      <Row>
                        <Col sm="12">
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {RefrencesList != null
                              ? RefrencesList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modalupload} size="lg">
        <ModalHeader>
          <Row>
            <Col sm="12">Attachment {HeaderUploader}</Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              <DocSearch />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setmodalupload(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default DocumentAttach;
