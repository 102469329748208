import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Media,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  InputGroup,
  Button,
  Input,
  Label,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import { H6, LI, P, UL, Image, Spinner, Btn } from "../../../AbstractElements";
import user from "../../../assets/images/user/user.png";
import { emailSidbar } from "../../../Data/Email";
import { Link, useParams } from "react-router-dom";
import {
  Api_AddMessengerFolder,
  Api_EditMessengerFolder,
  Api_MessengerFolderList,
  Api_DeleteMessengerFolder,
  Api_GetMessengerFolder,
} from "../../../api/Admin/MessengerFolder";

const MailSidebar = () => {
  const [IsOpen, setIsOpen] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modaldelete, setmodaldelete] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const { Mod, FolderName } = useParams();
  const OnHandelClick = () => {
    setIsOpen(!IsOpen);
  };
  useEffect(() => {
    LoadData();
  }, []);
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      title: "",
      id: 0,
    });
    setSelectedRowID(0);
    setmodal(false);
    setmodaldelete(false);
  };
  const [obj, setobj] = useState({
    title: "",
    id: 0,
  });
  const LoadData = async () => {
    try {
      const response = await Api_MessengerFolderList();

      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      }
    } catch (error) {}
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteMessengerFolder(id);
      setmodaldelete(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        LoadData();
      }
    } catch (error) {}
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddMessengerFolder(obj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          LoadData();
          ResetForm();
        }
      } else {
        const response = await Api_EditMessengerFolder(SelectedRowID, obj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setSelectedRowID(0);
          LoadData();
          ResetForm();
        }
      }
    } catch (error) {}
  };
  return (
    <Fragment>
      <div className="md-sidebar job-sidebar">
        <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${IsOpen ? "open" : ""}`}>
          <div className="email-left-aside">
            <Card>
              <CardBody>
                <div className="email-app-sidebar">
                  <UL attrUL={{ className: "simple-list nav main-menu ", role: "tablist" }}>
                    <LI attrLI={{ className: "nav-item" }}>
                      <Link className="btn-primary btn-block btn-mail w-100" to={`${process.env.PUBLIC_URL}/admin/Message/New`}>
                        <i className="icofont icofont-envelope me-2"></i> NEW MAIL
                      </Link>
                    </LI>
                    <LI attrLI={{ className: "nav-item" + Mod && Mod == "Inbox" ? "li-active" : null }}>
                      <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`}>
                        <i className="icofont icofont-envelope me-2"></i> Inbox
                      </Link>
                    </LI>
                    <LI attrLI={{ className: "nav-item" + Mod && Mod == "Unread" ? "li-active" : null }}>
                      <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Unread`}>
                        <i className="icon-email me-2"></i> Unread
                      </Link>
                    </LI>
                    <LI attrLI={{ className: "nav-item" + Mod && Mod == "Archive" ? "li-active" : null }}>
                      <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Archive`}>
                        <i className="icofont icofont-archive me-2"></i> Archive
                      </Link>
                    </LI>
                    <LI attrLI={{ className: "nav-item" + Mod && Mod == "Deleted" ? "li-active" : null }}>
                      <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Deleted`}>
                        <i className="icofont icofont-trash me-2"></i> Deleted
                      </Link>
                    </LI>
                    <LI attrLI={{ className: "nav-item" + Mod && Mod == "Sent" ? "li-active" : null }}>
                      <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Sent`}>
                        <i className="icon-new-window me-2"></i> Sent
                      </Link>
                    </LI>
                    <LI>
                      <hr></hr>
                    </LI>
                    <LI attrLI={{ className: "nav-item" }}>
                      <a
                        href="#"
                        className="show"
                        onClick={(event) => {
                          event.preventDefault();
                          setmodal(true);
                        }}
                      >
                        <i className="fa fa-plus"></i> New Folder
                      </a>
                    </LI>
                    {tableData != null ? (
                      <>
                        {tableData.map((item) => {
                          return (
                            <LI
                              key={item.id}
                              attrLI={{
                                key: item.id,
                                className:
                                  Mod && Mod == "Folder" && FolderName == item.title ? "nav-item user-folder li-Aactive" : "nav-item user-folder",
                              }}
                            >
                              <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Messenger/Folder/${item.title}`}>
                                {item.title}
                              </Link>
                              <div className="dropdown-basic">
                                <Dropdown className="dropdown">
                                  <Btn>
                                    <span>
                                      <i className="icofont icofont-arrow-down"></i>
                                    </span>
                                  </Btn>
                                  <DropdownMenu className="dropdown-content">
                                    <DropdownItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedRowID(item.id);
                                        setobj({
                                          ...obj,
                                          title: item.title,
                                        });
                                        setmodal(true);
                                      }}
                                      href="/"
                                    >
                                      Rename
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedRowID(item.id);
                                        setobj({
                                          ...obj,
                                          title: item.title,
                                        });
                                        setmodaldelete(true);
                                      }}
                                      href="#/"
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </LI>
                          );
                        })}
                      </>
                    ) : null}
                  </UL>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <Modal isOpen={modal}>
        <ModalHeader>Folder Name</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <InputGroup>
                  <Input
                    name="title"
                    maxLength={50}
                    className="form-control"
                    value={obj.title || ""}
                    onChange={onObjChange}
                    required={true}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {SelectedRowID == 0 ? (
            <>
              <Button onClick={() => onSubmit()} type="submit" className="btn btn-success btn-md">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : (
                  "Add"
                )}
              </Button>
              <Btn
                attrBtn={{
                  color: "light",
                  type: "button",
                  onClick: () => ResetForm(),
                }}
              >
                Cancel
              </Btn>
            </>
          ) : (
            <>
              <Button onClick={() => onSubmit()} type="submit" className="btn btn-success btn-md m-r-15">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
              {LoadingBtn == false ? (
                <Btn
                  attrBtn={{
                    color: "light",
                    type: "button",
                    onClick: () => ResetForm(),
                  }}
                >
                  Cancel
                </Btn>
              ) : null}
            </>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modaldelete}>
        <ModalHeader>Are you sure Delete Folder {obj.title}</ModalHeader>

        <ModalFooter>
          <>
            <Button onClick={() => Delete(SelectedRowID)} type="submit" className="btn btn-danger btn-md">
              {LoadingBtn ? (
                <div className="loader-box2">
                  <Spinner attrSpinner={{ className: "loader-7" }} />
                </div>
              ) : (
                "Yes"
              )}
            </Button>
            <Btn
              attrBtn={{
                color: "light",
                type: "button",
                onClick: () => ResetForm(),
              }}
            >
              Cancel
            </Btn>
          </>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default MailSidebar;
