import React, { useEffect, Fragment, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import useState from "react-usestateref";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";
import { DocumentAttach } from ".";

import { Api_BidAucationFileAttach } from "../../../api/Admin/Document";
import { toast } from "react-toastify";
const DocTableList = ({ tableData, objShowBtnAction, showPartner, contractsID, ViewFileBA, documentType }) => {
  const navigate = useNavigate();
  const [DocumentName, setDocumentName] = useState("");
  const [modal, setModal] = useState(false);
  const [modalFile, setmodalFile] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalLetter, setmodalLetter] = useState(false);
  const [modalPayments, setmodalPayments] = useState(false);

  const [sumRows, setsumRows] = useState(0);
  const [objConfrim, setobjConfrim] = useState({
    id: "",
    confrim: "Yes",
    docLogType: "",
    description: "",
  });
  const tableInstanceRef = useRef(null);
  const scollToRef = useRef();
  const FormatDate = (val) => {
    var date = new Date(val);
    var formattedDate = format(date, "MMMM d, yyyy");
    return formattedDate;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setsumRows(tableData.length);
        scollToRef.current.scrollIntoView();
      } catch (err) {}
    };

    fetchData();
  }, [tableData]);
  const [objdata, setobjdata, objdataRef] = useState({
    documentType: documentType,
    docID: "",
    contractMId: contractsID,
    typeID: "0",
  });
  const AttachFile = async (docID) => {
    try {
      if (contractsID) {
        if (docID != 0) {
          setobjdata({ ...objdata, docID: docID });
        }
        const data = objdataRef.current;
        const response = await Api_BidAucationFileAttach(data);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("The file is attached");
          ViewFileBA();
          setmodalLetter(false);
          setmodalPayments(false);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
      console.log(error);
    }
  };

  const toggle = () => setModal(!modal);
  const onChangeLetterType = async (event) => {
    setobjdata({
      ...objdata,
      typeID: event.target.value,
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },
      {
        accessorKey: "docDescription",
        header: "Doc.Description",
      },
      {
        accessorKey: "fillingMainStructure1Description",
        header: "Doc.Type",
      },
      {
        header: "Project/Plant",
        Cell: ({ cell }) =>
          cell.row.original.projectsDescription != null ? (
            <span>{cell.row.original.projectsDescription}</span>
          ) : cell.row.original.plantsDescription != null ? (
            <span>{cell.row.original.plantsDescription}</span>
          ) : null,
      },
      {
        accessorKey: "fillingMainStructure2Description",
        header: "Filling",
      },
      {
        header: "System Code",
        accessorKey: "systemCode",
        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },

      {
        accessorKey: "producerName",
        header: "Producer Name",
      },
      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        accessorKey: "versionID",
        header: "Ver",
      },
      {
        accessorKey: "letterPartner",
        header: "Partner",

        Cell: ({ cell }) => <span>{cell.row.original.letterPartner}</span>,
      },
      {
        accessorKey: "archiveNo",
        header: "Archive No",
      },
      {
        header: "File",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setDocumentName(cell.row.original.docTitle);
                  setobjConfrim({
                    ...objConfrim,
                    id: cell.row.id,
                    confrim: cell.row.original.confrim,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Attach",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  if (documentType == "Letter") {
                    setobjdata({ ...objdata, docID: cell.row.id });
                    setDocumentName(cell.row.original.docTitle);
                    setmodalLetter(true);
                  } else if (documentType == "Payments") {
                    setobjdata({ ...objdata, docID: cell.row.id });
                    setDocumentName(cell.row.original.docTitle);
                    setmodalPayments(true);
                  } else {
                    setDocumentName(cell.row.original.docTitle);
                    AttachFile(cell.row.id);
                  }
                }}
              >
                Attach
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {tableData != "" && tableData != null ? (
                <Row>
                  <Col sm="12">
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                      </Row>
                    </div>
                    <div ref={scollToRef}>
                      <MaterialReactTable
                        enableStickyHeader
                        muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                        muiTablePaperProps={{
                          elevation: 0,
                          sx: {
                            color: "#fff",
                            borderRadius: "0",
                            border: "0",
                            "& tbody tr:nth-of-type(odd)": {
                              backgroundColor: "#f5f5f5",
                            },
                            "& thead tr": {
                              backgroundColor: "#16449D",
                              color: "#fff",
                            },
                          },
                        }}
                        muiTableHeadCellProps={{
                          sx: (theme) => ({
                            color: "#fff",
                          }),
                        }}
                        muiTableHeadCellDragHandleProps={{
                          sx: (theme) => ({
                            color: "#fff",
                          }),
                        }}
                        muiTableHeadCellColumnActionsButtonProps={{
                          sx: (theme) => ({
                            color: "#fff",
                          }),
                        }}
                        muiTableHeadCellFilterTextFieldProps={{
                          sx: (theme) => ({
                            color: "#fff",
                          }),
                        }}
                        getRowId={(originalRow) => originalRow.id}
                        columns={columns}
                        data={tableData}
                        enableColumnOrdering
                        enableExpanding
                        enablePagination={false}
                        enableRowNumbers
                        enableSorting={true}
                        enableColumnDragging={false}
                        onRowSelectionChange={setRowSelection}
                        state={{ rowSelection }}
                        tableInstanceRef={tableInstanceRef}
                        initialState={{
                          columnVisibility: {
                            Actions: objShowBtnAction.edit,
                            letterPartner: showPartner,
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modalFile} size="lg">
        <ModalHeader>{DocumentName}</ModalHeader>
        <ModalBody>
          <DocumentAttach disabledbtn={true} obj={objConfrim} documentID={objConfrim.id} contractsID={contractsID} ViewFileBA={ViewFileBA} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFile(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalLetter}>
        <ModalHeader>Letters Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <Col sm="12">
              <FormGroup>
                <Input type="select" name="typeID" className="form-control" onChange={onChangeLetterType}>
                  <option value="0" key="0">
                    Select...
                  </option>
                  <option value="1" key="1">
                    The Contract Notification
                  </option>
                  <option value="2" key="2">
                    The Contract Commencement Date
                  </option>
                  <option value="3" key="3">
                    The ContractChange Scope
                  </option>
                  <option value="4" key="4">
                    The Contract Payments
                  </option>
                  <option value="5" key="5">
                    The Contract Finished Work
                  </option>
                  <option value="6" key="6">
                    The Contract Handover
                  </option>
                  <option value="7" key="7">
                    Others
                  </option>
                </Input>
              </FormGroup>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" disabled={objdata.typeID == "0" ? true : false} onClick={() => AttachFile(0)}>
            Attach
          </Button>
          <Button
            color="light"
            onClick={() => {
              setobjdata({
                ...objdata,
                docID: 0,
              });
              setmodalLetter(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalPayments}>
        <ModalHeader>Payments Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <Col sm="12">
              <FormGroup>
                <Input type="select" name="typeID" className="form-control" onChange={onChangeLetterType}>
                  <option value="0" key="0">
                    Select...
                  </option>
                  <option value="1" key="1">
                    Advance Payment
                  </option>
                  <option value="2" key="2">
                    Invoice Payment
                  </option>
                  <option value="3" key="3">
                    Settiement Payment
                  </option>
                  <option value="4" key="4">
                    Others
                  </option>
                </Input>
              </FormGroup>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" disabled={objdata.typeID == "0" ? true : false} onClick={() => AttachFile(0)}>
            Attach
          </Button>
          <Button
            color="light"
            onClick={() => {
              setobjdata({
                ...objdata,
                docID: 0,
              });
              setmodalPayments(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DocTableList;
