import React, { useEffect, useState, Fragment } from "react";
import { Api_AddSection, Api_EditSection, Api_SectionList, Api_DeleteSection, Api_GetSection } from "../../../api/Admin/Sections";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Table } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";

const ListSections = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);

  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
  });

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const response = await Api_SectionList();
    console.log(response);
    if (response.data.data != "") {
      setTableData(response.data.data);
    } else {
      setTableData(null);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setLoadingBtn(true);
    if (SelectedRowID == 0) {
      const response = await Api_AddSection(obj);
      setLoadingBtn(false);
      if (response.status == "200" && response.data.isSuccess == true) {
        toast.success("A new Section has been added");
        LoadData();
        setobj({
          ...obj,
          description: "",
          code: "",
          id: 0,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
          SelectedRowID(0);
        } else {
          toast.error("Error");
        }
      }
    } else {
      const response = await Api_EditSection(SelectedRowID, obj);
      setLoadingBtn(false);
      if (response.status == "200" && response.data.isSuccess == true) {
        toast.success("Information edited successfully");
        setSelectedRowID(0);
        LoadData();
        setobj({
          ...obj,
          description: "",
          code: "",
          id: 0,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    const response = await Api_DeleteSection(id);
    setModal(false);
    if (response.status == "200" && response.data.isSuccess == true) {
      toast.success("Section Is Removed");
      setSelectedRowID(0);
      RemoveFromList(id);
    } else {
      if (response.response.status == "500") {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error");
      }
    }
  };

  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    } finally {
      setSelectedRowID(0);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetSection(id);
      console.log(response);
      setSelectedRowID(id);
      if (response.status == "200" && response.data.isSuccess == true) {
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          remarks: response.data.data.remarks,
          companyID: response.data.data.companyID,
          id: response.data.data.id,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }
    } catch (err) {
      console.log(err.message);
      // setLoading(false);
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      id: 0,
    });
    setSelectedRowID(0);
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Sections" />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="10">
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <InputGroup>
                          <Input name="description" className="form-control" value={obj.description} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Code"}</Label>
                        <InputGroup>
                          <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} required autoComplete="off" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="bg-primary">
                          <tr>
                            <th scope="col">{"Description"}</th>
                            <th scope="col">{"Code"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.description}</td>
                              <td>{item.code}</td>
                              <td>
                                <>
                                  <span>
                                    <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(item.id)}>
                                      Edit
                                    </Button>
                                  </span>
                                  <span>
                                    <Button
                                      className="btn btn-danger btn-xs"
                                      onClick={() => {
                                        setSelectedRowID(item.id);
                                        toggle();
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </span>
                                </>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListSections;
