import React, { useEffect, Fragment, useContext } from "react";
import { Btn, Spinner, Ribbon } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroupText,
} from "reactstrap";
import { Api_CompanyListBasic, Api_CompanyCode } from "../../../api/Admin/Company";
import { Api_ProjectsList, Api_GetProjectCode } from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import { Api_ProducerCompanyList } from "../../../api/Admin/ProducerCompany";
import { Api_CheckAccess } from "../../../api/Admin/Doc";
import { Api_Add, Api_Get, Api_Edit } from "../../../api/Admin/BidAucation";
import { Api_CurrencyList, Api_NationalList } from "../../../api/Admin/National";
import { Api_GetUserAccessSecForDoc } from "../../../api/Admin/UserSecurityAccess";
import { BAAttach, DocLog, DocCodeShow, Risk } from ".";
import DatePicker from "react-datepicker";
import MemberTypeContext from "../../../_helper/MemberType/index";
const BidAucation = () => {
  const { bidAuctionID } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [BreadcrumbsTitle, setBreadcrumbsTitle] = useState("");
  const [baMod, setbaMod] = useState("Bid");
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [disabledFiled, setdisabledFiled] = useState(false);
  const [securityList, setsecurityList] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(false);
  const [LisCurrency, setLisCurrency] = useState([]);
  const [NationalList, setNationalList] = useState([]);

  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    title: "",
    remarks: "",
    currency: "USD $",
    technicalSpecification: "",
    bidAucationType: "Bid",
    costCenter: "",
    costCenterCode: "",
    estimation: "",
    winnerAmmount: "",
    position: "Active",
    confrim: "Waiting",
    contractStatus: "NotStarted",
    producerID: null,
    producerode: "",
    registerDate: new Date(),
    startDate: "",
    firstRenewalDate: "",
    finishedDate: "",
    duration: "",
    lastRenewalDate: "",
    companyCode: "",
    projectCode: "",
    plantCode: "",
    bidAucationCode: "B",
    kindCode: "P",
    characterCode: "B",
    security: "LowSecret",
    contractKindType: "Public",
    winnerName: "",
    nationalID: "",
    priority: "Medium",
    yearCode: "",
  });

  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
    fileAttcah: false,
  });
  const themes = ["classic"];

  const [curDate, setcurDate] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [RegisterDate, setRegisterDate] = useState(new Date());
  const [FinishedDate, setFinishedDate] = useState(new Date());
  const [FirstRenewalDate, setFirstRenewalDate] = useState(new Date());
  const [LastRenewalDate, setLastRenewalDate] = useState(new Date());

  useEffect(() => {
    // setcurDate(`${format(date, "do  MMMM yyyy")}`);

    const fetchData = async () => {
      try {
        LoadCompanyList();

        LoadCurrencyList();
        LoadNationalList();
        CheckAccessBtn();
        AllAccessSec();
      } catch (err) {}
    };
    if (bidAuctionID) {
      onChangeCompany(obj.companyID, false);
      setBreadcrumbsTitle("Edit Bid , Auction");
      GetDataForEdit(bidAuctionID);
    } else {
      onChangeCompany(obj.companyID, true);
      setBreadcrumbsTitle("New Bid , Auction");
    }
    fetchData();
  }, []);
  const CheckAccessBtn = async () => {
    try {
      setLoading(true);
      const response = await Api_CheckAccess();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const AllAccessSec = async () => {
    try {
      setLoading(true);
      const response = await Api_GetUserAccessSecForDoc();

      setsecurityList(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("Api_GetUserAccessSecForDoc error:" + error);
      setLoading(false);
    }
  };

  //Company
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);

      LoadProducerList(obj.companyID);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val, IsSetObj) => {
    LoadProjectList(val);
    LoadPlantList(val);
    GetCompanyCode(val, IsSetObj);
    LoadProducerList(val);
  };
  const GetCompanyCode = async (id, IsSetObj) => {
    try {
      if (id > 0 && IsSetObj == true) {
        const response = await Api_CompanyCode(id);
        setobj({
          ...obj,
          companyID: id,
          companyCode: response.data.data.code,
          projectID: 0,
          plantID: 0,
          yearCode: new Date().getFullYear().toString().substring(2, 4),
        });
      }
    } catch (error) {}
  };
  //---Company

  //Project
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeProject = async (val) => {
    if (val > 0) {
      const response = await Api_GetProjectCode(val);
      setobj({
        ...obj,
        projectID: val,
        projectCode: response.data.data.code,
        plantID: 0,
        plantCode: "",
      });
    } else {
      setobj({
        ...obj,
        projectID: 0,
        plantID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };
  //---Project

  //Plannt
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangePlant = async (val) => {
    if (val > 0) {
      const response = await Api_PlantsCode(val);
      setobj({
        ...obj,
        plantID: val,
        plantCode: response.data.data.code,
        projectID: 0,
        projectCode: "",
      });
    } else {
      setobj({
        ...obj,
        plantID: 0,
        projectID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };

  //#region   Date
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      startDate: date,
    });
  };
  const handleLastRenewalDate = (date) => {
    setLastRenewalDate(date);
    setobj({
      ...obj,
      lastRenewalDate: date,
    });
  };
  const handleFinishedDate = (date) => {
    setFinishedDate(date);
    setobj({
      ...obj,
      finishedDate: date,
    });
  };
  const handleFirstRenewalDate = (date) => {
    setFirstRenewalDate(date);
    setobj({
      ...obj,
      firstRenewalDate: date,
    });
  };
  const handleRegisterDate = (date) => {
    setRegisterDate(date);
    setobj({
      ...obj,
      registerDate: date,
      yearCode: date.getFullYear().toString().substring(2, 4),
    });
  };
  //#endregion

  const LoadCurrencyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CurrencyList();

      setLisCurrency(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadNationalList = async () => {
    try {
      setLoading(true);
      const response = await Api_NationalList();
      setNationalList(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async (val) => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(val);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeBA = (val) => {
    setbaMod(val);

    if (val == "Bid") {
      setobj({
        ...obj,
        bidAucationCode: "B",
        bidAucationType: val,
      });
    } else {
      setobj({
        ...obj,
        bidAucationCode: "A",
        bidAucationType: val,
      });
    }
  };
  const onChangeKind = (val) => {
    if (val == "Public") {
      setobj({
        ...obj,
        kindCode: "P",
        contractKindType: val,
      });
    } else if (val == "TowStages") {
      setobj({
        ...obj,
        kindCode: "T",
        contractKindType: val,
      });
    } else if (val == "Limited") {
      setobj({
        ...obj,
        kindCode: "L",
        contractKindType: val,
      });
    } else {
      setobj({
        ...obj,
        kindCode: "F",
        contractKindType: val,
      });
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const CloseForm = () => {
    navigate(`${process.env.PUBLIC_URL}/admin/BidAuction/add`);
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_Get(id);

      if (response.status == "200" && response.data.isSuccess == true) {
        setobj({
          projectID: response.data.data.projectID,
          plantID: response.data.data.plantID,
          companyID: response.data.data.companyID,
          priority: response.data.data.priority,
          security: response.data.data.security,
          producerID: response.data.data.producerID,
          position: response.data.data.position,
          confrim: response.data.data.confrim,
          title: response.data.data.title,
          id: response.data.data.id,
          remarks: response.data.data.remarks,
          currency: response.data.data.currency,
          technicalSpecification: response.data.data.technicalSpecification,
          bidAuctionType: response.data.data.bidAuctionType,
          estimation: response.data.data.estimation,
          winnerAmmount: response.data.data.winnerAmmount,
          contractStatus: response.data.data.contractStatus,
          producerode: response.data.data.producerode,
          registerDate: response.data.data.registerDate,
          finishedDate: response.data.data.finishedDate,
          startDate: response.data.data.startDate,
          bidAucationType: response.data.data.bidAucationType,
          firstRenewalDate: response.data.data.firstRenewalDate,
          duration: response.data.data.duration,
          lastRenewalDate: response.data.data.lastRenewalDate,
          companyCode: response.data.data.companyCode,
          projectCode: response.data.data.projectCode,
          yearCode: response.data.data.yearCode,
          plantCode: response.data.data.plantCode,
          bidAucationCode: response.data.data.bidAucationCode,
          kindCode: response.data.data.kindCode,
          characterCode: response.data.data.characterCode,
          contractKindType: response.data.data.contractKindType,
          winnerName: response.data.data.winnerName,
          userCode: response.data.data.userCode,
          costCenterCode: response.data.data.costCenterCode,
          costCenter: response.data.data.costCenter,
          nationalID: response.data.data.nationalID,
        });

        if (response.data.data.startDate != null) {
          const dateadd = new Date(response.data.data.startDate.toString().substring(0, 10));
          setstartDate(dateadd);
        }
        if (response.data.data.finishedDate != null) {
          const dateadd = new Date(response.data.data.finishedDate.toString().substring(0, 10));
          setFinishedDate(dateadd);
        }
        if (response.data.data.registerDate != null) {
          const dateadd = new Date(response.data.data.registerDate.toString().substring(0, 10));
          setRegisterDate(dateadd);
        }
        if (response.data.data.firstRenewalDate != null) {
          const dateadd = new Date(response.data.data.firstRenewalDate.toString().substring(0, 10));
          setFirstRenewalDate(dateadd);
        }
        if (response.data.data.lastRenewalDate != null) {
          const dateadd = new Date(response.data.data.lastRenewalDate.toString().substring(0, 10));
          setLastRenewalDate(dateadd);
        }

        if (response.data.data.confrim == "Yes") {
          setdisabledFiled(true);
        }
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }

      setLoading(false);
    } catch (err) {
      console.log("err:" + err.message);
    }
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (bidAuctionID) {
        const response = await Api_Edit(bidAuctionID, objRef.current);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Your document has been edited");
          navigate(`${process.env.PUBLIC_URL}/admin/BidAuction/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_Add(objRef.current);

        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("New " + baMod + " Added");
          navigate(`${process.env.PUBLIC_URL}/admin/BidAuction/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  if (Loading == false) {
    return (
      <Fragment>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
          <Container fluid={true}>
            <Breadcrumbs parent="Contract Management" title={BreadcrumbsTitle} Loading={Loading} />
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="companyID"
                            className="form-control"
                            value={obj.companyID}
                            disabled={disabledFiled ? true : false}
                            onChange={(txt) => {
                              onChangeCompany(txt.target.value, true);
                            }}
                          >
                            {ListCompany != null
                              ? ListCompany.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Project"}</Label>
                          <Input
                            type="select"
                            name="projectID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.projectID != null ? obj.projectID : 0}
                            onChange={(txt) => {
                              onChangeProject(txt.target.value);
                            }}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListProject != null
                              ? ListProject.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Plant"}</Label>
                          <Input
                            type="select"
                            name="plantID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.plantID != null ? obj.plantID : 0}
                            onChange={(txt) => {
                              onChangePlant(txt.target.value);
                            }}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListPlant != null
                              ? ListPlant.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Bid / Auction"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="bidAucationType"
                            className="form-control"
                            value={obj.bidAucationType}
                            onChange={(txt) => {
                              onChangeBA(txt.target.value);
                            }}
                          >
                            <option value="Bid" key="Bid">
                              Bid
                            </option>
                            <option value="Aucation" key="Aucation">
                              Auction
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Kind"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="contractKindType"
                            className="form-control"
                            value={obj.contractKindType}
                            onChange={(txt) => {
                              onChangeKind(txt.target.value);
                            }}
                          >
                            <option value="Public" key="Public">
                              Public
                            </option>
                            <option value="TowStages" key="TowStages">
                              Tow Stages
                            </option>
                            <option value="Limited" key="Limited">
                              Limited
                            </option>
                            <option value="LeavingFormalities" key="LeavingFormalities">
                              Leaving Formalities
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="8"></Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input
                              name="title"
                              maxLength={255}
                              className="form-control"
                              value={obj.title || ""}
                              onChange={onObjChange}
                              required={true}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Remarks"}</Label>
                          <InputGroup>
                            <Input
                              name="remarks"
                              type="text"
                              maxLength={255}
                              className="form-control"
                              value={obj.remarks || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Technical Specification"}</Label>
                          <InputGroup>
                            <Input
                              name="technicalSpecification"
                              type="textarea"
                              maxLength={4000}
                              className="form-control"
                              value={obj.technicalSpecification || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Currency"}</Label>
                          <Input
                            type="select"
                            name="currency"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.currency}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {LisCurrency != null
                              ? LisCurrency.map((item) => {
                                  return (
                                    <option key={item.abbreviation} value={item.abbreviation}>
                                      {item.abbreviation}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Priority"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                            <option value="Top" key="Top">
                              Top
                            </option>
                            <option value="Medium" key="Medium">
                              Medium
                            </option>
                            <option value="Low" key="Low">
                              Low
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Security"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="security" className="form-control" value={obj.security} onChange={onObjChange}>
                            {securityList != null && securityList != ""
                              ? securityList.map((item) => {
                                  return (
                                    <option value={item.value} key={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Estimation"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              id="input-example"
                              name="estimation"
                              className="form-control"
                              placeholder=""
                              value={obj.estimation}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => setobj({ ...obj, estimation: value })}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Winner Name"}</Label>
                          <InputGroup>
                            <Input
                              name="winnerName"
                              type="text"
                              maxLength={100}
                              className="form-control"
                              value={obj.winnerName || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Winner Ammount"}</Label>

                          <InputGroup>
                            <CurrencyInput
                              id="input-example"
                              name="winnerAmmount"
                              className="form-control"
                              placeholder=""
                              value={obj.winnerAmmount}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => setobj({ ...obj, winnerAmmount: value })}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Winner Nationality"}</Label>
                          <Input
                            type="select"
                            name="nationalID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.nationalID != null ? obj.nationalID : 0}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {NationalList != null
                              ? NationalList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Duration"}</Label>
                          <InputGroup>
                            <Input
                              name="duration"
                              type="number"
                              maxLength={7}
                              className="form-control"
                              value={obj.duration || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                            <InputGroupText>Days</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Register Date</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={RegisterDate}
                            onChange={handleRegisterDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Start Date</Label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={startDate}
                            onChange={handleStartDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>First Renewal Date</Label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={FirstRenewalDate}
                            onChange={handleFirstRenewalDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Last Renewal Date</Label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={LastRenewalDate}
                            onChange={handleLastRenewalDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Finished Date</Label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={FinishedDate}
                            onChange={handleFinishedDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Position"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="position" className="form-control" value={obj.position} onChange={onObjChange}>
                            <option value="Active" key="Active">
                              Active
                            </option>
                            <option value="Inactive" key="Inactive">
                              Inactive
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Status"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="contractStatus" className="form-control" value={obj.contractStatus} onChange={onObjChange}>
                            <option value="NotStarted" key="NotStarted">
                              Not Started
                            </option>
                            <option value="Hold" key="Hold">
                              Hold
                            </option>
                            <option value="InProgress" key="InProgress">
                              In Progress
                            </option>
                            <option value="Finished" key="Finished">
                              Finished
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Producer"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="producerID"
                            className="form-control"
                            value={obj.producerID == null ? 0 : obj.producerID}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListProducer != null
                              ? ListProducer.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Producer  Code"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input
                              name="producerode"
                              maxLength={255}
                              className="form-control"
                              value={obj.producerode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <hr></hr>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"User Code"}</Label>
                          <InputGroup>
                            <Input
                              name="userCode"
                              maxLength={100}
                              className="form-control"
                              value={obj.userCode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Cost Center"}</Label>
                          <InputGroup>
                            <Input
                              name="costCenter"
                              maxLength={255}
                              className="form-control"
                              value={obj.costCenter || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Cost Center Code"}</Label>
                          <InputGroup>
                            <Input
                              name="costCenterCode"
                              maxLength={100}
                              className="form-control"
                              value={obj.costCenterCode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <DocCodeShow objSystemCode={objRef.current} />
                    </Row>
                    <Row>
                      <Col sm="12">
                        {bidAuctionID && obj.confrim != "Deleted" ? (
                          <Button className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : bidAuctionID ? (
                              "Save"
                            ) : (
                              "Add"
                            )}
                          </Button>
                        ) : (
                          <>
                            <Button className="btn btn-success btn-md m-r-15">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : bidAuctionID ? (
                                "Save"
                              ) : (
                                "Add"
                              )}
                            </Button>
                          </>
                        )}

                        {bidAuctionID ? (
                          <>
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => CloseForm(),
                              }}
                            >
                              Close
                            </Btn>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {bidAuctionID ? (
              <BAAttach disabledbtn={false} obj={obj} bidAuctionID={bidAuctionID} />
            ) : (
              <BAAttach disabledbtn={true} obj={obj} bidAuctionID={0} />
            )}
            {bidAuctionID ? <DocLog bidAuctionID={bidAuctionID} /> : null}
          </Container>
        </Form>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default BidAucation;
