import axios from "axios";
import { URL, Version } from "../URL";
const Api_GetDocLog = async (id, mod) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${URL}${Version}/DocLog/GetAll/${id}/${mod}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_GetDocLog };
