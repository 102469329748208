import axios from "axios";
import { URL, Version } from "../URL";

const Api_FillingMainStructuresListCompany = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetAllForCompany/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_FillingMainStructuresListCompany_Parent = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetAllForCompany_Parent/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_FillingMainStructuresListCompany_Parent_level1 = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetAllForCompany_Parent_level1/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_FillingMainStructuresListCompanyChild = async (id, parenid) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetAllForCompanyChild/${id}/${parenid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetFillingMainStructures = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/Get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditFillingMainStructures = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/FillingMainStructure?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddFillingMainStructures = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/FillingMainStructure`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteFillingMainStructures = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/FillingMainStructure/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};
const Api_FillingCode = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetCode/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_FillingGetPath = async (id, companyid) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetPath/${id}/${companyid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetChildsByCode = async (companyid, code) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetChildsByCode/${companyid}/${code}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

//---نمایش لیست داک تایپ برای داشبورد
const Api_GetDcoType_Dashboard = async (companyid) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/FillingMainStructure/GetDcoType_Dashboard/${companyid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
//-L--نمایش لیست فایلینگ برای داشبورد
const Api_GetFilling_Dashboard = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Doc/GetFilling_Dashboard/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetFilling_D_Dashboard = async (data) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.post(`${URL}${Version}/Doc/GetFilling_D_Dashboard`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_FillingMainStructuresListCompanyChild,
  Api_FillingMainStructuresListCompany,
  Api_GetFillingMainStructures,
  Api_EditFillingMainStructures,
  Api_AddFillingMainStructures,
  Api_DeleteFillingMainStructures,
  Api_FillingMainStructuresListCompany_Parent,
  Api_FillingMainStructuresListCompany_Parent_level1,
  Api_FillingCode,
  Api_FillingGetPath,
  Api_GetChildsByCode,
  Api_GetDcoType_Dashboard,
  Api_GetFilling_Dashboard,
  Api_GetFilling_D_Dashboard,
};
