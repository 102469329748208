import React, { useEffect, Fragment, useRef, useContext } from "react";
import {
  Btn,
  H6,
  Image,
  P,
  Spinner,
  Ribbon,
  H5,
} from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Api_GetDocLog } from "../../../api/Admin/DocLog";
import teacher from "../../../assets/images/avtar/teacher.png";
import { Media } from "reactstrap";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  InputGroupText,
} from "reactstrap";
const DocLog = ({ documentID }) => {
  const [logList, setlogList] = useState(null);
  useEffect(() => {
    if (documentID != "" && documentID != 0) {
      ViewlOG();
    } else {
      console.log("not use DocLog:" + documentID);
    }
  }, [documentID]);
  const ViewlOG = async () => {
    try {
      console.log("documentID 2:" + documentID);
      const response = await Api_GetDocLog(documentID, "Doc");
      setlogList(response.data.data);
      console.log("Api_GetDocLog:" + response.data.data);
    } catch (error) {}
  };
  if (logList != null && logList != "") {
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <Card className="ribbon-wrapper-right">
              <CardBody>
                <Ribbon
                  attrRibbons={{
                    className: "ribbon ribbon ribbon-clip ribbon-secondary",
                  }}
                >
                  Document Logs
                </Ribbon>
                <Row>
                  <Col sm="12">
                    {logList != null ? (
                      <div className="table-responsive">
                        <Table className="table-bordernone">
                          <tbody>
                            {logList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Media>
                                      <div className="square-box me-2">
                                        <Image
                                          attrImage={{
                                            className: "img-fluid b-r-5",
                                            src: `${teacher}`,
                                            alt: "",
                                          }}
                                        />
                                      </div>
                                      <Media body>
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/user/userprofile`}
                                        >
                                          <H5>
                                            {item.userName} {item.userFamily}
                                          </H5>
                                          <P
                                            attrPara={{
                                              className:
                                                item.docLogType == "Yes"
                                                  ? "font-success"
                                                  : item.docLogType == "No"
                                                  ? "font-danger"
                                                  : "font-primary",
                                            }}
                                          >
                                            Confrim:{item.docLogType}
                                          </P>
                                        </Link>

                                        <P attrPara={{ className: "txt-dark" }}>
                                          {item.description}
                                        </P>
                                      </Media>
                                    </Media>
                                  </td>
                                  <td>
                                    <span className="badge badge-light-theme-light font-theme-light">
                                      {item.dateAdd.substring(0, 15)}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  } else {
    return null;
  }
};
export default DocLog;
