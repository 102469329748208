import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Alerts, Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { DocumentAttach } from ".";
import { format } from "date-fns";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_WatingList, Api_ConfrimDocument } from "../../../api/Admin/Doc";
import MaterialReactTable from "material-react-table";
import { Api_ProjectsList, Api_GetProjectCode } from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const WaitingList = () => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const { ParamCompanyID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [DocumentName, setDocumentName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalFile, setmodalFile] = useState(false);
  const [CompanyID, setCompanyID] = useState(DefaultCompanyID);
  const [ListCompany, setListCompany] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [ProjectID, setProjectID] = useState(0);
  const [PlantID, setPlantID] = useState(0);
  const [modalConfrim, setmodalConfrim] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [objConfrim, setobjConfrim] = useState({
    id: "",
    confrim: "Waiting",
    docLogType: "Waiting",
    description: "",
  });
  const tableInstanceRef = useRef(null);
  const onChangeCompany = (val) => {
    if (val > 0) {
      setCompanyID(val);
      LoadDoucmentListCompany(val, ProjectID, PlantID);
      LoadProjectList(val);
      LoadPlantList(val);
    } else {
      setCompanyID(0);
      setTableData(null);
    }
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const LoadDoucmentListCompany = async (companyID, projectID, plantID) => {
    try {
      setLoading(true);
      const response = await Api_WatingList(companyID, projectID, plantID);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setTableData(response.data.data);
        setsumRows(response.data.data.length);
        setLoading(false);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onObjChangeConfrim = async (event) => {
    setobjConfrim({
      ...objConfrim,
      [event.target.name]: event.target.value,
    });
  };
  const onObjChangeConfrim2 = async (event) => {
    setobjConfrim({
      ...objConfrim,
      confrim: event.target.value,
      docLogType: event.target.value,
    });
  };
  const ConfrimDocument = async () => {
    try {
      if (objConfrim.description == "" && objConfrim.confrim != "Yes") {
        toast.error("Description Is Required");
      } else {
        setLoading(true);
        const response = await Api_ConfrimDocument(objConfrim);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("saved");
          setLoading(false);
          if (objConfrim.confrim == "Waiting") {
            const AllData = [...tableData];
            const DataIndex = AllData.findIndex((p) => p.id == objConfrim.id);
            const TempData = AllData[DataIndex];
            TempData.confrim = objConfrim.confrim;
            AllData[DataIndex] = TempData;
            setTableData(AllData);
          } else {
            const updatedContact = tableData.filter((c) => c.id !== objConfrim.id);
            setTableData(updatedContact);
          }
          setmodalConfrim(false);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
          setmodalConfrim(false);
        }
      }
      setLoading(false);
      setmodalConfrim(false);
    } catch (error) {
      setLoading(false);
      setmodalConfrim(false);
      toast.error("Error - 0");
    }
  };
  const onChangeProject = (val) => {
    setProjectID(val);
    setPlantID(0);
    LoadDoucmentListCompany(CompanyID, val, PlantID);
  };
  const onChangePlant = (val) => {
    setProjectID(0);
    setPlantID(val);
    LoadDoucmentListCompany(CompanyID, ProjectID, val);
  };
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    if (ParamCompanyID) {
      setCompanyID(ParamCompanyID);
      LoadDoucmentListCompany(ParamCompanyID, ProjectID, PlantID);
    }

    const fetchData = async () => {
      try {
        LoadCompanyList();
        if (!ParamCompanyID) {
          onChangeCompany(CompanyID, false);
        }
      } catch (err) {}
    };

    fetchData();
  }, []);
  const FormatDate = (val) => {
    var date = new Date(val);

    var formattedDate = format(date, "MMMM d, yyyy");

    return formattedDate;
  };
  const toggle = () => setModal(!modal);
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      DocTitle: item.docTitle,
      DocType: item.fillingMainStructure1Description,
      Filling: item.fillingMainStructure2Description,
      SystemCode: item.systemCode,
      RegisterDate: FormatDate(item.dateAdd),
      Recorder: item.userName + " " + item.userFamily,
    }));
    exportToExcel(customHeadings, "Documents Waiting List");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
    });
    let RowNumber = 1;
    const customRows = tableData.map((item) => [
      RowNumber++,
      item.docTitle,
      item.fillingMainStructure1Description,
      item.fillingMainStructure2Description,
      item.systemCode,
      FormatDate(item.dateAdd),
      item.userName + " " + item.userFamily,
    ]);
    autoTable(doc, {
      head: [["Row Number", "DocTitle", "DocType", "Filling", "SystemCode", "RegisterDate", "Recorder"]],
      body: customRows,
    });
    doc.text("Documents Waiting List", 14, 10);
    doc.save("Documents Waiting List.pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Document Title",
      },
      {
        accessorKey: "fillingMainStructure1Description",
        header: "Doc.Type",
      },

      {
        accessorKey: "fillingMainStructure2Description",
        header: "Filling",
      },
      {
        header: "System Code",
        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },
      {
        header: "Register Date",
        Cell: ({ cell }) => <span>{FormatDate(cell.row.original.dateAdd)}</span>,
      },
      {
        header: "Recorder",
        Cell: ({ cell }) => <span>{cell.row.original.userName + " " + cell.row.original.userFamily}</span>,
      },
      {
        header: "Detail Sheet",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Document/edit/${cell.row.id}`)}>
                D
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "File",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setDocumentName(cell.row.original.docTitle);
                  setobjConfrim({ ...objConfrim, id: cell.row.id, confrim: cell.row.original.confrim });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Confrim",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className={
                  cell.row.original.confrim == "Waiting"
                    ? "btn btn-info mr-5 btn-xs"
                    : cell.row.original.confrim == "Yes"
                    ? "btn btn-success mr-5 btn-xs"
                    : "btn btn-danger mr-5 btn-xs"
                }
                onClick={() => {
                  setDocumentName(cell.row.original.docTitle);
                  setobjConfrim({ ...objConfrim, id: cell.row.id, confrim: cell.row.original.confrim });
                  setmodalConfrim(true);
                }}
              >
                {cell.row.original.confrim}
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Documents" title="Documents Waiting List" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label>
                        <Input
                          type="select"
                          name="companyID"
                          className="form-control digits"
                          value={CompanyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Project"}</Label>
                        <Input
                          type="select"
                          name="projectID"
                          className="form-control digits"
                          value={ProjectID}
                          onChange={(txt) => {
                            onChangeProject(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListProject != null
                            ? ListProject.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Plant"}</Label>
                        <Input
                          type="select"
                          name="plantID"
                          className="form-control digits"
                          value={PlantID}
                          onChange={(txt) => {
                            onChangePlant(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListPlant != null
                            ? ListPlant.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {tableData != "" && tableData != null ? (
                        <Row>
                          <Col sm="12">
                            <Card>
                              <CardBody>
                                <div className="lbl-sum-Table">
                                  <Row>
                                    <Col md="6">
                                      <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                                    </Col>
                                    <Col md="6 text-right" className="export-iconList">
                                      <i
                                        onClick={() => {
                                          handleExportExcel();
                                        }}
                                        className="fa fa-file-excel-o"
                                      ></i>
                                      <i
                                        onClick={() => {
                                          handleExportPdf();
                                        }}
                                        className="fa fa-file-pdf-o"
                                      ></i>
                                    </Col>
                                  </Row>
                                </div>
                                <MaterialReactTable
                                  enableStickyHeader
                                  muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                                  muiTablePaperProps={{
                                    elevation: 0,
                                    sx: {
                                      color: "#fff",
                                      borderRadius: "0",
                                      border: "0",
                                      "& tbody tr:nth-of-type(odd)": {
                                        backgroundColor: "#f5f5f5",
                                      },
                                      "& thead tr": {
                                        backgroundColor: "#16449D",
                                        color: "#fff",
                                      },
                                    },
                                  }}
                                  muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                      color: "#fff",
                                    }),
                                  }}
                                  muiTableHeadCellDragHandleProps={{
                                    sx: (theme) => ({
                                      color: "#fff",
                                    }),
                                  }}
                                  muiTableHeadCellColumnActionsButtonProps={{
                                    sx: (theme) => ({
                                      color: "#fff",
                                    }),
                                  }}
                                  muiTableHeadCellFilterTextFieldProps={{
                                    sx: (theme) => ({
                                      color: "#fff",
                                    }),
                                  }}
                                  getRowId={(originalRow) => originalRow.id}
                                  columns={columns}
                                  data={tableData}
                                  enableColumnOrdering
                                  enableExpanding
                                  enableColumnDragging={false}
                                  enablePagination={false}
                                  onRowSelectionChange={setRowSelection}
                                  state={{ rowSelection }}
                                  enableRowNumbers
                                  tableInstanceRef={tableInstanceRef}
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      ) : Loading == false ? (
                        <Alerts attrAlert={{ color: "danger" }}>{"Empty List"}</Alerts>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modalConfrim}>
        <ModalHeader>{DocumentName}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <Col sm="12">
              <FormGroup>
                <Label htmlFor="exampleFormControlSelect9">{"Confrim"}</Label>
                <Input type="select" name="confrim" className="form-control" value={objConfrim.confrim} onChange={onObjChangeConfrim2}>
                  <option value="Yes" key="Yes">
                    Yes
                  </option>
                  <option value="No" key="No">
                    No
                  </option>
                  <option value="Waiting" key="Waiting">
                    Waiting
                  </option>
                  <option value="Deleted" key="Deleted">
                    Deleted
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                <InputGroup>
                  <Input
                    name="description"
                    autoComplete="off"
                    className="form-control"
                    value={objConfrim.description}
                    onChange={onObjChangeConfrim}
                    required
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => ConfrimDocument()}>
            Save
          </Button>
          <Button color="light" onClick={() => setmodalConfrim(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFile} size="lg">
        <ModalHeader>{DocumentName}</ModalHeader>
        <ModalBody>
          <DocumentAttach openfrom={"watinglist"} serialID={0} disabledbtn={true} obj={objConfrim} documentID={objConfrim.id} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFile(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default WaitingList;
