import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Label, Col, Row, Card, CardBody } from "reactstrap";
import { Image } from "../../../AbstractElements";
const Default = (props) => {
  const styles = { width: "100%" };
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ marginTop: "10px" }}>
            <Card>
              <CardBody>
                <Image attrImage={{ src: require(`../../../assets/images/dashboard.jpg`), style: styles, alt: "" }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Default;
