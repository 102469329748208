import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn } from "../../../AbstractElements";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import DatePicker from "react-datepicker";
import { Api_MeetingPlansList, Api_DeleteMeetingPlans, Api_ChangeIsDone } from "../../../api/Admin/MeetingPlans";

const MeetingPlansList = () => {
  const navigate = useNavigate();
  const { meetingPlanID } = useParams();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [obj, setobj, objRef] = useState({
    id: 0,
    title: "",
    startDate: "",
    endDate: "",
    meetingPlanID: 0,
  });
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      startDate: date,
    });
    LoadData(true);
  };
  const handleEndDate = (date) => {
    setendDate(date);
    setobj({
      ...obj,
      endDate: date,
    });
    LoadData(true);
  };
  useEffect(() => {
    if (meetingPlanID) {
      setobj({
        ...obj,
        meetingPlanID: meetingPlanID,
      });
    }
    LoadData(false);
  }, []);
  const LoadData = async (btnClick) => {
    try {
      if (btnClick == true) {
        setobj({
          ...obj,
          meetingPlanID: 0,
        });
      }
      setLoading(true);
      const newobj = objRef.current;

      const response = await Api_MeetingPlansList(newobj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          if (btnClick == true) {
            toast.warning("No results found");
          }
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteMeetingPlans(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Meeting plan Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const toggle = () => setModal(!modal);
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const showMore = async (id) => {
    try {
      const AllData = [...tableData];
      const DataIndex = AllData.findIndex((p) => p.id == id);
      const TempData = AllData[DataIndex];
      TempData.show = !TempData.show;
      AllData[DataIndex] = TempData;
      setTableData(AllData);
    } catch (err) {
      toast.error("Error");
    }
  };
  const styless = {
    backgroundImage: `url(${blogSingle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeDone = async (id, val) => {
    try {
      setLoading(true);
      const response = await Api_ChangeIsDone(id, val);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Information edited successfully");
        const AllData = [...tableData];
        const DataIndex = AllData.findIndex((p) => p.id == id);
        const TempData = AllData[DataIndex];
        TempData.isDone = val;
        AllData[DataIndex] = TempData;
        setTableData(AllData);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Meetings Plans" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label>Date From</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          dateFormat="MMMM d, yyyy"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>To</Label>
                        <DatePicker className="datepicker-here form-control" dateFormat="MMMM d, yyyy" selected={endDate} onChange={handleEndDate} />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label>{"Parameters"}</Label>
                        <InputGroup>
                          <Input name="title" className="form-control" value={obj.title} onChange={onInputChange} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <InputGroup>
                          <Btn
                            attrBtn={{
                              color: "success",
                              type: "button",
                              style: { marginTop: 40 },
                              onClick: () => LoadData(true),
                            }}
                          >
                            Search
                          </Btn>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <div className="activity-media">
              {tableData.map((item) => {
                return (
                  <Row key={item.id}>
                    <Col sm="12">
                      <div className="blog-single">
                        <div className="blog-box blog-details">
                          <Card>
                            <CardBody>
                              <div className="blog-details">
                                <Row>
                                  <Col sm="12">
                                    <UL
                                      attrUL={{
                                        className: "blog-social plan  flex-row simple-list",
                                      }}
                                    >
                                      <LI>Priority : {item.priority}</LI>
                                      <LI>Remarks : {item.remarks}</LI>
                                      <LI>Date : {item.date.toString().substring(0, 10)}</LI>
                                      {item.time ? <LI>Time : {item.time}</LI> : null}
                                    </UL>
                                    <H4
                                      attrH4={{
                                        className: "plan-Title",
                                      }}
                                    >
                                      <span>Title :</span> <span>{item.title}</span>
                                    </H4>
                                    {item.show == true ? (
                                      <div className="plan-Title">
                                        <H4>
                                          <span> Description :</span>
                                        </H4>

                                        <div className="box-readmore" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                        <div onClick={() => showMore(item.id)} className="ShowMore">
                                          <i className="fa fa-angle-up"></i>
                                        </div>
                                      </div>
                                    ) : (
                                      <div onClick={() => showMore(item.id)} className="ShowMore">
                                        <i className="fa fa-angle-down"></i>
                                      </div>
                                    )}
                                  </Col>

                                  <Col sm="12">
                                    <div style={{ marginTop: 20, textAlign: "right" }}>
                                      <FormGroup className="form-group">
                                        <div className="checkbox">
                                          <Input
                                            id={item.id + "checkboxDone"}
                                            type="checkbox"
                                            defaultChecked={item.isDone}
                                            onClick={() => onChangeDone(item.id, !item.isDone)}
                                          />
                                          <Label className="text-muted" for={item.id + "checkboxDone"}>
                                            Is Done
                                          </Label>
                                        </div>
                                      </FormGroup>
                                    </div>
                                  </Col>
                                  <Col sm="12">
                                    <div style={{ marginTop: 20, textAlign: "right" }}>
                                      <span>
                                        <Button
                                          className="btn btn-info mr-5 btn-xs"
                                          onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/MeetingPlans/Edit/${item.id}`)}
                                        >
                                          Edit
                                        </Button>
                                      </span>

                                      <span>
                                        <Button
                                          className="btn btn-danger btn-xs"
                                          onClick={() => {
                                            setSelectedRowID(item.id);
                                            toggle();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default MeetingPlansList;
