import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import useState from "react-usestateref";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { Alerts, Ribbon } from "../../../AbstractElements";
import { Api_GetDailyPlansDashboard } from "../../../api/Admin/DailyPlans";
import MaterialReactTable from "material-react-table";
const DailyPlans = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const tableInstanceRef = useRef(null);
  const [rowSelection, setRowSelection] = useState({});
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_GetDailyPlansDashboard();

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columnsInbox = useMemo(() => [
    {
      header: "Title",
      enableHiding: false,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <>
          <Link className="show" to={`${process.env.PUBLIC_URL}/admin/DailyPlans/Show/${cell.row.original.id}`}>
            <span>{cell.row.original.title}</span>
          </Link>
        </>
      ),
    },
  ]);
  if (Loading == false) {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Ribbon attrRibbons={{ className: "ribbon ribbon ribbon-clip ribbon-secondary" }}>
                <Link to={`${process.env.PUBLIC_URL}/admin/DailyPlans`}>Daily Plans</Link>
              </Ribbon>
            </CardHeader>
            <CardBody>
              {tableData != null && tableData != "" ? (
                <div className="activity-media">
                  {tableData != null ? (
                    <Row>
                      <Col sm="12 hideToolbar">
                        <MaterialReactTable
                          enableStickyHeader
                          muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              color: "#fff",
                              borderRadius: "0",
                              border: "0",
                              "& tbody tr:nth-of-type(odd)": {
                                backgroundColor: "#f5f5f5",
                              },
                              "& thead tr": {
                                backgroundColor: "#16449D",
                                color: "#fff",
                              },
                            },
                          }}
                          muiTableHeadCellProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableBodyCellProps={{
                            sx: (theme) => ({
                              fontSize: ".7rem",
                            }),
                          }}
                          muiTableHeadCellDragHandleProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellColumnActionsButtonProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellFilterTextFieldProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          getRowId={(originalRow) => originalRow.id}
                          columns={columnsInbox}
                          data={tableData}
                          enableFilters={false}
                          enableColumnOrdering
                          enableDensityToggle={false}
                          onIsFullScreenChange={false}
                          enableFullScreenToggle={false}
                          enableExpanding={false}
                          enablePagination={false}
                          enableBottomToolbar={false}
                          enableGlobalFilter={false}
                          enableColumnResizing={false}
                          enableColumnDragging={false}
                          onRowSelectionChange={setRowSelection}
                          state={{ rowSelection }}
                          tableInstanceRef={tableInstanceRef}
                          rowNumberMode="static"
                          enableToolbarInternalActions={false}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Alerts attrAlert={{ color: "warning dark" }}>{"Empty List"}</Alerts>
                  )}
                </div>
              ) : (
                <Alerts attrAlert={{ color: "warning dark" }}>{"Empty List"}</Alerts>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default DailyPlans;
