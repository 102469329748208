import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Label, Col, Row, Card, CardBody, Form, Button } from "reactstrap";
import { Api_StaticText } from "../../../api/Admin/StaticText";
import { userAgreePorotocol } from "../../../api/Auth/GetToken";
import { Spinner } from "../../../AbstractElements";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const AgreePorotocol = (props) => {
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  useEffect(() => {
    GetData();
  }, []);

  const [obj, setobj] = useState({
    body: "",
  });
  const GetData = async () => {
    try {
      const response = await Api_StaticText(1);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setobj({
          body: response.data.data.body,
        });
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      const response = await userAgreePorotocol();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setLoadingBtn(false);
        navigate(`${process.env.PUBLIC_URL}/`);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoadingBtn(false);
    } catch (error) {
      toast.error("Error");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Row>
            <Col sm="12" style={{ marginTop: "10px" }}>
              <Card>
                <CardBody>
                  <div dangerouslySetInnerHTML={{ __html: obj.body }}></div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {obj.body != "" ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Button type="submit" className="btn btn-success btn-md m-r-15">
                      {LoadingBtn ? (
                        <div className="loader-box2">
                          <Spinner attrSpinner={{ className: "loader-7" }} />
                        </div>
                      ) : (
                        "Agree"
                      )}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};
export default AgreePorotocol;
