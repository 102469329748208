import React, { useEffect, Fragment, useRef, useContext } from "react";
import { Btn, H6, Image, P, Spinner, Ribbon } from "../../../AbstractElements";
import { Link, useNavigate, useParams, flushSync } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DocTableList } from "./";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  InputGroupText,
} from "reactstrap";
import { Api_CompanyListBasic, Api_CompanyCode } from "../../../api/Admin/Company";
import { Api_ProjectsList } from "../../../api/Admin/Projects";
import { Api_PlantsList } from "../../../api/Admin/Plants";
import DatePicker from "react-datepicker";
import {
  Api_GetFilling_Dashboard,
  Api_GetDcoType_Dashboard,
  Api_FillingCode,
  Api_FillingGetPath,
  Api_GetFilling_D_Dashboard,
} from "../../../api/Admin/Filling";
import { Api_ProducerCompanyList } from "../../../api/Admin/ProducerCompany";
import { Api_ProjectUnitsCode } from "../../../api/Admin/ProjectUnit";
import { Api_PhaseListParent, Api_PhaseCode } from "../../../api/Admin/Phase";
import { Api_DocTypeCode } from "../../../api/Admin/DocType";
import { Api_DisciplineListParent, Api_DisciplineCode } from "../../../api/Admin/Discipline";
import DeniReactTreeView from "deni-react-treeview";
import MemberTypeContext from "../../../_helper/MemberType/index";
const Dashboard = () => {
  const { documentID } = useParams();
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListUnit, setListUnit] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [modalDocTypeTechnical, setmodalDocTypeTechnical] = useState(false);
  const [modalPhase, setmodalPhase] = useState(false);
  const [modalDiscipline, setmodalDiscipline] = useState(false);
  const [modalDocType, setmodalDocType] = useState(false);
  const [modalFilling, setmodalFilling] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [DocTypeName, setDocTypeName] = useState("");
  const [DisciplineName, setDisciplineName] = useState("");
  const [PhaseName, setPhaseName] = useState("");
  const [DocTypeTechnicalName, setDocTypeTechnicalName] = useState("");
  const [modalUnit, setmodalUnit] = useState(false);
  const [UnitName, setUnitName] = useState("");
  const [FillingName, setFillingName] = useState("");
  const [ShowFilling, setShowFilling] = useState(false);
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListDocType, setListDocType] = useState([]);
  const [ListFilling, setListFilling] = useState([]);
  const [ListDiscipline, setListDiscipline] = useState([]);
  const [ListPhase, setListPhase] = useState([]);
  const [ListDocTypeTechnical, setListDocTypeTechnical] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());
  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    docTypeID: 0,
    fillingID: null,
    disciplineID: 2,
    phaseID: 1,
    technicalDocTypeID: null,
    dateFrom: "",
    dateTo: "",
  });

  const [objSystemCode, setobjSystemCode] = useState({
    companyCode: "",
    projectCode: "",
    plantCode: "",
    doctypeCode: "",
    fillingCode: "n",
    rd: "",
    producerode: "",
    year: "",
    unitcode: "",
    phasecode: "",
    disciplinecode: "",
    docTypeTechnicalcode: "",
  });
  const themes = ["classic"];
  const date = new Date();
  useEffect(() => {
    const fetchData = async () => {
      try {
        LoadCompanyList();
        onChangeCompany(obj.companyID, false);
      } catch (err) {}
    };

    fetchData();
  }, [obj.companyID]);
  const handleStartDate = (date) => {
    setstartDate(date);
    setobj({
      ...obj,
      dateFrom: date,
    });
  };
  const handleEndDate = (date) => {
    setendDate(date);
    setobj({
      ...obj,
      dateTo: date,
    });
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      LoadProducerList();
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async () => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(obj.companyID);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadDocType = async (mod, id) => {
    try {
      setLoading(true);
      setListFilling(null);
      if (mod == "company") {
        const response = await Api_GetDcoType_Dashboard(id);

        setListDocType(response.data.data);
      }
      setLoading(false);
    } catch (error) {}
  };
  const LoadFilling = async (parent) => {
    try {
      setLoading(true);
      const response = await Api_GetFilling_Dashboard(parent);

      setListFilling(response.data.data);

      setLoading(false);
    } catch (error) {}
  };

  const LoadDiscipline = async (id) => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(id, "Contractors");
      setListDiscipline(response1.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val) => {
    setobj({
      ...obj,
      companyID: val,
      projectID: 0,
      plantID: 0,
      disciplineID: 2,
      phaseID: 1,
      technicalDocTypeID: null,
      docTypeID: 0,
      fillingID: null,
      dateFrom: "",
      dateTo: "",
    });
    setDocTypeName("");
    LoadProjectList(val);
    LoadPlantList(val);
    LoadDocType("company", val);

    setDisciplineName("");
    setPhaseName("");
    setDocTypeTechnicalName("");
    setFillingName("");
    setTableData(null);
  };

  const GetDocTypeCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);
        if (response.data.data.code.toUpperCase() == "L") {
          LoadFilling(id);
          setListPhase(null);
        } else if (response.data.data.code.toUpperCase() == "M") {
          LoadFilling(id);
          setListPhase(null);
        }
        if (response.data.data.code.toUpperCase() == "D") {
          LoadTechnicalParts();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetFillingCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);
        setobjSystemCode({
          ...objSystemCode,
          fillingCode: response.data.data.code,
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeProject = (val) => {
    setTableData(null);
    setFillingName("");
    setDocTypeName("");
    setDisciplineName("");
    setPhaseName("");
    setDocTypeTechnicalName("");
    if (val > 0) {
      setobj({
        ...obj,
        projectID: val,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
      });
    } else {
      setobj({
        ...obj,
        projectID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
        plantID: null,
      });
      setListUnit(null);
    }
  };
  const onChangePlant = (val) => {
    setDisciplineName("");
    setPhaseName("");
    setDocTypeTechnicalName("");
    setTableData(null);
    setFillingName("");
    setDocTypeName("");
    if (val > 0) {
      setobj({
        ...obj,
        plantID: val,
        projectID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
      });
    } else {
      setobj({
        ...obj,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
        projectID: null,
      });

      setListUnit(null);
    }
  };

  const LoadTechnicalParts = async () => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(0, "TechnicalDocuments");
      setListDiscipline(response1.data.data);

      const response3 = await Api_PhaseListParent();
      setListPhase(response3.data.data);
      setLoading(false);

      const response2 = await Api_GetFilling_D_Dashboard(objRef.current);
      setListFilling(response2.data.data);
    } catch (error) {}
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onRenderItem = (item) => {
    return (
      <div className="treeview-item-example">
        <span onClick={() => onSelectItemUnit(item)} className="treeview-item-example-text">
          {item.text}
        </span>
        {item.code != null ? <span className="actionButton trash">({item.code})</span> : null}
      </div>
    );
  };
  const onSelectItemUnit = async (item) => {
    try {
      if (item.isLeaf == "true") {
        const response = await Api_ProjectUnitsCode(item.id);

        setobjSystemCode({
          ...objSystemCode,
          unitcode: response.data.data.code,
        });

        setUnitName(item.text);
        setobj({
          ...obj,
          unitID: item.id,
        });
        setmodalUnit(false);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDocType = async (item) => {
    try {
      setTableData(null);
      setobj({
        ...obj,
        docTypeID: item.id,
        fillingID: null,
        disciplineID: 1,
        phaseID: 2,
        technicalDocTypeID: null,
      });

      setListFilling(null);
      setDocTypeName(item.text);
      setFillingName("");
      setDisciplineName("");
      setPhaseName("");
      setDocTypeTechnicalName("");
      setListDiscipline(null);
      setmodalDocType(false);
      setListDocTypeTechnical(null);
      GetDocTypeCode(item.id);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemFilling = async (item) => {
    try {
      setDisciplineName("");
      setobj({
        ...obj,
        fillingID: item.id,
      });

      const response = await Api_FillingGetPath(item.id, obj.companyID);

      setFillingName(response.data.message);
      setmodalFilling(false);
      GetFillingCode(item.id);

      if (objSystemCode.doctypeCode.toUpperCase() == "V") {
        LoadDiscipline(item.id);
      } else {
        setListDiscipline(null);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDocTypeTechnical = async (item) => {
    try {
      const response = await Api_DocTypeCode(item.id);
      setobjSystemCode({
        ...objSystemCode,
        docTypeTechnicalcode: response.data.data.code,
      });
      setobj({
        ...obj,
        technicalDocTypeID: item.id,
      });
      setDocTypeTechnicalName(item.text);
      setmodalDocTypeTechnical(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemPhase = async (item) => {
    try {
      const response = await Api_PhaseCode(item.id);
      // console.log("response:" + JSON.stringify(response));
      setobjSystemCode({
        ...objSystemCode,
        phasecode: response.data.data.code,
      });

      setobj({
        ...obj,
        phaseID: item.id,
      });
      setPhaseName(item.text);
      setmodalPhase(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDiscipline = async (item) => {
    try {
      const response = await Api_DisciplineCode(item.id);

      setobjSystemCode({
        ...objSystemCode,
        disciplinecode: response.data.data.code,
      });
      setobj({
        ...obj,
        disciplineID: item.id,
      });
      setDisciplineName(item.text);
      setmodalDiscipline(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const Search = (async) => {};
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Dashboard" title="Dashboard" Loading={Loading} />
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="3">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                      <Input
                        type="select"
                        name="companyID"
                        className="form-control digits"
                        value={obj.companyID}
                        disabled={documentID ? true : false}
                        onChange={(txt) => {
                          onChangeCompany(txt.target.value);
                        }}
                      >
                        {ListCompany != null
                          ? ListCompany.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })
                          : null}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Project"}</Label>
                      <Input
                        type="select"
                        name="projectID"
                        disabled={documentID ? true : false}
                        className="form-control digits"
                        value={obj.projectID != null ? obj.projectID : 0}
                        onChange={(txt) => {
                          onChangeProject(txt.target.value);
                        }}
                      >
                        <option value={0} key={0}>
                          Select...
                        </option>
                        {ListProject != null
                          ? ListProject.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.description}
                                </option>
                              );
                            })
                          : null}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Plant"}</Label>
                      <Input
                        type="select"
                        name="plantID"
                        disabled={documentID ? true : false}
                        className="form-control digits"
                        value={obj.plantID != null ? obj.plantID : 0}
                        onChange={(txt) => {
                          onChangePlant(txt.target.value);
                        }}
                      >
                        <option value={0} key={0}>
                          Select...
                        </option>
                        {ListPlant != null
                          ? ListPlant.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.description}
                                </option>
                              );
                            })
                          : null}
                      </Input>
                    </FormGroup>
                  </Col>
                  {obj.projectID != null || obj.plantID != null ? (
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Unit"}</Label>

                        {ListUnit != null && ListUnit != "" ? (
                          <Input
                            className="form-control"
                            value={UnitName}
                            onClick={() => {
                              setmodalUnit(true);
                            }}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            className="form-control"
                            value={""}
                            onClick={() => {
                              toast.info("List Is Empty");
                            }}
                            readOnly={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Doc.Type"}</Label>
                      <label className="txt-danger Required">{"*"}</label>
                      {ListDocType != null && ListDocType != "" ? (
                        <Input
                          className="form-control"
                          disabled={documentID ? true : false}
                          value={DocTypeName}
                          onClick={() => {
                            setmodalDocType(true);
                          }}
                          readOnly={true}
                        />
                      ) : (
                        <Input
                          className="form-control"
                          value={""}
                          onClick={() => {
                            toast.info("List Is Empty");
                          }}
                          readOnly={true}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  {ShowFilling == true ? (
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Filling"}</Label>
                        <label className="txt-danger Required">{"*"}</label>
                        {ListFilling != null && ListFilling != "" ? (
                          <Input
                            className="form-control"
                            disabled={documentID ? true : false}
                            value={FillingName}
                            onClick={() => {
                              setmodalFilling(true);
                            }}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            className="form-control"
                            value={""}
                            onClick={() => {
                              toast.info("List Is Empty");
                            }}
                            readOnly={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  ) : null}
                  {(ListPhase != null && ListPhase != "" && ShowFilling == false) || (ShowFilling == false && PhaseName != "") ? (
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Phase"}</Label>

                        <Input
                          className="form-control"
                          disabled={documentID ? true : false}
                          value={PhaseName}
                          onClick={() => {
                            setmodalPhase(true);
                          }}
                          readOnly={true}
                        />
                      </FormGroup>
                    </Col>
                  ) : null}

                  {(ListDiscipline != null && ListDiscipline != "") || (ShowFilling == false && DisciplineName != "") ? (
                    <Col sm={objSystemCode.doctypeCode.toUpperCase() == "V" ? 12 : 2}>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Discipline"}</Label>

                        <Input
                          className="form-control"
                          disabled={documentID ? true : false}
                          value={DisciplineName}
                          onClick={() => {
                            setmodalDiscipline(true);
                          }}
                          readOnly={true}
                        />
                      </FormGroup>
                    </Col>
                  ) : null}
                  {(ListDocTypeTechnical != null && ListDocTypeTechnical != "") || (ShowFilling == false && DocTypeTechnicalName != "") ? (
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Doc.Type"}</Label>

                        <Input
                          className="form-control"
                          disabled={documentID ? true : false}
                          value={DocTypeTechnicalName}
                          onClick={() => {
                            setmodalDocTypeTechnical(true);
                          }}
                          readOnly={true}
                        />
                      </FormGroup>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup>
                      <Label>Date From</Label>
                      <DatePicker
                        className="datepicker-here form-control"
                        dateFormat="MMMM d, yyyy"
                        selected={startDate}
                        onChange={handleStartDate}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <Label>To</Label>
                      <DatePicker className="datepicker-here form-control" dateFormat="MMMM d, yyyy" selected={endDate} onChange={handleEndDate} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th>Sub Title</th>
                      <th>Quantity</th>
                      <th>%</th>
                      <th>Diagram</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListFilling != "" && ListFilling != null
                      ? ListFilling.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.text}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modalDocType}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemDocType}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocType}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDocType(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFilling}>
        <ModalHeader>
          Doc.Type: {DocTypeName}+<br></br>Filling:{FillingName}
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemFilling}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListFilling}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFilling(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDiscipline}>
        <ModalHeader>Discipline</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDiscipline}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDiscipline}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => {
              setmodalDiscipline(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalPhase}>
        <ModalHeader>Phase</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemPhase}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListPhase}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => {
              setmodalPhase(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDocTypeTechnical}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDocTypeTechnical}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocTypeTechnical}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => {
              setmodalDocTypeTechnical(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalUnit}>
        <ModalHeader>Unit</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  selectRow={true}
                  onRenderItem={onRenderItem}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListUnit}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {obj.unitID != null && obj.unitID != 0 ? (
            <Button
              color="danger"
              onClick={() => {
                setobj({
                  ...obj,
                  unitID: null,
                });
                setmodalUnit(false);

                setUnitName("");
              }}
            >
              Unselect Unit
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodalUnit(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Dashboard;
