const URL = "https://api2.planetzamina.com/api";
//const URL = "https://apidemo.filmofan.ir/api";
//const URL = "https://localhost:44339/api";

const Version = "/v1";
const IsGust = "0";
export { URL, Version, IsGust };

// if (response.status && response.status == 200 && response.data.isSuccess == true) {
// } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
//   toast.error("You do not have access permission");
// } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
//   toast.error("Your desired information was not found");
//   //navigate(`${process.env.PUBLIC_URL}/admin/Advice`);
// } else if (response.response && response.response.data.isSuccess == false) {
//   toast.error(response.response.data.message);
// } else if (response.data && response.data.isSuccess == false) {
//   toast.error(response.data.message);
// } else {
//   toast.error("Error - 1");
// }
