import ErrorPage1 from "../Component/Pages/ErrorPages/error-page1";
import ErrorPage2 from "../Component/Pages/ErrorPages/error-page2";
import ErrorPage3 from "../Component/Pages/ErrorPages/error-page3";
import ErrorPage4 from "../Component/Pages/ErrorPages/error-page4";

import Logins from "../Auth/Signin";

export const authRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/pages/authentication/login`,
    Component: <Logins />,
  },

  //Error Page
  {
    path: `${process.env.PUBLIC_URL}/pages/error/error-page1`,
    Component: <ErrorPage1 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/error/error-page2`,
    Component: <ErrorPage2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/error/error-page3`,
    Component: <ErrorPage3 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/error/error-page4`,
    Component: <ErrorPage4 />,
  },
];
