import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_PhaseList, Api_GetPhase, Api_EditPhase, Api_AddPhase, Api_DeletePhase, Api_PhaseListParent } from "../../../api/Admin/Phase";
import { Btn, H6, Image, P, Spinner, H5 } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MaterialReactTable from "material-react-table";
import DeniReactTreeView from "deni-react-treeview";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const ListPhase = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [ParentCategoryId, setParentCategoryId] = useState(0);
  const [modalparent, setmodalparent] = useState(false);
  const [parentName, setparentName] = useState("");
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListParent, setListParent] = useState(null);
  const [modalupload, setmodalupload] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
    parentCategoryId: null,
    sort: 0,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadList();
  }, []);

  const themes = ["classic"];
  const LoadList = async () => {
    try {
      setLoading(true);
      const response = await Api_PhaseList();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          CreateParentList(response.data.data);
          setsumRows(response.data.data.length);
          const response2 = await Api_PhaseListParent();
          setListParent(response2.data.data);

          setLoading(false);
        } else {
          setTableData(null);
          CreateParentList(null);
          setLoading(false);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddPhase(obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("A new Phase has been added");
          LoadList(obj.companyID);
          setSelectedRowID(0);
          setobj({
            ...obj,
            description: "",
            code: "",
            sort: 0,
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_EditPhase(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadList();
          setParentCategoryId(0);
          setparentName("");
          setobj({
            ...obj,
            description: "",
            code: "",
            sort: 0,
            id: 0,
            parentCategoryId: null,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const CreateParentList = async (data) => {
    if (data != null) {
      const updatedContact = data.filter((c) => c.parentCategoryId == null);
      setListParent(updatedContact);
    } else {
      setListParent(null);
    }
  };

  const DeleteDepartment = async (id) => {
    try {
      setLoadingBtn(true);
      const response = await Api_DeletePhase(id);
      setModal(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Phase Is Removed");
        LoadList();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      toast.error("Error - 0");
      setLoadingBtn(false);
    } catch (error) {
      toast.error("Error - 0");
      setLoadingBtn(false);
      setModal(false);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetPhase(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          parentCategoryId: response.data.data.parentCategoryId,
          id: response.data.data.id,
          sort: response.data.data.sort,
        });
        if (response.data.data.parentCategoryId == null) {
          setParentCategoryId(0);
          setparentName("");
        } else {
          setParentCategoryId(response.data.data.parentCategoryId);
          setparentName(response.data.data.parentCategoryDescription);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      parentCategoryId: null,
      id: 0,
      sort: 0,
    });
    setSelectedRowID(0);
    setParentCategoryId(0);
    setparentName("");
  };

  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Description: item.Description,
      Code: item.Code,
    }));
    exportToExcel(customHeadings, "Phase");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.Description, item.Code]);
    autoTable(doc, {
      head: [["Row Number", "Description", "Code"]],
      body: customRows,
    });
    doc.text("Phase", 14, 10);
    doc.save("Phase.pdf");
  };
  const columns = useMemo(
    () => [
      {
        header: "Description",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.ParentCategoryId == null ? (
              <span style={{ fontWeight: "bold" }}>{cell.row.original.Description}</span>
            ) : cell.row.original.ParentCategoryId != null ? (
              <span style={{ color: "#16449D", marginLeft: 15, display: "block" }}>{cell.row.original.Description}</span>
            ) : null}
          </>
        ),
      },
      {
        header: "Code",
        accessorKey: "Code",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.ParentCategoryId == null ? (
              <span style={{ fontWeight: "bold" }}>{cell.row.original.Code}</span>
            ) : cell.row.original.ParentCategoryId != null ? (
              <span style={{ color: "#16449D", marginLeft: 15, display: "block" }}>{cell.row.original.Code}</span>
            ) : null}
          </>
        ),
      },
      {
        accessorKey: "Sort",
        header: "Sort",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const onSelectItem = async (item) => {
    try {
      setparentName(item.text);
      setParentCategoryId(item.id);
      setobj({
        ...obj,
        parentCategoryId: item.id,
      });
      setmodalparent(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onRemoveParent = async () => {
    setparentName("");
    setParentCategoryId(0);
    setobj({
      ...obj,
      parentCategoryId: null,
    });
    setmodalparent(false);
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Phase" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="description" className="form-control" value={obj.description || ""} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Parent"}</Label>
                        {ListParent != null ? (
                          <Input
                            className="form-control"
                            value={parentName}
                            onClick={() => {
                              setmodalparent(true);
                            }}
                            readOnly={true}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="code" className="form-control" value={obj.code || ""} onChange={onInputChange} required autoComplete="off" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input
                            name="sort"
                            className="form-control"
                            inputMode="numeric"
                            value={obj.sort}
                            onChange={onInputChange}
                            required
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.Id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteDepartment(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalparent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListParent}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {ParentCategoryId > 0 ? (
            <Button color="danger" onClick={() => onRemoveParent()}>
              Remove Parent
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodalparent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListPhase;
