import axios from "axios";
import { URL, Version } from "../URL";

const Api_Add = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/BidAucation`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_WatingList = async (companyID, projectID, plantID, BAmod, Kind) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${URL}${Version}/BidAucation/WatingList/${companyID}/${projectID}/${plantID}/${BAmod}/${Kind}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ConfrimDocument = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}${Version}/BidAucation/ConfrimDocument`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_NonApprovedList = async (
  companyID,
  projectID,
  plantID,
  BAmod,
  Kind
) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${URL}${Version}/BidAucation/NonApprovedList/${companyID}/${projectID}/${plantID}/${BAmod}/${Kind}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeletedList = async (companyID, projectID, plantID, BAmod, Kind) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${URL}${Version}/BidAucation/DeletedList/${companyID}/${projectID}/${plantID}/${BAmod}/${Kind}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Get = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BidAucation/Get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Edit = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}${Version}/BidAucation?id=${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Search = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${URL}${Version}/BidAucation/Search/`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_Add,
  Api_WatingList,
  Api_ConfrimDocument,
  Api_NonApprovedList,
  Api_DeletedList,
  Api_Get,
  Api_Edit,
  Api_Search,
};
