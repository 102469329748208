import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn } from "../../../AbstractElements";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import { Api_BlogCategoryList } from "../../../api/Admin/BlogCategory";
import { Api_BlogList, Api_DeleteBlog } from "../../../api/Admin/Blog";
import { Api_GetBlogCategory_Parent } from "../../../api/Admin/BlogCategory";
import DeniReactTreeView from "deni-react-treeview";
const LegendList = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [ListTitle, setListTitle] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [ParentCategoryId, setParentCategoryId] = useState(0);
  const [modalparent, setmodalparent] = useState(false);
  const [parentName, setparentName] = useState("");
  const [obj, setobj, objRef] = useState({
    id: 0,
    categoryID: 0,
  });
  useEffect(() => {
    LoadTitle();
  }, []);
  const LoadTitle = async () => {
    try {
      setLoading(true);
      const response = await Api_GetBlogCategory_Parent();
      setListTitle(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const themes = ["classic"];
  const LoadData = async (btnClick) => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      const response = await Api_BlogList(newobj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          if (btnClick == true) {
            toast.warning("No results found");
          }
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteBlog(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Advice Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const toggle = () => setModal(!modal);

  const onSelectItem = async (item) => {
    try {
      setparentName(item.text);
      setParentCategoryId(item.id);
      setobj({
        ...obj,
        categoryID: item.id,
      });
      setmodalparent(false);
      LoadData(true);
    } catch (error) {}
  };
  const showMore = async (id) => {
    try {
      const AllData = [...tableData];
      const DataIndex = AllData.findIndex((p) => p.id == id);
      const TempData = AllData[DataIndex];
      TempData.show = !TempData.show;
      AllData[DataIndex] = TempData;
      setTableData(AllData);
      console.log("AllData:" + JSON.stringify(AllData));
    } catch (err) {
      toast.error("Error");
    }
  };

  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Legend" Loading={Loading} />
          <Row>
            <Col md="12" className="project-list">
              <Card>
                <Row>
                  <Col md="12">
                    <Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/Admin/Legend/Add`}>
                      New Legend
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                        {ListTitle != null ? (
                          <Input
                            className="form-control"
                            value={parentName}
                            onClick={() => {
                              setmodalparent(true);
                            }}
                            readOnly={true}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <div className="activity-media">
              {tableData.map((item) => {
                return (
                  <Row key={item.id}>
                    <Col sm="12">
                      <div className="blog-single">
                        <div className="blog-box blog-details">
                          <Card>
                            <CardBody>
                              <div className="blog-details">
                                <UL
                                  attrUL={{
                                    className: "blog-social plan flex-row simple-list",
                                  }}
                                >
                                  <LI>Category : {item.blogCategoryTitle}</LI>
                                </UL>
                                <H4
                                  attrH4={{
                                    className: "plan-Title",
                                  }}
                                >
                                  <span>Title :</span> <span>{item.title}</span>
                                </H4>
                                {item.show == true ? (
                                  <>
                                    <H4
                                      attrH4={{
                                        className: "plan-Title",
                                      }}
                                    >
                                      <span> Text :</span>
                                    </H4>

                                    <div className="box-readmore" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                    <div onClick={() => showMore(item.id)} className="ShowMore">
                                      <i className="fa fa-angle-up"></i>
                                    </div>
                                  </>
                                ) : (
                                  <div onClick={() => showMore(item.id)} className="ShowMore">
                                    <i className="fa fa-angle-down"></i>
                                  </div>
                                )}
                                <div style={{ marginTop: 20, textAlign: "right" }}>
                                  <>
                                    <span>
                                      <Button
                                        className="btn btn-info mr-5 btn-xs"
                                        onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Legend/Edit/${item.id}`)}
                                      >
                                        Edit
                                      </Button>
                                    </span>
                                    <span>
                                      <Button
                                        className="btn btn-danger btn-xs"
                                        onClick={() => {
                                          setSelectedRowID(item.id);
                                          toggle();
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </span>
                                  </>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalparent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListTitle}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalparent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default LegendList;
