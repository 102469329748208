import axios from "axios";
import { URL, Version } from "../URL";

const Api_AdviceQuestionTitleCategoryList = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/AdviceQuestionTitleCategory/GetAll?dto=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetAdviceQuestionTitleCategory = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/AdviceQuestionTitleCategory/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditAdviceQuestionTitleCategory = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/AdviceQuestionTitleCategory?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddAdviceQuestionTitleCategory = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/AdviceQuestionTitleCategory`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteAdviceQuestionTitleCategory = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/AdviceQuestionTitleCategory/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_AdviceQuestionTitleCategoryList,
  Api_GetAdviceQuestionTitleCategory,
  Api_EditAdviceQuestionTitleCategory,
  Api_AddAdviceQuestionTitleCategory,
  Api_DeleteAdviceQuestionTitleCategory,
};
