import React, { Fragment, useContext, useState, useMemo, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Card, Row, Media, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { H6, P, Image, H5, UL, LI, Btn } from "../../../AbstractElements";
import { Api_GetMessage, Api_setAction, Api_MoveToFloder } from "../../../api/Admin/Messenger";
import { Api_GetMessageFiles, Api_GetViewDoucments } from "../../../api/Admin/Document";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import { Api_MessengerFolderList } from "../../../api/Admin/MessengerFolder";
import MemberTypeContext from "../../../_helper/MemberType/index";
const Message = () => {
  const navigate = useNavigate();
  const { setnoti, noti } = useContext(MemberTypeContext);
  const { Mod, ID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [modalFolder, setmodalFolder] = useState(false);
  const [folderList, setfolderList] = useState(null);
  const [fileList, setfileList] = useState([]);
  const [obj, setObj] = useState([]);
  useEffect(() => {
    LoadData();
  }, []);
  const LoadFolder = async () => {
    try {
      const response = await Api_MessengerFolderList();

      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setfolderList(response.data.data);
          setmodalFolder(true);
        } else {
          setfolderList(null);
          toast.warn("You have not created a folder yet");
        }
      }
    } catch (error) {}
  };
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_GetMessage(ID, Mod);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setObj(response.data.data);
        setLoading(false);
        ViewFile();
        setnoti("");
        setnoti({
          ...noti,
          notiMessage: ID,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };
  const ViewFile = async () => {
    try {
      setLoading(true);
      const response = await Api_GetMessageFiles(ID);
      setfileList(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const setAction = async (act) => {
    try {
      setLoading(true);
      const response = await Api_setAction(Mod, act, ID);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        LoadData();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {}
  };
  const MoveToFolder = async (folderid) => {
    try {
      setLoading(true);
      const response = await Api_MoveToFloder(Mod, folderid, ID);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setmodalFolder(false);
        toast.success("Moved To Folder");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {}
  };

  const DownloadFile = async (id, documentType, FileName) => {
    const response = await Api_GetViewDoucments(id, documentType);
    console.log("response DownloadFile: " + JSON.stringify(response));
    if (response.status == "200") {
      fileDownload(response.data, FileName);
      console.log("a");
    } else {
      toast.error("Error");
      console.log("b");
    }
  };
  return (
    <Fragment>
      <div className="email-right-aside">
        <Card className="email-body">
          <div className="email-profile">
            <div className="email-right-aside">
              <div className="email-body">
                <div className="email-content">
                  <div className="email-top">
                    <Row>
                      <Col xl="8">
                        <Media>
                          <Image
                            attrImage={{
                              className: "me-3 img-40 rounded-circle",
                              src: obj.imageName,
                              alt: "",
                            }}
                          />
                          <Media body>
                            <H6 attrH6={{ className: "d-block" }}>{obj.sender}</H6>
                            <P>
                              <i className="fa fa-calendar me-2"></i>
                              {obj.dateAdd}
                            </P>
                            <P>
                              <i className="fa fa-info-circle me-2"></i>
                              {obj.status}
                            </P>
                            {obj.receiversList != null ? (
                              <>
                                <div className="box-receivers">
                                  <i className="fa fa-users"></i>
                                  <ul className="list-receivers">
                                    {obj.receiversList.map((item) => {
                                      return <li key={item.id}>{item.label}</li>;
                                    })}
                                  </ul>
                                </div>
                              </>
                            ) : null}
                          </Media>
                        </Media>
                      </Col>
                      <Col xl="4">
                        <Row>
                          <Col sm="6">
                            <div className="text-right">
                              <button
                                className="btn-sm"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                  setAction("delete");
                                }}
                              >
                                <i className="icon-trash"></i>
                              </button>
                              {obj.status != "Archive" ? (
                                <button
                                  className="btn-sm"
                                  title="Archive"
                                  type="button"
                                  onClick={() => {
                                    setAction("archive");
                                  }}
                                >
                                  <i className="icon-archive"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn-sm"
                                  title="Restore"
                                  type="button"
                                  onClick={() => {
                                    setAction("archive");
                                  }}
                                >
                                  <i className="icon-archive"></i>
                                </button>
                              )}
                              <button
                                className="btn-sm"
                                title="Move To"
                                onClick={() => {
                                  LoadFolder();
                                }}
                              >
                                <i className="fa fa-toggle-up"></i>
                              </button>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="text-right">
                              {Mod == "i" ? (
                                <button
                                  className="btn-sm"
                                  type="button"
                                  title="reply"
                                  onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/admin/Message/Reply/${ID}`);
                                  }}
                                >
                                  <i className="fa fa-mail-reply"></i>
                                </button>
                              ) : null}

                              <button
                                className="btn-sm"
                                title="forward"
                                type="button"
                                onClick={() => {
                                  navigate(`${process.env.PUBLIC_URL}/admin/Message/Forward/${ID}`);
                                }}
                              >
                                <i className="fa fa-mail-forward"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="email-wrapper">
                    <div className="emailread-group">
                      <div className="read-group">
                        <H5>{obj.subject}</H5>

                        <div dangerouslySetInnerHTML={{ __html: obj.text }}></div>
                      </div>
                    </div>
                    {fileList != null && fileList != "" ? (
                      <div className="emailread-group">
                        <H6 attrH6={{ className: "text-muted mb-0" }}>
                          <i className="icofont icofont-clip"></i> ATTACHMENTS
                        </H6>

                        <div className="clearfix"></div>
                        <div className="attachment">
                          <UL attrUL={{ className: "simple-list flex-row" }}>
                            {fileList != null
                              ? fileList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <button
                                        className="btn btn-outline-light btn-sm"
                                        onClick={() => {
                                          DownloadFile(item.id, item.documentType, item.fileName);
                                        }}
                                      >
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </button>
                                    </li>
                                  );
                                })
                              : null}
                          </UL>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Modal isOpen={modalFolder}>
        <ModalHeader>Move To Folder</ModalHeader>
        <ModalBody>
          {folderList != null ? (
            <UL>
              {folderList.map((item) => {
                return (
                  <LI
                    key={item.id}
                    attrBtn={{
                      color: "light",
                      type: "button",
                      key: item.id,
                    }}
                    attrLI={{ onClick: () => MoveToFolder(item.id), className: "list-group-item-action", tag: "a", href: "#javascript" }}
                  >
                    {item.title}
                  </LI>
                );
              })}
            </UL>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Btn
            attrBtn={{
              color: "light",
              type: "button",
              onClick: () => setmodalFolder(false),
            }}
          >
            Cancel
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default Message;
