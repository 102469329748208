import { ListMembers, CreateMember, ChangePassword } from "../Component/admin/Members";
import { ListCompany, EditCompany, AddCompany } from "../Component/admin/Company";
import { DocumentList, Document, WaitingList, DocSearch, NonApprovedList, Dashboard, DeletedList } from "../Component/admin/Document";
import { ListDepartment } from "../Component/admin/Department";
import { ListContracts } from "../Component/admin/ContractsType";
import { ListPlants } from "../Component/admin/Plants";
import { ListSections } from "../Component/admin/Sections";
import { ListJobPosition } from "../Component/admin/JobPosition";
import { UnitCategory, ListProjects, ProjectUnits, FillingMainStructure, PlantUnits } from "../Component/admin/Projects";
import { RoleList } from "../Component/admin/Role";
import { NatioanlList, ProducerCompany, VendorsCompanies } from "../Component/admin/Natioanl";
import { ListDiscipline, ListDocType, ListPhase, RecieverSender } from "../Component/admin/TechnicalDocuments";
import { DisciplineContractors } from "../Component/admin/ContractorsVendors";
import { Notyetactivated } from "../Component/admin/Notyetactivated";
import { StaticTex } from "../Component/admin/StaticTex";
import { BulletinBoardList, BulletinBoardAdd } from "../Component/admin/BulletinBoard";
import { DailyPlansAdd, DailyPlansList, DailyPlansListTable } from "../Component/admin/DailyPlans";
import { MeetingPlansList, MeetingPlansAdd, MeetingPlansListTable } from "../Component/admin/MeetingPlans";
import { TelBookAdd, CompanyTelBook, PrivateTelBook, Interacting } from "../Component/admin/TelBook";
import { AdviceQuestionTitle } from "../Component/admin/AdviceQuestion";
import { AdviceAdd, Advice, AdviceResponse } from "../Component/admin/AdviceQuestion";
import { QuestionAdd, Question, QuestionResponse } from "../Component/admin/AdviceQuestion";
import { NewMessage, MessageList, MessageInfo } from "../Component/admin/Messenger";
import { LegendCategory, LegendList, LegendAdd, Legend } from "../Component/admin/Legend";
import { BAWaitingList, BANonApprovedList, BidAucation, BADeletedList, BidAucationReport } from "../Component/admin/BidAucation";
import { ContractWaitingList, ContractNonApprovedList, Contract, ContractDeletedList, ContractReport } from "../Component/admin/Contracts";
import { DataTypeAccess } from "../Component/admin/Module";
import QuickAccess from "../Component/admin/QuickAccess/QuickAccess";
export const AdminRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/admin/QuickAccess`,
    Component: <QuickAccess />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ChangePassword`,
    Component: <ChangePassword />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/dashboard`,
    Component: <Dashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document`,
    Component: <DocSearch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/List`,
    Component: <DocumentList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/add`,
    Component: <Document />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/edit/:documentID`,
    Component: <Document />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/WaitingList/:ParamCompanyID`,
    Component: <WaitingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/WaitingList/`,
    Component: <WaitingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/NonApprovedList/`,
    Component: <NonApprovedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/NonApprovedList/:ParamCompanyID`,
    Component: <NonApprovedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/DeletedList/`,
    Component: <DeletedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Document/DeletedList/:ParamCompanyID`,
    Component: <DeletedList />,
  },
  { path: `${process.env.PUBLIC_URL}/admin/User`, Component: <ListMembers /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/User/:companyID`,
    Component: <ListMembers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/User/add`,
    Component: <CreateMember />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/User/edit/:userID`,
    Component: <CreateMember />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/AccessLevels`,
    Component: <RoleList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Nationality`,
    Component: <NatioanlList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/InteractingCompanies`,
    Component: <ProducerCompany />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/VendorsCompanies`,
    Component: <VendorsCompanies />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Company`,
    Component: <ListCompany />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Company/add`,
    Component: <AddCompany />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Company/edit/:CompanyID`,
    Component: <EditCompany />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Department`,
    Component: <ListDepartment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/ContractsType`,
    Component: <ListContracts />,
  },

  { path: `${process.env.PUBLIC_URL}/admin/Plants`, Component: <ListPlants /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/Plants/:CompanyID`,
    Component: <ListPlants />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Sections`,
    Component: <ListSections />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/JobPosition`,
    Component: <ListJobPosition />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectUnitCategory`,
    Component: <UnitCategory />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Projects`,
    Component: <ListProjects />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Projects/:CompanyID`,
    Component: <ListProjects />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectUnits/:ProjectID`,
    Component: <ProjectUnits />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/PlantUnits/:PlantID`,
    Component: <PlantUnits />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/FilingStructure/`,
    Component: <FillingMainStructure />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/DocType/`,
    Component: <ListDocType />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/Discipline/`,
    Component: <ListDiscipline />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/Phase/`,
    Component: <ListPhase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ContractorsVendors/Discipline/`,
    Component: <DisciplineContractors />,
  },
  // { path: `${process.env.PUBLIC_URL}/admin/ContractorsVendors/List`, Component: <ListDisciplineContractors /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/Notyetactivated/`,
    Component: <Notyetactivated />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Correspondences/RecieverSender/`,
    Component: <RecieverSender />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/StaticTex`,
    Component: <StaticTex />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/BulletinBoard`,
    Component: <BulletinBoardList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BulletinBoard/Show/:bulletinID`,
    Component: <BulletinBoardList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BulletinBoard/Add`,
    Component: <BulletinBoardAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BulletinBoard/Add/:lang`,
    Component: <BulletinBoardAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BulletinBoard/Edit/:bulletinID`,
    Component: <BulletinBoardAdd />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/DailyPlans`,
    Component: <DailyPlansList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/DailyPlans/Show/:dailyPlanID`,
    Component: <DailyPlansList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/DailyPlansReport`,
    Component: <DailyPlansListTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/DailyPlans/Add`,
    Component: <DailyPlansAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/DailyPlans/Edit/:dailyPlanID`,
    Component: <DailyPlansAdd />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/MeetingPlans`,
    Component: <MeetingPlansList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MeetingPlans/Show/:meetingPlanID`,
    Component: <MeetingPlansList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MeetingPlansReport`,
    Component: <MeetingPlansListTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MeetingPlans/Add`,
    Component: <MeetingPlansAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MeetingPlans/Edit/:meetingPlanID`,
    Component: <MeetingPlansAdd />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/TelBook/CompanyTelBook`,
    Component: <CompanyTelBook />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TelBook/PrivateTelBook`,
    Component: <PrivateTelBook />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TelBook/Interacting`,
    Component: <Interacting />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TelBook/Add/:categoryID`,
    Component: <TelBookAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/TelBook/Edit/:categoryID/:telBookID`,
    Component: <TelBookAdd />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/AdviceQuestionTitle`,
    Component: <AdviceQuestionTitle />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Advices/Add/`,
    Component: <AdviceAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Advices/Edit/:adviceID`,
    Component: <AdviceAdd />,
  },
  { path: `${process.env.PUBLIC_URL}/admin/Advices/`, Component: <Advice /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/Advices/Show/:adviceID`,
    Component: <Advice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Advices/Responses/:adviceID`,
    Component: <AdviceResponse />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Questions/Add/`,
    Component: <QuestionAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Questions/Edit/:adviceID`,
    Component: <QuestionAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Questions/Show/:adviceID`,
    Component: <Question />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Questions/`,
    Component: <Question />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Questions/Responses/:adviceID`,
    Component: <QuestionResponse />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Message/New`,
    Component: <NewMessage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Message/Reply/:ReplyID`,
    Component: <NewMessage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Message/Forward/:ForwardID`,
    Component: <NewMessage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Messenger/:Mod`,
    Component: <MessageList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Messenger/:Mod/:FolderName`,
    Component: <MessageList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Message/:Mod/:ID`,
    Component: <MessageInfo />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/LegendCategory`,
    Component: <LegendCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/LegendList`,
    Component: <LegendList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Legend/Add`,
    Component: <LegendAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Legend/Edit/:LegendID`,
    Component: <LegendAdd />,
  },
  { path: `${process.env.PUBLIC_URL}/admin/Legend`, Component: <Legend /> },
  {
    path: `${process.env.PUBLIC_URL}/admin/DataTypeAccess`,
    Component: <DataTypeAccess />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction`,
    Component: <BidAucationReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction/Add`,
    Component: <BidAucation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction/Edit/:bidAuctionID`,
    Component: <BidAucation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction/NonApprovedList`,
    Component: <BANonApprovedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction/WaitingList`,
    Component: <BAWaitingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/BidAuction/DeletedList`,
    Component: <BADeletedList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts`,
    Component: <ContractReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts/Add`,
    Component: <Contract />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts/Edit/:contractsID`,
    Component: <Contract />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts/NonApprovedList`,
    Component: <ContractNonApprovedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts/WaitingList`,
    Component: <ContractWaitingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Contracts/DeletedList`,
    Component: <ContractDeletedList />,
  },
];
