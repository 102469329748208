import React, { useEffect, Fragment, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import useState from "react-usestateref";
import MaterialReactTable from "material-react-table";
import { DocumentAttach } from ".";
import { Api_DeleteBidAucationFile } from "../../../api/Admin/Document";
import { toast } from "react-toastify";
const RelatedList = ({ tableData, documentType, contractsID, ViewFileBA, obj }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalFile, setmodalFile] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [sumRows, setsumRows] = useState(0);
  const [modaldelete, setmodaldelete] = useState(false);

  const tableInstanceRef = useRef(null);
  const scollToRef = useRef();

  useEffect(() => {
    console.log("1 contractsID:" + contractsID);
    const fetchData = async () => {
      try {
        setsumRows(tableData.length);
        scollToRef.current.scrollIntoView();
      } catch (err) {}
    };

    fetchData();
  }, [tableData]);
  const [obj2, setobj2] = useState({
    id: 0,
    documentName: "",
    isDirect: false,
  });

  const DeleteFile = async (docid) => {
    console.log("docid:" + docid);
    console.log("contractsID:" + contractsID);
    if (obj2.isDirect == true) {
      const response = await Api_DeleteBidAucationFile(docid, contractsID, "Direct", documentType);

      if (response.status == "200") {
        toast.success("The file was deleted");

        ViewFileBA();
      } else {
        toast.error("Error");
      }
      setmodaldelete(false);
    } else {
      const response = await Api_DeleteBidAucationFile(docid, contractsID, "BA", documentType);

      if (response.status == "200") {
        toast.success("The file was deleted");

        ViewFileBA();
      } else {
        toast.error("Error");
      }
      setmodaldelete(false);
    }
  };
  const toggle = () => setModal(!modal);

  const columnsDocumentFile = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },
      {
        header: "System Code",
        accessorKey: "systemCode",

        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },
      {
        accessorKey: "userDocCode",
        header: "User Code",
      },

      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        header: "Doc. Date",
        Cell: ({ cell }) => (
          <>
            <span>{cell.row.original.dateAdd.toString().substring(0, 10)}</span>
          </>
        ),
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Files",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    documentName: cell.row.original.docTitle,
                    id: cell.row.original.id,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-danger mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    id: cell.row.original.id,
                    documentName: cell.row.original.docTitle,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodaldelete(true);
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const columnsTransmital = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },

      {
        header: "System Code",
        accessorKey: "systemCode",

        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },
      {
        accessorKey: "userDocCode",
        header: "User Code",
      },

      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Doc. Date",
        Cell: ({ cell }) => (
          <>
            <span>{cell.row.original.dateAdd.toString().substring(0, 10)}</span>
          </>
        ),
      },
      {
        header: "Files",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    documentName: cell.row.original.docTitle,
                    id: cell.row.original.id,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-danger mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    id: cell.row.original.id,
                    documentName: cell.row.original.docTitle,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodaldelete(true);
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const columnsLetter = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },
      {
        accessorKey: "customeType",
        header: "Type",
      },
      {
        header: "System Code",
        accessorKey: "systemCode",

        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },
      {
        accessorKey: "producerName",
        header: "Producer Name",
      },
      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        accessorKey: "userDocCode",
        header: "User Code",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Files",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    documentName: cell.row.original.docTitle,
                    id: cell.row.original.id,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-danger mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    id: cell.row.original.id,
                    documentName: cell.row.original.docTitle,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodaldelete(true);
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const columnsPaymnets = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },
      {
        accessorKey: "customeType",
        header: "Type",
      },
      {
        header: "System Code",
        accessorKey: "systemCode",

        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },

      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        accessorKey: "userDocCode",
        header: "User Code",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        header: "Files",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    documentName: cell.row.original.docTitle,
                    id: cell.row.original.id,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-danger mr-5 btn-xs"
                onClick={() => {
                  setobj2({
                    id: cell.row.original.id,
                    documentName: cell.row.original.docTitle,
                    isDirect: cell.row.original.isDirect,
                  });
                  setmodaldelete(true);
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12">
            <div className="lbl-sum-Table m-t-15">
              <Row>
                <Col md="6">
                  <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12">
            {tableData != "" && tableData != null ? (
              <Row>
                <Col sm="12">
                  <div ref={scollToRef}>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={
                        documentType == "DocumentFile"
                          ? columnsDocumentFile
                          : documentType == "Transmital"
                          ? columnsTransmital
                          : documentType == "Letter"
                          ? columnsLetter
                          : documentType == "Payments"
                          ? columnsPaymnets
                          : null
                      }
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      enableRowNumbers
                      enableSorting={true}
                      enableColumnDragging={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modalFile} size="lg">
        <ModalHeader>{obj2.documentName}</ModalHeader>
        <ModalBody>
          <DocumentAttach
            obj={obj}
            documentType={documentType}
            contractsID={contractsID}
            disabledbtn={false}
            documentID={obj2.id}
            isDirect={obj2.isDirect}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFile(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modaldelete}>
        <ModalBody>
          <Row>
            <Col sm="12">
              <p>Are you sure to delete {obj2.documentName}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteFile(obj2.id)}>
            Yes
          </Button>
          <Button
            color="light"
            onClick={() => {
              setmodaldelete(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default RelatedList;
