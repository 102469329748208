import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_AddPlants, Api_EditPlants, Api_PlantsList_Parent, Api_DeletePlants, Api_GetPlants } from "../../../api/Admin/Plants";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MaterialReactTable from "material-react-table";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import Dropzone from "react-dropzone-uploader";
import {  Api_GetHasPlantChart, Api_GetViewViewPlantChart } from "../../../api/Admin/Document";
const ListPlants = () => {
  const navigate = useNavigate();
  const { CompanyID } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [Loading, setLoading] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [FileID, setFileID] = useState("");
  const [modalupload, setmodalupload] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [fileext, setfileext] = useState("");
  const [FileName, setFileName] = useState("");
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
    companyID: DefaultCompanyID,
    remarks: "",
    position: "Active",
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    if (CompanyID) {
      onChangeCompany(CompanyID);
    } else {
      onChangeCompany(obj.companyID);
    }
    LoadCompanyList();
  }, []);

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const LoadPlantsList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList_Parent(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
      LoadPlantsList(val);
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
      setTableData(null);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddPlants(obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Plant has been added");
          LoadPlantsList(obj.companyID);
          setobj({
            ...obj,
            description: "",
            code: "",
            remarks: "",
            id: 0,
            position: "Active",
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoadingBtn(false);
      } else {
        const response = await Api_EditPlants(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadPlantsList(obj.companyID);
          setobj({
            ...obj,
            description: "",
            code: "",
            remarks: "",
            position: "Active",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoadingBtn(false);
      }
    } catch (error) {
      toast.error("Error - 0");
      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const DeletePlants = async (id) => {
    const response = await Api_DeletePlants(id);
    if (response.status && response.status == 200 && response.data.isSuccess == true) {
      toast.success("Plant Is Removed");
      ResetForm();
    } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
      toast.error("You do not have access permission");
    } else if (response.response && response.response.data.isSuccess == false) {
      toast.error(response.response.data.message);
    } else {
      toast.error("Error - 1");
    }
    setModal(false);
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetPlants(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          remarks: response.data.data.remarks,
          companyID: response.data.data.companyID,
          id: response.data.data.id,
          position: response.data.data.position,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      remarks: "",
      id: 0,
      position: "Active",
    });
    setSelectedRowID(0);
    LoadPlantsList(obj.companyID);
  };
  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Chart has been successfully uploaded");
        setmodalupload(false);
      } else {
        toast.error("Chart could not be loaded");
      }
    }
  };

  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");

    return {
      url: `${URL}${Version}/Document/UploadpPlantChart/${SelectedRowID}`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };

  const ViewFile = async (id) => {
    const response = await Api_GetHasPlantChart(id);

    if (response.status == "200" && response.data.statusCode == "Success") {
      setFileID(response.data.data.id);
      setfileext(response.data.data.fileExt);
    } else if (response.status == "200" && response.data.statusCode == "NotFound") {
      setFileID("");
      setfileext("");
      setFileName("");
    } else {
      setFileID("");
      setfileext("");
      setFileName("");
    }
  };

  const DownloadFile = async () => {
    const response = await Api_GetViewViewPlantChart(SelectedRowID);
    fileDownload(response.data, FileName + fileext);
    if (response.status == "200") {
    } else {
      setFileID("");
      setfileext("");
      setFileName("");
    }
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Description: item.Description,
      Code: item.Code,
      Remarks: item.Remarks,
      Position: item.position == 1 ? "Active" : "Inactive",
    }));
    exportToExcel(customHeadings, "Company Plant");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.Description, item.Code, item.Remarks, item.position == 1 ? "Active" : "Inactive"]);
    autoTable(doc, {
      head: [["Row Number", "Description", "CurreCodency", "Remarks", "Position"]],
      body: customRows,
    });
    doc.text("Company Plant", 14, 10);
    doc.save("Company Plant.pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "Description",
        header: "Description",
      },
      {
        accessorKey: "Code",
        header: "Code",
      },
      {
        header: "Sub Unit",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-light  btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/PlantUnits/${cell.row.id}`)}>
                S U
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Chart",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <i
            className="fa fa-file-image-o"
            onClick={() => {
              setFileName(cell.row.original.Description);
              setmodalupload(true);
              ViewFile(cell.row.id);
              setSelectedRowID(cell.row.id);
            }}
          ></i>
        ),
      },
      {
        accessorKey: "Remarks",
        header: "Remarks",
      },
      {
        accessorKey: "position",
        header: "Position",
        Cell: ({ cell }) =>
          cell.row.original.position == "Active" || cell.row.original.position == 1 ? (
            <span className="txt-success">Active</span>
          ) : (
            <span className="txt-danger">Inactive</span>
          ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Company Plant" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {obj.companyID != 0 ? (
                    <>
                      <Row>
                        <Col sm="10">
                          <FormGroup>
                            <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input
                                name="description"
                                autoComplete="off"
                                className="form-control"
                                value={obj.description}
                                onChange={onInputChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} required autoComplete="off" />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="10">
                          <FormGroup>
                            <Label>{"Remarks"}</Label>
                            <InputGroup>
                              <Input name="remarks" autoComplete="off" className="form-control" value={obj.remarks} onChange={onInputChange} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Position"}</Label>
                            <label className="txt-danger Required">{"*"}</label>
                            <Input type="select" name="position" className="form-control" value={obj.position} onChange={onInputChange}>
                              <option value="Active" key="Active">
                                Active
                              </option>
                              <option value="Inactive" key="Inactive">
                                Inactive
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          {SelectedRowID == 0 ? (
                            <Button type="submit" className="btn btn-success btn-md">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Add"
                              )}
                            </Button>
                          ) : (
                            <>
                              <Button type="submit" className="btn btn-success btn-md m-r-15">
                                {LoadingBtn ? (
                                  <div className="loader-box2">
                                    <Spinner attrSpinner={{ className: "loader-7" }} />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                              {LoadingBtn == false ? (
                                <Btn
                                  attrBtn={{
                                    color: "light",
                                    type: "button",
                                    onClick: () => ResetForm(),
                                  }}
                                >
                                  Cancel
                                </Btn>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null && tableData != "" ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.Id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeletePlants(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalupload}>
        {FileID != "" ? (
          <ModalHeader>
            <Row>
              <Col sm="12">
                <Button color="primary" onClick={DownloadFile}>
                  Download {FileName} Chart
                </Button>
              </Col>
            </Row>
          </ModalHeader>
        ) : null}

        <ModalBody>
          <Row>
            <Col sm="12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept=".jpg,.pdf,.jpeg"
                inputContent="Drop files here or click to upload."
                styles={{
                  dropzone: { width: "100%", height: 50 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setmodalupload(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListPlants;
