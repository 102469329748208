import React, { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import { DataTypeAccessList } from "../Module/DataTypeAccessList";
import { DocSearch, RelatedList, Risk, DirectAdd } from ".";
import useState from "react-usestateref";
import { Api_GetContractsFiles, Api_GetViewDoucments, Api_DeleteBidAucationFile } from "../../../api/Admin/Document";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroupText,
} from "reactstrap";
const BAAttach = ({ bidAuctionID, disabledbtn, obj }) => {
  const [modalupload, setmodalupload] = useState(false);
  const [modalDirect, setmodalDirect] = useState(false);
  const [riskCount, setriskCount] = useState(0);
  const [DatesCount, setDatesCount] = useState(0);
  const [HeaderUploader, setHeaderUploader] = useState(false);
  const [DocumentFilesList, setDocumentFilesList] = useState(null);
  const [TransmitalFilesList, setTransmitalFilesList] = useState(null);
  const [LettersFilesList, setLettersFilesList] = useState(null);
  const [PaymentsFilesList, setPaymentsFilesList] = useState(null);
  const [documentType, setdocumentType] = useState("");
  const [BasicTab, setBasicTab] = useState("1");
  const HandleUpload = (header, _setdocumentType) => {
    setdocumentType(_setdocumentType);
    setHeaderUploader(header);
    setmodalupload(true);
  };
  const HandleDirect = (header, _setdocumentType) => {
    setdocumentType(_setdocumentType);
    setHeaderUploader(header);
    setmodalDirect(true);
  };

  const ViewFileBA = async () => {
    console.log("response");
    try {
      const response1 = await Api_GetContractsFiles("0", bidAuctionID, "DocumentFile");
      setDocumentFilesList(response1.data.data);

      const response2 = await Api_GetContractsFiles("0", bidAuctionID, "Transmital");
      setTransmitalFilesList(response2.data.data);

      const response3 = await Api_GetContractsFiles("0", bidAuctionID, "Letter");
      console.log("response3.data.data:" + response3);
      setLettersFilesList(response3.data.data);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  useEffect(() => {
    if (bidAuctionID != "") {
      ViewFileBA();
    } else {
    }
  }, [bidAuctionID]);

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Nav tabs className={"ulbi"}>
                <NavItem className={"li-ba"}>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "1" ? "active" : ""}
                    onClick={(event) => {
                      event.preventDefault();
                      setBasicTab("1");
                    }}
                  >
                    Document Files ({DocumentFilesList == null ? 0 : DocumentFilesList.length})
                  </NavLink>
                </NavItem>
                <NavItem className={"li-ba"}>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "2" ? "active" : ""}
                    onClick={(event) => {
                      event.preventDefault();
                      setBasicTab("2");
                    }}
                  >
                    Transmittals(
                    {TransmitalFilesList == null ? 0 : TransmitalFilesList.length})
                  </NavLink>
                </NavItem>
                <NavItem className={"li-ba"}>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "3" ? "active" : ""}
                    onClick={(event) => {
                      event.preventDefault();
                      setBasicTab("3");
                    }}
                  >
                    Letters(
                    {LettersFilesList == null ? 0 : LettersFilesList.length})
                  </NavLink>
                </NavItem>

                <NavItem className={"li-ba"}>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "4" ? "active" : ""}
                    onClick={(event) => {
                      event.preventDefault();
                      setBasicTab("4");
                    }}
                  >
                    Risks(
                    {riskCount})
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className="fade show" tabId="1">
                  <Row>
                    {obj.confrim != "Yes" ? (
                      <Col sm="12">
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            DocumentFilesList != null && DocumentFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleUpload("Document File", "DocumentFile")}
                        >
                          Attcah
                        </Button>
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            DocumentFilesList != null && DocumentFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleDirect("Document File", "DocumentFile")}
                        >
                          Direct
                        </Button>
                      </Col>
                    ) : null}
                    <Col sm="12">
                      <RelatedList
                        obj={obj}
                        tableData={DocumentFilesList}
                        ViewFileBA={ViewFileBA}
                        contractsID={bidAuctionID}
                        documentType={"DocumentFile"}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    {obj.confrim != "Yes" ? (
                      <Col sm="12">
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            TransmitalFilesList != null && TransmitalFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleUpload("Transmital", "Transmital")}
                        >
                          Attcah
                        </Button>
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            TransmitalFilesList != null && TransmitalFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleDirect("Transmital", "Transmital")}
                        >
                          Direct
                        </Button>
                      </Col>
                    ) : null}
                    <Col sm="12">
                      <RelatedList
                        obj={obj}
                        tableData={TransmitalFilesList}
                        ViewFileBA={ViewFileBA}
                        bidAuctionID={bidAuctionID}
                        documentType={"Transmital"}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    {obj.confrim != "Yes" ? (
                      <Col sm="12">
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            LettersFilesList != null && LettersFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleUpload("Letter", "Letter")}
                        >
                          Attcah
                        </Button>
                        <Button
                          disabled={disabledbtn == true ? true : false}
                          className={
                            DocumentFilesList != null && DocumentFilesList != ""
                              ? "btn btn-light btn-md m-r-15 m-t-15"
                              : "btn btn-danger btn-md m-r-15 m-t-15"
                          }
                          onClick={() => HandleDirect("Letter", "Letter")}
                        >
                          Direct
                        </Button>
                      </Col>
                    ) : null}
                    <Col sm="12">
                      <RelatedList
                        obj={obj}
                        tableData={LettersFilesList}
                        ViewFileBA={ViewFileBA}
                        bidAuctionID={bidAuctionID}
                        documentType={"Letter"}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Col sm="12 m-t-15">{bidAuctionID ? <Risk setriskCount={setriskCount} /> : null}</Col>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modalupload} size="lg">
        <ModalHeader>
          <Row>
            <Col sm="12">Attachment {HeaderUploader}</Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              <DocSearch bidAuctionID={bidAuctionID} ViewFileBA={ViewFileBA} documentType={documentType} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setmodalupload(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDirect} size="lg">
        <ModalHeader>
          <Row>
            <Col sm="12">Direct To {HeaderUploader}</Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              <DirectAdd bidAuctionID={bidAuctionID} ViewFileBA={ViewFileBA} documentType={documentType} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setmodalDirect(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default BAAttach;
