import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { DataTypeAccessList } from "../Module/DataTypeAccessList";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button } from "reactstrap";
import useState from "react-usestateref";
import { Api_AddMessage, Api_EditMessage, Api_GetMessage, Api_GetMessageSender } from "../../../api/Admin/Messenger";
import { Api_UserSearch } from "../../../api/Admin/User";
import AsyncSelect from "react-select/async";
import Dropzone from "react-dropzone-uploader";
import { URL, Version } from "../../../api/URL";
import { Api_GetMessageFiles, Api_DeleteFileMessage } from "../../../api/Admin/Document";
import { Editor } from "@tinymce/tinymce-react";
const NewMessage = () => {
  const navigate = useNavigate();
  const { ReplyID, ForwardID } = useParams();
  const [MessageID, setMessageID] = useState("");
  const [title, settitle] = useState("New Message");
  const [Loading, setLoading] = useState(false);
  const [modalFiles, setmodalFiles] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [UserList, setUserList, UserListRef] = useState([]);
  const [fileList, setfileList, fileListRef] = useState([]);
  const [DataTypeUploader, setDataTypeUploader] = useState([]);
  const [obj, setobj, objRef] = useState({
    subject: "",
    text: "",
    isAttach: false,
    messengerCategory: "Draft",
    usersID: [],
    parentID: "",
  });
  const [objsender, setobjsender] = useState({
    subject: "",
    sender: "",
    imageName: "",
  });
  const editorRef = useRef(null);
  useEffect(() => {
    if (ReplyID) {
      settitle("Reply Message");
      LoadData(ReplyID);
    } else if (ForwardID) {
      settitle("Forward Message");
      LoadData(ForwardID);
      ViewFile();
    }
    if (MessageID == "") {
      CreateMessage();
    }
    SetDataTypeAccess();
  }, [MessageID]);
  const CreateMessage = async () => {
    try {
      setLoading(true);
      const response = await Api_AddMessage(obj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setMessageID(response.data.message);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
        navigate(`${process.env.PUBLIC_URL}/admin`);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const SetDataTypeAccess = async () => {
    const _data = await DataTypeAccessList();
    setDataTypeUploader(_data);
  };
  const LoadData = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetMessageSender(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        // navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setobjsender(response.data.data);

      setLoading(false);
    } catch (error) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (MessageID != "") {
        if (fileList != "" && fileList != null) {
          setobj({
            ...obj,
            isAttach: true,
          });
        }
        if (ReplyID) {
          setobj({
            ...obj,
            messengerCategory: "Reply",
            parentID: ReplyID,
            text: editorRef.current.getContent(),
          });
        } else if (ForwardID) {
          setobj({
            ...obj,
            messengerCategory: "Forward",
            parentID: ForwardID,
            text: editorRef.current.getContent(),
          });
        } else {
          setobj({
            ...obj,
            messengerCategory: "Sent",
            text: editorRef.current.getContent(),
          });
        }
        const backupobj = objRef.current;
        console.log(JSON.stringify(objRef.current));
        const response = await Api_EditMessage(MessageID, backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Your message has been sent");
          navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Sent`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
          toast.error("The message was not sent");
          navigate(`${process.env.PUBLIC_URL}/admin/Messenger/New`);
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        toast.error("The message was not sent");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/New`);
      }
    } catch (error) {
      toast.error("Error - 0");
      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const loadOptions = async (inputValue) => {
    if (inputValue != "") {
      const response = await Api_UserSearch(inputValue);
      setUserList(response.data.data);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(filter(inputValue));
        }, 1000);
      });
    }
  };
  const filter = (inputValue) => UserListRef.current.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setobj({
      ...obj,
      usersID: selectedOption,
    });
  };
  const handleChangeStatus = async ({ meta, file, xhr, remove }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        //  toast.success("Document has been successfully uploaded");
        remove();
        ViewFile(MessageID);
      } else {
        toast.error("Document could not be loaded");
      }
    }
    setLoadingBtn(false);
    setLoading(false);
  };
  const getUploadParams = async ({ meta }) => {
    try {
      setLoadingBtn(true);
      setLoading(true);
      const token = await localStorage.getItem("token");
      let df = "Messenger";
      return {
        url: `${URL}${Version}/Document/UploadDocumentFile/${df}?docid=${MessageID}`,
        headers: { Authorization: `Bearer ${token}` },
      };
    } catch (error) {
      toast.error("The file could not be uploaded");
    }
  };
  const ViewFile = async (id) => {
    try {
      const response = await Api_GetMessageFiles(id);
      setfileList(response.data.data);
      if (response.data.data.length > 0) {
        setobj({
          ...obj,
          isAttach: true,
        });
      } else {
        setobj({
          ...obj,
          isAttach: false,
        });
      }
    } catch (error) {}
  };
  const DeleteFile = async (id) => {
    try {
      setLoadingBtn(true);
      setLoading(true);
      let df = "Messenger";
      const response = await Api_DeleteFileMessage(id, MessageID, df);

      if (response.status == "200") {
        ViewFile(MessageID);
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
      setLoading(false);
    } catch (error) {
      setLoadingBtn(false);
      setLoading(false);
      toast.error("Error");
    }
  };
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title={title} Loading={Loading} />

          {MessageID != "" ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      {ReplyID ? (
                        <Col sm="12">
                          <FormGroup>
                            <p>
                              <span>Reply To : </span>
                              {objsender.sender}
                            </p>
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm="12">
                          <FormGroup>
                            <Label>{"To"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <AsyncSelect onChange={handleChange} isMulti defaultOptions cacheOptions loadOptions={loadOptions} />
                          </FormGroup>
                        </Col>
                      )}
                      {ReplyID || ForwardID ? (
                        <Col sm="12">
                          <FormGroup>
                            <p>
                              <span> Subject : </span>
                              {objsender.subject}
                            </p>
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm="12">
                          <FormGroup>
                            <Label>{"Subject"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input name="subject" className="form-control" value={obj.subject} onChange={onInputChange} required />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      )}
                      {ForwardID ? (
                        <Col sm="12">
                          <div dangerouslySetInnerHTML={{ __html: objsender.text }}></div>
                        </Col>
                      ) : (
                        <Col sm="12">
                          <FormGroup>
                            <Label>{"Text"}</Label>

                            <Editor
                              apiKey="97twnsawko365at3kw26izpuzdz92cln8uduxn75z1lt9zju"
                              onInit={(evt, editor) => (editorRef.current = editor)}
                              init={{
                                // plugins:
                                //   "directionality  anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount   export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography",
                                // toolbar:
                                //   "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight  | numlist bullist indent outdent | emoticons charmap | removeformat",
                                plugins:
                                  "directionality anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                toolbar:
                                  "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                tinycomments_mode: "embedded",

                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                              }}
                              initialValue={obj.text}
                              onChange={onInputChange}
                              name="text"
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm="12">
                        <FormGroup>
                          <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                            {fileList != null
                              ? fileList.map((item) => {
                                  return (
                                    <li key={item.id} className="list-group-item">
                                      <span>
                                        <i className="fa fa-cloud-download txt-primary me-2"></i>
                                        {item.fileName}
                                      </span>
                                      {!ForwardID ? (
                                        <i
                                          onClick={() => {
                                            DeleteFile(item.id);
                                          }}
                                          className="fa fa-trash-o  ml-10 txt-danger me-2"
                                        ></i>
                                      ) : null}
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </FormGroup>
                      </Col>
                      {!ForwardID ? (
                        <Col sm="12">
                          <FormGroup>
                            <div className="dz-message needsclick">
                              <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                multiple={true}
                                accept={DataTypeUploader}
                                inputContent="Drop files here or click to upload."
                                styles={{
                                  dropzone: { width: "100%" },
                                  dropzoneActive: { borderColor: "green" },
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};

export default NewMessage;
