import React, { useEffect, Fragment, useContext } from "react";

import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType/index";
import useState from "react-usestateref";
import { Api_AddTelBook, Api_EditTelBook, Api_GetTelBook } from "../../../api/Admin/TelBook";

const TelBookAdd = () => {
  const { telBookID, categoryID } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const navigate = useNavigate();
  const [ListCompany, setListCompany] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [titlePage, settitlePage] = useState(false);
  const [obj, setobj, objRef] = useState({
    name: "",
    family: "",
    position: "",
    mobile: "",
    officeTell: "",
    remarks: "",
    telBookCategory: "0",
    email: "",
    workOffice: "",
    homeTel: "",
    address: "",
    officeName: "",
    website: "",
    companyID: DefaultCompanyID,
    abbreviation: "",
  });

  useEffect(() => {
    if (categoryID) {
      setobj({
        ...obj,
        telBookCategory: categoryID,
      });
      if (categoryID == "CompanyTelBook") {
        settitlePage("Company Tel Book");
        LoadCompanyList();
      } else if (categoryID == "PrivateTelBook") {
        settitlePage("Private Tel Book");
      } else if (categoryID == "Interacting") {
        settitlePage("Interacting Co.'s Tel. Book");
        LoadCompanyList();
      }
    }
    if (telBookID) {
      GetDataForEdit(telBookID);
    } else {
    }
  }, []);
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const backupobj = objRef.current;

        const response = await Api_AddTelBook(backupobj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Tel has been added");

          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const backupobj = objRef.current;

        const response = await Api_EditTelBook(SelectedRowID, backupobj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetTelBook(id);
      console.log(JSON.stringify(response));
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);

        setobj({
          ...obj,
          name: response.data.data.name,
          family: response.data.data.family,
          remarks: response.data.data.remarks,
          position: response.data.data.position,
          mobile: response.data.data.mobile,
          officeTell: response.data.data.officeTell,
          telBookCategory: response.data.data.telBookCategory,
          email: response.data.data.email,
          workOffice: response.data.data.workOffice,
          address: response.data.data.address,
          homeTel: response.data.data.homeTel,
          officeName: response.data.data.officeName,
          website: response.data.data.website,
          abbreviation: response.data.data.abbreviation,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const ResetForm = async () => {
    setobj({
      ...obj,
      name: "",
      family: "",
      position: "",
      mobile: "",
      officeTell: "",
      remarks: "",
      email: "",
      workOffice: "",
      homeTel: "",
      address: "",
      officeName: "",
    });

    if (SelectedRowID != 0) {
      navigate(`${process.env.PUBLIC_URL}/admin/TelBook/${obj.telBookCategory}`);
    } else {
      setSelectedRowID(0);
    }
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title={titlePage} Loading={Loading} />
          {Loading == false ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      {obj.telBookCategory != "PrivateTelBook" ? (
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <Input
                              type="select"
                              name="select"
                              className="form-control digits"
                              value={obj.companyID}
                              onChange={(txt) => {
                                onChangeCompany(txt.target.value);
                              }}
                            >
                              {ListCompany != null
                                ? ListCompany.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </Input>
                          </FormGroup>
                        </Col>
                      ) : null}
                      {obj.telBookCategory == "Interacting" ? (
                        <Col sm="3">
                          <FormGroup>
                            <Label>{"Company Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input
                                name="officeName"
                                className="form-control"
                                maxLength={100}
                                value={obj.officeName}
                                onChange={onInputChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      ) : null}
                      {obj.telBookCategory == "Interacting" ? (
                        <Col sm="3">
                          <FormGroup>
                            <Label>{"Company Abbreviation"}</Label>
                            <InputGroup>
                              <Input name="abbreviation" className="form-control" maxLength={50} value={obj.abbreviation} onChange={onInputChange} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      ) : null}
                      {obj.telBookCategory != "0" ? (
                        <>
                          <Col sm="3">
                            <FormGroup>
                              <Label>{"Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                              <InputGroup>
                                <Input name="name" className="form-control" maxLength={100} value={obj.name} onChange={onInputChange} required />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup>
                              <Label>{"Family"}</Label>
                              <InputGroup>
                                <Input name="family" className="form-control" maxLength={100} value={obj.family} onChange={onInputChange} />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup>
                              <Label>{"Position"}</Label>
                              <InputGroup>
                                <Input name="position" className="form-control" maxLength={100} value={obj.position} onChange={onInputChange} />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          {obj.telBookCategory == "PrivateTelBook" ? (
                            <Col sm="3">
                              <FormGroup>
                                <Label>{"WorkOffice"}</Label>
                                <InputGroup>
                                  <Input name="workOffice" className="form-control" maxLength={100} value={obj.workOffice} onChange={onInputChange} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col sm="3">
                            <FormGroup>
                              <Label>{"Office Tel"}</Label>
                              <InputGroup>
                                <Input name="officeTell" className="form-control" maxLength={50} value={obj.officeTell} onChange={onInputChange} />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          {obj.telBookCategory == "PrivateTelBook" ? (
                            <Col sm="3">
                              <FormGroup>
                                <Label>{"Home Tel"}</Label>
                                <InputGroup>
                                  <Input name="homeTel" className="form-control" maxLength={50} value={obj.homeTel} onChange={onInputChange} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col sm="3">
                            <FormGroup>
                              <Label>{"Mobile"}</Label>
                              <InputGroup>
                                <Input name="mobile" className="form-control" maxLength={50} value={obj.mobile} onChange={onInputChange} />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          {obj.telBookCategory == "PrivateTelBook" || obj.telBookCategory == "Interacting" ? (
                            <Col sm="3">
                              <FormGroup>
                                <Label>{"Mail Box"}</Label>
                                <InputGroup>
                                  <Input name="email" className="form-control" maxLength={100} value={obj.email} onChange={onInputChange} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : null}
                          {obj.telBookCategory == "Interacting" ? (
                            <Col sm="3">
                              <FormGroup>
                                <Label>{"Web Site"}</Label>
                                <InputGroup>
                                  <Input name="website" className="form-control" maxLength={100} value={obj.website} onChange={onInputChange} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : null}

                          {obj.telBookCategory == "PrivateTelBook" || obj.telBookCategory == "Interacting" ? (
                            <Col sm="12">
                              <FormGroup>
                                <Label>{"Address"}</Label>
                                <InputGroup>
                                  <Input name="address" className="form-control" maxLength={255} value={obj.address} onChange={onInputChange} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col sm="6">
                            <FormGroup>
                              <Label>{"Remarks"}</Label>
                              <InputGroup>
                                <Input name="remarks" className="form-control" maxLength={50} value={obj.remarks} onChange={onInputChange} />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </>
                      ) : null}
                    </Row>

                    {obj.telBookCategory != "0" ? (
                      <Row>
                        <Col sm="12">
                          {SelectedRowID == 0 ? (
                            <Button type="submit" className="btn btn-success btn-md">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Add"
                              )}
                            </Button>
                          ) : (
                            <>
                              <Button type="submit" className="btn btn-success btn-md m-r-15">
                                {LoadingBtn ? (
                                  <div className="loader-box2">
                                    <Spinner attrSpinner={{ className: "loader-7" }} />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                              {LoadingBtn == false ? (
                                <Btn
                                  attrBtn={{
                                    color: "light",
                                    type: "button",
                                    onClick: () => ResetForm(),
                                  }}
                                >
                                  Cancel
                                </Btn>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};

export default TelBookAdd;
