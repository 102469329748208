import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { H5, H4, LI, P, UL, H6, Btn, Alerts } from "../../../AbstractElements";
import blogSingle from "../../../assets/images/blog/blog-single.jpg";
import { Api_AdviceQuestionTitleCategoryList } from "../../../api/Admin/AdviceQuestionTitle";
import { Api_AdviceQuestionList, Api_DeleteAdviceQuestion } from "../../../api/Admin/AdviceQuestion";
import { Api_SetActionAQ } from "../../../api/Admin/UserSeeEvents";
import MemberTypeContext from "../../../_helper/MemberType/index";
const Question = () => {
  const navigate = useNavigate();
  const { setnoti, noti } = useContext(MemberTypeContext);
  const { adviceID } = useParams();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [ListTitle, setListTitle] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [obj, setobj, objRef] = useState({
    id: 0,
    titleCategoryID: 0,
    myAdvice: false,
    adviceQuestionCategory: "Question",
    archive: false,
    adviceID: 0,
  });
  useEffect(() => {
    if (adviceID) {
      setobj({
        ...obj,
        adviceID: adviceID,
      });
    }
    LoadTitle();
    LoadData(false);
  }, []);
  const LoadTitle = async () => {
    try {
      setLoading(true);
      const response = await Api_AdviceQuestionTitleCategoryList(obj.adviceQuestionCategory);
      setListTitle(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const LoadData = async (btnClick) => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      const response = await Api_AdviceQuestionList(newobj);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
          if (btnClick == true) {
            toast.warning("No results found");
          }
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteAdviceQuestion(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Question Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const toggle = () => setModal(!modal);
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const styless = {
    backgroundImage: `url(${blogSingle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
  };
  const onChangeCategory = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        titleCategoryID: val,
        adviceID: 0,
      });
    } else {
      setobj({
        ...obj,
        titleCategoryID: 0,
        adviceID: 0,
      });
    }
    LoadData(true);
  };
  const showMore = async (id, setAction) => {
    try {
      const AllData = [...tableData];
      const DataIndex = AllData.findIndex((p) => p.id == id);
      const TempData = AllData[DataIndex];
      TempData.show = !TempData.show;
      AllData[DataIndex] = TempData;
      setTableData(AllData);

      if (setAction == true) {
        const response = await Api_SetActionAQ(id);

        setnoti({
          ...noti,
          notiQuestion: id,
        });
      }
    } catch (err) {
      toast.error("Error");
    }
  };
  const onChangeCheckBox1 = (val) => {
    setobj({
      ...obj,
      myAdvice: val,
      adviceID: 0,
    });
    LoadData(true);
  };
  const onChangeCheckBox2 = (val) => {
    setobj({
      ...obj,
      archive: val,
      adviceID: 0,
    });
    LoadData(true);
  };
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Breadcrumbs parent="Services Management" title="Questions" Loading={Loading} />
          <Row>
            <Col md="12" className="project-list">
              <Card>
                <CardBody className="dismiss-text">
                  <Alerts attrAlert={{ color: "primary inverse alert-dismissible ", isOpen: true, target: "Alert-1" }}>
                    <i className="fa fa-question-circle"></i>
                    <P attrPara={{ className: "h4 txt-info" }}>
                      Your Comments & Suggestions = Encourage the Company Excellence
                      <Btn
                        attrBtn={{
                          className: "btn-close",
                          color: "transperant",
                          id: "Alert-1",
                          onClick: () => {
                            navigate(`${process.env.PUBLIC_URL}/Admin/Questions/Add`);
                          },
                        }}
                      >
                        <span className="bg-primary"> New Question</span>
                      </Btn>
                    </P>
                  </Alerts>
                </CardBody>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="titleCategoryID"
                          className="form-control digits"
                          value={obj.titleCategoryID}
                          onChange={(txt) => {
                            onChangeCategory(txt.target.value);
                          }}
                        >
                          <option key={0} value={0}>
                            ...
                          </option>
                          {ListTitle != null
                            ? ListTitle.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col
                      sm="2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        alignSelf: "center",
                      }}
                    >
                      <FormGroup className="form-group">
                        <div className="checkbox">
                          <Input id="checkbox1" type="checkbox" defaultChecked={obj.myAdvice} onClick={() => onChangeCheckBox1(!obj.myAdvice)} />
                          <Label className="text-muted" for="checkbox1">
                            My Question
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col
                      sm="2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        alignSelf: "center",
                      }}
                    >
                      <FormGroup className="form-group">
                        <div className="checkbox">
                          <Input id="checkbox2" type="checkbox" defaultChecked={obj.myAdvice} onClick={() => onChangeCheckBox2(!obj.archive)} />
                          <Label className="text-muted" for="checkbox2">
                            Archived Questions
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {tableData != null ? (
            <div className="activity-media">
              {tableData.map((item) => {
                return (
                  <Row key={item.id}>
                    <Col sm="12">
                      <div className="blog-single">
                        <div className="blog-box blog-details">
                          <Card>
                            <CardBody>
                              <div className="blog-details">
                                <UL
                                  attrUL={{
                                    className: "blog-social plan flex-row simple-list",
                                  }}
                                >
                                  <LI>Question Submitted by : {item.user}</LI>
                                  <LI>Date : {item.dateAdd}</LI>
                                  <LI>Code : {item.code}</LI>
                                  <LI>Sender : {item.user}</LI>
                                </UL>
                                <H4
                                  attrH4={{
                                    className: "plan-Title",
                                  }}
                                >
                                  <span>Title :</span> <span>{item.adviceQuestionTitleCategoryTitle}</span>
                                </H4>
                                {item.show == true ? (
                                  <>
                                    <H4
                                      attrH4={{
                                        className: "plan-Title",
                                      }}
                                    >
                                      <span> Question :</span>
                                    </H4>

                                    <div className="box-readmore" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                    <div onClick={() => showMore(item.id, false)} className="ShowMore">
                                      <i className="fa fa-angle-up"></i>
                                    </div>
                                  </>
                                ) : (
                                  <div onClick={() => showMore(item.id, true)} className="ShowMore">
                                    <i className="fa fa-angle-down"></i>
                                  </div>
                                )}
                                <div style={{ marginTop: 20, textAlign: "right" }}>
                                  <span>
                                    <Button
                                      className="btn btn-success mr-5 btn-xs"
                                      onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Questions/Responses/${item.id}`)}
                                    >
                                      Responses
                                    </Button>
                                  </span>

                                  {item.allowEdit == true ? (
                                    <>
                                      <span>
                                        <Button
                                          className="btn btn-info mr-5 btn-xs"
                                          onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Questions/Edit/${item.id}`)}
                                        >
                                          Edit
                                        </Button>
                                      </span>
                                      <span>
                                        <Button
                                          className="btn btn-danger btn-xs"
                                          onClick={() => {
                                            setSelectedRowID(item.id);
                                            toggle();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Question;
