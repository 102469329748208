import React, { Fragment, useContext, useState } from "react";
import { Container, Col, Row, Card, CardBody } from "reactstrap";
import { H5 } from "../../../AbstractElements";

const Notyetactivated = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ marginTop: "10px" }}>
            <Card>
              <CardBody>
                <H5>Not yet activated</H5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Notyetactivated;
