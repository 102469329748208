import { Api_DataTypeAccessListStr } from "../../../api/Admin/DataTypeAccess";

const DataTypeAccessList = async () => {
  const response = await Api_DataTypeAccessListStr();
  if (response.status && response.status == 200 && response.data.isSuccess == true) {
    return response.data.data;
  } else {
    return null;
  }
};
export { DataTypeAccessList };
