import axios from "axios";
import { URL, Version } from "../URL";

const Api_AddDailyPlans = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/DailyPlans`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_DailyPlansList = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/DailyPlans/GetAll/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetDailyPlansDashboard = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/DailyPlans/GetForDashboard`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetDailyPlans = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/DailyPlans/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditDailyPlans = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/DailyPlans?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_DeleteDailyPlans = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/DailyPlans/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};

const Api_ChangeIsDone = async (id, val) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/DailyPlans/ChangeIsDone/${id}/${val}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_GetDailyPlansDashboard,
  Api_ChangeIsDone,
  Api_AddDailyPlans,
  Api_DailyPlansList,
  Api_DeleteDailyPlans,
  Api_EditDailyPlans,
  Api_GetDailyPlans,
};
