import React, { Fragment, useState, useContext, useEffect } from "react";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label, Col, Row } from "reactstrap";
import { Btn, H5, UL } from "../../../AbstractElements";
import { Password, SignIn } from "../../../Constant";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import { getToken } from "../../../api/Auth/GetToken";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { ToastContainer, toast } from "react-toastify";
import { userRole } from "../../../api/Auth/GetToken";
import { validateCaptcha } from "react-simple-captcha";
import Captcha from "./Captcha";
import { IsGust } from "../../../api/URL";
const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { setTypeName } = useContext(MemberTypeContext);

  const login = async () => {
    let user_captcha_value = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha_value) == true) {
      try {
        setDisableBtn(true);
        setLoading(true);
        if (email != "" && password != "") {
          const response = await getToken(email, password);

          if (response.status && response.status == 200 && response.data.access_token) {
            await localStorage.setItem("token", response.data.access_token);
            GetUserInfo();
          } else if (response.response && response.response.data.isSuccess == false) {
            toast.error(response.response.data.message);
          } else if (response.data && response.data.isSuccess == false) {
            toast.error(response.data.message);
          } else {
            toast.error("Error - 1");
          }
          setDisableBtn(false);
        } else {
          setDisableBtn(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("The security phrase is not correct");
    }
  };
  const GetUserInfo = async () => {
    try {
      var token = await localStorage.getItem("token");
      if (token) {
        const response = await userRole();
        if (response == "404") {
          toast.error("Error connecting to the server");
        } else if (response.status == "200") {
          setTypeName({
            Family: response.data.family,
            Name: response.data.name,
            Position: response.data.jobPositionDescription,
            CompanyName: response.data.companyName,
            DefaultCompanyID: response.data.companyID,
            Menu: response.data.menu,
            agreeProtocol: response.data.agreeProtocol,
            Img: response.data.img,
          });
          toast.success("You have successfully login");
          if (response.data.agreeProtocol == true) {
            setTimeout(() => {
              navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(`${process.env.PUBLIC_URL}/admin/AgreePorotocol`, { replace: true });
            }, 1000);
          }
        } else {
          toast.error("Error connecting to the server");
        }
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  useEffect(() => {
    if (IsGust == "1") {
      setPassword("Guest");
      setEmail("Guest");
    }
  }, []);
  return (
    <Fragment>
      <Form className="theme-form login-form">
        <div className="login-back">
          <div className="login-form-header"></div>
          <FormHeader selected={selected} />

          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <i className="icon-email"></i>
              </InputGroupText>
              <Input
                autoComplete="off"
                className="form-control"
                type="text"
                required=""
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                defaultValue={email}
                placeholder="Username"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
              <Input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={password}
                required=""
                placeholder="Password"
              />
              <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <div>
              <Captcha />
            </div>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <i className="icofont icofont-exclamation"></i>
              </InputGroupText>
              <Input
                autoComplete="off"
                className="form-control"
                type="text"
                required=""
                id="user_captcha_input"
                placeholder="Please Enter these Characters"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Row>
              {IsGust == "0" ? (
                <>
                  <Col sm="6">
                    <a href="https://demo.planetzamina.com" className="btn-block btn-Guest btn btn-light">
                      Guest
                    </a>
                  </Col>
                  <Col sm="6">
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "btn-block",
                        disabled: disableBtn,
                        onClick: (e) => login(),
                      }}
                    >
                      {loading ? "LOADING..." : SignIn}
                    </Btn>
                  </Col>
                </>
              ) : (
                <Col sm="12">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "btn-block",
                      disabled: disableBtn,
                      onClick: (e) => login(),
                    }}
                  >
                    {loading ? "LOADING..." : SignIn}
                  </Btn>
                </Col>
              )}
            </Row>
          </FormGroup>
        </div>
      </Form>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginTab;
