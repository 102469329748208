import React, { useEffect, Fragment, useContext } from "react";
import { Btn, Spinner } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DocTableList } from "./";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Api_CompanyListBasic,
  Api_CompanyCode,
} from "../../../api/Admin/Company";
import {
  Api_ProjectsList,
  Api_GetProjectCode,
} from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import {
  Api_FillingMainStructuresListCompanyChild,
  Api_FillingMainStructuresListCompany_Parent_level1,
  Api_FillingCode,
  Api_FillingGetPath,
} from "../../../api/Admin/Filling";
import {
  Api_ProducerCompanyList,
  Api_GetProducerGetCode,
} from "../../../api/Admin/ProducerCompany";
import { Api_Search, Api_CheckAccess } from "../../../api/Admin/Doc";
import {
  Api_ProjectUnitListProject_Parent,
  Api_ProjectUnitListPlant,
  Api_ProjectUnitsCode,
} from "../../../api/Admin/ProjectUnit";
import { Api_PhaseListParent, Api_PhaseCode } from "../../../api/Admin/Phase";
import {
  Api_DocTypeListParent,
  Api_DocTypeCode,
} from "../../../api/Admin/DocType";
import {
  Api_DisciplineListParent,
  Api_DisciplineCode,
} from "../../../api/Admin/Discipline";
import {
  Api_SenderRecieverListParent,
  Api_SenderRecieverCode,
} from "../../../api/Admin/RecieverSender";
import DatePicker from "react-datepicker";
import DeniReactTreeView from "deni-react-treeview";
import MemberTypeContext from "../../../_helper/MemberType/index";
const DocSearch = ({ contractsID, ViewFileBA, documentType }) => {
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [startDate, setstartDate] = useState(new Date());
  const navigate = useNavigate();
  const [openDate, setopenDate] = useState(false);
  const [senderrecievername, setsenderrecievername] = useState("");
  const [RDName, setRDName] = useState("");
  const [modalDocTypeTechnical, setmodalDocTypeTechnical] = useState(false);
  const [modalPhase, setmodalPhase] = useState(false);
  const [modalDiscipline, setmodalDiscipline] = useState(false);
  const [modalDocType, setmodalDocType] = useState(false);
  const [modalFilling, setmodalFilling] = useState(false);
  const [modalSenderReciever, setmodalSenderReciever] = useState(false);
  const [modalUnit, setmodalUnit] = useState(false);
  const [UnitName, setUnitName] = useState("");
  const [DocTypeName, setDocTypeName] = useState("");
  const [DisciplineName, setDisciplineName] = useState("");
  const [PhaseName, setPhaseName] = useState("");
  const [DocTypeTechnicalName, setDocTypeTechnicalName] = useState("");
  const [FillingName, setFillingName] = useState("");
  const [ShowFilling, setShowFilling] = useState(false);
  const [showUnit, setshowUnit] = useState(true);
  const [ShowIsReqUnit, setShowIsReqUnit] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListDocType, setListDocType] = useState([]);
  const [ListFilling, setListFilling] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [ListDiscipline, setListDiscipline] = useState([]);
  const [ListPhase, setListPhase] = useState([]);
  const [ListDocTypeTechnical, setListDocTypeTechnical] = useState([]);
  const [ListUnit, setListUnit] = useState([]);
  const [senderrecieverlist, setsenderrecieverlist] = useState([]);
  const [ProducerID, setProducerID] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [ShowLetterPartner, setShowLetterPartner] = useState(false);
  const [PartnerName, setPartnerName] = useState(false);
  const [showPartner, setshowPartner] = useState(false);
  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    unitID: 0,
    docTypeID: 0,
    fillingID: null,
    disciplineID: null,
    phaseID: null,
    technicalDocTypeID: null,
    docTitle: "",
    docDescription: "",
    lastEdit: "",
    priority: "All",
    security: "All",
    transmital: "",
    letter: "",
    position: "Active",
    producerID: null,
    producerDocumentCode: "",
    userDocCode: "",
    serialID: null,
    versionID: "",
    senderID: "",
    recieverID: "",
    ammendments: "",
    dateAdd: "",
    systemCode: "",
    confrim: "Yes",
    archiveNo: "",
  });
  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
  });
  const [objSystemCode, setobjSystemCode] = useState({
    companyCode: "",
    projectCode: "",
    plantCode: "",
    doctypeCode: "",
    fillingCode: "n",
    rd: "",
    producerode: "",
    year: "",
    unitcode: "",
    phasecode: "",
    disciplinecode: "",
    docTypeTechnicalcode: "",
  });
  const themes = ["classic"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        LoadCompanyList();
        onChangeCompany(obj.companyID, false);
      } catch (err) {}
    };

    fetchData();
  }, [obj.companyID]);
  const CheckAccessBtn = async () => {
    try {
      setLoading(true);
      const response = await Api_CheckAccess();
      if (
        response.status &&
        response.status == 200 &&
        response.data.isSuccess == true
      ) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const handleStartDate = (date) => {
    console.log("date:" + date);
    if (date == null) {
      setobj({
        ...obj,
        dateAdd: "",
        lastEdit: "",
      });
      setstartDate(new Date());
    } else {
      setstartDate(date);
      setobj({
        ...obj,
        dateAdd: date,
        lastEdit: date,
      });
    }

    console.log("formattedDate:" + new Date(date));
  };
  const handleStartDate2 = () => {
    setopenDate(true);
    setstartDate(new Date());
    setobj({
      ...obj,
      dateAdd: new Date(),
      lastEdit: new Date(),
    });
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      CheckAccessBtn();
      LoadProducerList();
      setLoading(false);
    } catch (error) {}
  };
  const LoadSenderRecierList = async (mod) => {
    try {
      setLoading(true);
      const response = await Api_SenderRecieverListParent(mod);
      setsenderrecieverlist(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async () => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(obj.companyID);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadUintOfPlant = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitListPlant(id);
      setListUnit(response.data.data);
      setLoading(false);
      //console.log("Api_ProjectUnitListPlant:" + JSON.stringify(response.data.data));
    } catch (error) {}
  };
  const LoadUintOfProject = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitListProject_Parent(id);
      setListUnit(response.data.data);
      setLoading(false);
      console.log(
        "Api_ProjectUnitListPlant:" + JSON.stringify(response.data.data)
      );
    } catch (error) {}
  };
  const LoadDocType = async (mod, id) => {
    try {
      setLoading(true);
      setListFilling(null);
      if (mod == "company") {
        const response =
          await Api_FillingMainStructuresListCompany_Parent_level1(id);
        setListDocType(response.data.data);
      } else {
        //console.log(JSON.stringify(obj));
      }
      setLoading(false);
    } catch (error) {}
  };
  const LoadFilling = async (data, parent) => {
    try {
      setLoading(true);
      const response = await Api_FillingMainStructuresListCompanyChild(
        data.companyID,
        parent
      );

      setListFilling(response.data.data);

      setLoading(false);
    } catch (error) {}
  };
  const LoadTechnicalParts = async () => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(0, "TechnicalDocuments");
      setListDiscipline(response1.data.data);
      const response2 = await Api_DocTypeListParent();
      setListDocTypeTechnical(response2.data.data);
      const response3 = await Api_PhaseListParent();
      setListPhase(response3.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadDiscipline = async (id) => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(id, "Contractors");
      setListDiscipline(response1.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val) => {
    setUnitName("");
    setsenderrecievername("");
    setsenderrecieverlist(null);
    setobj({
      ...obj,
      companyID: val,
      unitID: null,
      dateAdd: null,
    });
    LoadProjectList(val);
    LoadPlantList(val);
    LoadDocType("company", val);

    setDisciplineName("");
    setPhaseName("");
    setDocTypeTechnicalName("");
    setFillingName("");
  };

  const GetDocTypeCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);
        if (
          response.data.data.code.toUpperCase() == "L" ||
          response.data.data.code.toUpperCase() == "C"
        ) {
          setshowPartner(true);
        } else {
          setshowPartner(false);
        }
        if (response.data.data.code.toUpperCase() == "L") {
          setshowUnit(false);
          setobjSystemCode({
            ...objSystemCode,
            unitcode: "",
          });
        } else {
          setshowUnit(true);
        }

        if (response.data.data.code.toUpperCase() == "D") {
          if (
            objSystemCode.projectCode != "" ||
            objSystemCode.plantCode != ""
          ) {
            setShowIsReqUnit(true);
          } else {
            setShowIsReqUnit(false);
          }
          setShowFilling(false);
          LoadTechnicalParts();
        } else {
          LoadFilling(obj, id);
          setShowFilling(true);

          setShowIsReqUnit(false);
        }

        if (response.data.data.code.toUpperCase() == "L") {
          setShowLetterPartner(true);
          setPartnerName("Letter Partner");
        } else if (response.data.data.code.toUpperCase() == "C") {
          setShowLetterPartner(true);
          setPartnerName("Contract Partner");
        } else if (response.data.data.code.toUpperCase() == "B") {
          setShowLetterPartner(true);
          setPartnerName("Partner");
        } else {
          setShowLetterPartner(false);
        }

        setobjSystemCode({
          ...objSystemCode,
          doctypeCode: response.data.data.code,
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const GetFillingCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);
        setobjSystemCode({
          ...objSystemCode,
          fillingCode: response.data.data.code,
        });

        if (
          objSystemCode.doctypeCode.toUpperCase() == "L" &&
          response.data.data.code.toUpperCase() == "ER"
        ) {
          setRDName("Reciever");
          LoadSenderRecierList("Sender");
        } else if (
          objSystemCode.doctypeCode.toUpperCase() == "L" &&
          response.data.data.code.toUpperCase() == "ED"
        ) {
          setRDName("Sender");
          LoadSenderRecierList("Reciever");
        }
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const GetSenderRecieverCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_SenderRecieverCode(id);
        setobjSystemCode({
          ...objSystemCode,
          rd: response.data.data.code,
        });
      } else {
        setobjSystemCode({
          ...objSystemCode,
          rd: "",
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeProject = (val) => {
    setFillingName("");
    setDocTypeName("");
    if (val > 0) {
      setobj({
        ...obj,
        projectID: val,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
      });

      LoadUintOfProject(val);
    } else {
      setobj({
        ...obj,
        projectID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
        plantID: null,
      });

      setListUnit(null);
    }
  };
  const onChangePlant = (val) => {
    setFillingName("");
    setDocTypeName("");
    if (val > 0) {
      setobj({
        ...obj,
        plantID: val,
        projectID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
      });
      //LoadDocType("plant", val);

      LoadUintOfPlant(val);
    } else {
      setobj({
        ...obj,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
        projectID: null,
      });

      //LoadDocType("company", obj.companyID);
      setListUnit(null);
    }
  };
  const onChangeProducer = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        producerID: val,
      });
      setProducerID(val);
    } else {
      setobj({
        ...obj,
        producerID: null,
      });
      setProducerID(0);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSelectItemDocType = async (item) => {
    try {
      setobj({
        ...obj,
        docTypeID: item.id,
        fillingID: null,
      });

      setsenderrecievername("");
      setsenderrecieverlist(null);
      setListFilling(null);
      setDocTypeName(item.text);
      setFillingName("");
      setDisciplineName("");
      setPhaseName("");
      setDocTypeTechnicalName("");
      setListDiscipline(null);
      setmodalDocType(false);
      setListDocTypeTechnical(null);
      GetDocTypeCode(item.id);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemFilling = async (item) => {
    try {
      setsenderrecievername("");
      setsenderrecieverlist(null);
      setDisciplineName("");
      setobj({
        ...obj,
        fillingID: item.id,
      });

      const response = await Api_FillingGetPath(item.id, obj.companyID);

      setFillingName(response.data.message);
      setmodalFilling(false);
      GetFillingCode(item.id);

      if (objSystemCode.doctypeCode.toUpperCase() == "V") {
        LoadDiscipline(item.id);
      } else {
        setListDiscipline(null);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemUnit = async (item) => {
    try {
      if (item.isLeaf == "true") {
        const response = await Api_ProjectUnitsCode(item.id);

        setobjSystemCode({
          ...objSystemCode,
          unitcode: response.data.data.code,
        });

        setUnitName(item.text);
        setobj({
          ...obj,
          unitID: item.id,
        });
        setmodalUnit(false);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onRenderItem = (item) => {
    return (
      <div className="treeview-item-example">
        <span
          onClick={() => onSelectItemUnit(item)}
          className="treeview-item-example-text"
        >
          {item.text}
        </span>
        {item.code != null ? (
          <span className="actionButton trash">({item.code})</span>
        ) : null}
      </div>
    );
  };
  const onSelectItemSenderReciever = async (item) => {
    try {
      // console.log("objSystemCode.doctypeCode:" + objSystemCode.doctypeCode);
      // console.log("objSystemCode.fillingCode:" + objSystemCode.fillingCode);
      if (
        objSystemCode.doctypeCode.toUpperCase() == "L" &&
        objSystemCode.fillingCode.toUpperCase() == "ER"
      ) {
        console.log("recieverID:" + item.id);
        setobj({
          ...obj,
          recieverID: item.id,
        });
      } else if (
        objSystemCode.doctypeCode.toUpperCase() == "L" &&
        objSystemCode.fillingCode.toUpperCase() == "ED"
      ) {
        console.log("senderID:" + item.id);
        setobj({
          ...obj,
          senderID: item.id,
        });
      }
      setsenderrecievername(item.text);
      setmodalSenderReciever(false);
      GetSenderRecieverCode(item.id);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDocTypeTechnical = async (item) => {
    try {
      const response = await Api_DocTypeCode(item.id);
      setobjSystemCode({
        ...objSystemCode,
        docTypeTechnicalcode: response.data.data.code,
      });
      setobj({
        ...obj,
        technicalDocTypeID: item.id,
      });
      setDocTypeTechnicalName(item.text);
      setmodalDocTypeTechnical(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemPhase = async (item) => {
    try {
      const response = await Api_PhaseCode(item.id);
      console.log("response:" + JSON.stringify(response));
      setobjSystemCode({
        ...objSystemCode,
        phasecode: response.data.data.code,
      });

      setobj({
        ...obj,
        phaseID: item.id,
      });
      setPhaseName(item.text);
      setmodalPhase(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDiscipline = async (item) => {
    try {
      const response = await Api_DisciplineCode(item.id);

      setobjSystemCode({
        ...objSystemCode,
        disciplinecode: response.data.data.code,
      });
      setobj({
        ...obj,
        disciplineID: item.id,
      });
      setDisciplineName(item.text);
      setmodalDiscipline(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };

  const Search = async () => {
    console.log(obj.archiveNo);
    try {
      setLoading(true);
      if ((obj.docTypeID == 0 || obj.docTypeID == "") && obj.archiveNo == "") {
        toast.error("Choose a Doc Type");
        console.log(obj.archiveNo);
      } else if (
        objSystemCode.doctypeCode != "D" &&
        (obj.fillingID == null || obj.fillingID == "") &&
        obj.archiveNo == ""
      ) {
        toast.error("Choose a Filling");
      } else {
        const response = await Api_Search(obj);
        if (
          response.status &&
          response.status == 200 &&
          response.data.isSuccess == true
        ) {
          if (response.data.data != "") {
            setTableData(response.data.data);
          } else {
            toast.error("No document matching your search was found");
            setTableData(null);
          }
        } else if (
          response.data &&
          response.data.statusCode &&
          response.data.statusCode == "UnAuthorized"
        ) {
          toast.error("You do not have access permission");
        } else if (
          response.response &&
          response.response.data.isSuccess == false
        ) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Row>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label>{" "}
                  <label className="txt-danger Required">{"*"}</label>
                  <Input
                    type="select"
                    name="companyID"
                    className="form-control digits"
                    value={obj.companyID}
                    onChange={(txt) => {
                      onChangeCompany(txt.target.value);
                    }}
                  >
                    {ListCompany != null
                      ? ListCompany.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">{"Project"}</Label>
                  <Input
                    type="select"
                    name="projectID"
                    className="form-control digits"
                    value={obj.projectID != null ? obj.projectID : 0}
                    onChange={(txt) => {
                      onChangeProject(txt.target.value);
                    }}
                  >
                    <option value={0} key={0}>
                      Select...
                    </option>
                    {ListProject != null
                      ? ListProject.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          );
                        })
                      : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">{"Plant"}</Label>
                  <Input
                    type="select"
                    name="plantID"
                    className="form-control digits"
                    value={obj.plantID != null ? obj.plantID : 0}
                    onChange={(txt) => {
                      onChangePlant(txt.target.value);
                    }}
                  >
                    <option value={0} key={0}>
                      Select...
                    </option>
                    {ListPlant != null
                      ? ListPlant.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          );
                        })
                      : null}
                  </Input>
                </FormGroup>
              </Col>

              {(obj.projectID != null || obj.plantID != null) &&
              showUnit == true ? (
                <Col sm="3">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">{"Unit"}</Label>
                    {ShowIsReqUnit ? (
                      <label className="txt-danger Required">{"*"}</label>
                    ) : null}
                    {ListUnit != null && ListUnit != "" ? (
                      <Input
                        className="form-control"
                        value={UnitName}
                        onClick={() => {
                          setmodalUnit(true);
                        }}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        className="form-control"
                        value={""}
                        onClick={() => {
                          toast.info("List Is Empty");
                        }}
                        readOnly={true}
                      />
                    )}
                  </FormGroup>
                </Col>
              ) : null}
              <Col sm="3">
                <FormGroup>
                  <Label>{"System Code"}</Label>
                  <InputGroup>
                    <Input
                      name="systemCode"
                      maxLength={255}
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.systemCode || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Serial No"}</Label>
                  <InputGroup>
                    <Input
                      name="serialID"
                      maxLength={255}
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.serialID || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Version"}</Label>
                  <InputGroup>
                    <Input
                      name="versionID"
                      maxLength={255}
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.versionID || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {obj.dateAdd != "" && obj.dateAdd != null ? (
                <Col sm="3">
                  <FormGroup>
                    <Label>{"Last Edition date"}</Label>
                    <DatePicker
                      className="datepicker-here form-control"
                      // dateFormat="MMMM d, yyyy"
                      selected={startDate}
                      onChange={handleStartDate}
                      startOpen={openDate}
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col sm="3">
                  <FormGroup>
                    <Label>{"Last Edition date"}</Label>
                    <InputGroup>
                      <Input
                        name="dateAdd"
                        maxLength={20}
                        className="form-control"
                        onClick={handleStartDate2}
                        onChange={handleStartDate2}
                        onFocus={handleStartDate2}
                        value={""}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              )}

              <Col sm="3">
                <FormGroup>
                  <Label>{"Producer Document Code"}</Label>
                  <InputGroup>
                    <Input
                      name="producerDocumentCode"
                      maxLength={255}
                      className="form-control"
                      value={obj.producerDocumentCode || ""}
                      onChange={onObjChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"User Document Code"}</Label>
                  <InputGroup>
                    <Input
                      name="userDocCode"
                      maxLength={255}
                      className="form-control"
                      value={obj.userDocCode || ""}
                      onChange={onObjChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Document Title"}</Label>
                  <InputGroup>
                    <Input
                      name="docTitle"
                      maxLength={255}
                      className="form-control"
                      value={obj.docTitle || ""}
                      onChange={onObjChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Doc.Type"}
                  </Label>
                  <label className="txt-danger Required">{"*"}</label>
                  {ListDocType != null && ListDocType != "" ? (
                    <Input
                      className="form-control"
                      value={DocTypeName}
                      onClick={() => {
                        setmodalDocType(true);
                      }}
                      readOnly={true}
                    />
                  ) : (
                    <Input
                      className="form-control"
                      value={""}
                      onClick={() => {
                        toast.info("List Is Empty");
                      }}
                      readOnly={true}
                    />
                  )}
                </FormGroup>
              </Col>
              {ShowFilling == true ? (
                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">
                      {"Filling"}
                    </Label>
                    <label className="txt-danger Required">{"*"}</label>
                    {ListFilling != null && ListFilling != "" ? (
                      <Input
                        className="form-control"
                        value={FillingName}
                        onClick={() => {
                          setmodalFilling(true);
                        }}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        className="form-control"
                        value={""}
                        onClick={() => {
                          toast.info("List Is Empty");
                        }}
                        readOnly={true}
                      />
                    )}
                  </FormGroup>
                </Col>
              ) : null}
              {(ListPhase != null && ListPhase != "" && ShowFilling == false) ||
              (ShowFilling == false && PhaseName != "") ? (
                <Col sm="2">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">{"Phase"}</Label>

                    <Input
                      className="form-control"
                      value={PhaseName}
                      onClick={() => {
                        setmodalPhase(true);
                      }}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              ) : null}

              {(ListDiscipline != null && ListDiscipline != "") ||
              (ShowFilling == false && DisciplineName != "") ? (
                <Col
                  sm={objSystemCode.doctypeCode.toUpperCase() == "V" ? 12 : 2}
                >
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">
                      {"Discipline"}
                    </Label>

                    <Input
                      className="form-control"
                      value={DisciplineName}
                      onClick={() => {
                        setmodalDiscipline(true);
                      }}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {(ListDocTypeTechnical != null && ListDocTypeTechnical != "") ||
              (ShowFilling == false && DocTypeTechnicalName != "") ? (
                <Col sm="2">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">
                      {"Doc.Type"}
                    </Label>

                    <Input
                      className="form-control"
                      value={DocTypeTechnicalName}
                      onClick={() => {
                        setmodalDocTypeTechnical(true);
                      }}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {(senderrecieverlist != null && senderrecieverlist != "") ||
              senderrecievername != "" ? (
                <Col sm="4">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">{RDName}</Label>
                    <Input
                      className="form-control"
                      value={senderrecievername}
                      onClick={() => {
                        setmodalSenderReciever(true);
                      }}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {ShowLetterPartner ? (
                <Col sm="4">
                  <FormGroup>
                    <Label>{PartnerName}</Label>
                    <InputGroup>
                      <Input
                        name="letterPartner"
                        type="text"
                        maxLength={100}
                        className="form-control"
                        value={obj.letterPartner || ""}
                        onChange={onObjChange}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Archive No."}</Label>
                  <InputGroup>
                    <Input
                      name="archiveNo"
                      maxLength={6}
                      type="number"
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.archiveNo || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Producer Company"}
                  </Label>
                  <Input
                    type="select"
                    name="producerID"
                    className="form-control"
                    value={ProducerID}
                    onChange={(txt) => {
                      onChangeProducer(txt.target.value);
                    }}
                  >
                    <option value={0} key={0}>
                      Select...
                    </option>
                    {ListProducer != null
                      ? ListProducer.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Transmital"}</Label>
                  <InputGroup>
                    <Input
                      name="transmital"
                      maxLength={255}
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.transmital || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>{"Letter"}</Label>
                  <InputGroup>
                    <Input
                      name="letter"
                      maxLength={255}
                      className="form-control"
                      onChange={onObjChange}
                      value={obj.letter || ""}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Priority"}
                  </Label>
                  <label className="txt-danger Required">{"*"}</label>
                  <Input
                    type="select"
                    name="priority"
                    className="form-control"
                    value={obj.priority}
                    onChange={onObjChange}
                  >
                    <option value="All" key="All">
                      All
                    </option>
                    <option value="Top" key="Top">
                      Top
                    </option>
                    <option value="Medium" key="Medium">
                      Medium
                    </option>
                    <option value="Low" key="Low">
                      Low
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Security"}
                  </Label>
                  <label className="txt-danger Required">{"*"}</label>
                  <Input
                    type="select"
                    name="security"
                    className="form-control"
                    value={obj.security}
                    onChange={onObjChange}
                  >
                    <option value="All" key="All">
                      All
                    </option>
                    <option value="TopSecret" key="TopSecret">
                      Top Secret
                    </option>
                    <option value="Medium" key="Medium">
                      Medium
                    </option>
                    <option value="LowSecret" key="LowSecret">
                      Low Secret
                    </option>
                    <option value="NonSecret" key="NonSecret">
                      Non Secret
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Position"}
                  </Label>
                  <label className="txt-danger Required">{"*"}</label>
                  <Input
                    type="select"
                    name="position"
                    className="form-control"
                    value={obj.position}
                    onChange={onObjChange}
                  >
                    <option value="Active" key="Active">
                      Active
                    </option>
                    <option value="Inactive" key="Inactive">
                      Inactive
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Confrim Document"}
                  </Label>
                  <label className="txt-danger Required">{"*"}</label>
                  <Input
                    type="select"
                    name="confrim"
                    className="form-control"
                    value={obj.confrim}
                    onChange={onObjChange}
                  >
                    <option value="Yes" key="Yes">
                      Yes
                    </option>
                    <option value="No" key="No">
                      No
                    </option>
                    <option value="Waiting" key="Waiting">
                      Waiting
                    </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "m-r-15",
                      type: "button",
                      disabled: LoadingBtn,
                      onClick: () => Search(),
                    }}
                  >
                    {Loading ? (
                      <div className="loader-box2">
                        <Spinner attrSpinner={{ className: "loader-7" }} />
                      </div>
                    ) : (
                      "Search"
                    )}
                  </Btn>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        {tableData != null && tableData != "" ? (
          <DocTableList
            showPartner={showPartner}
            objShowBtnAction={objShowBtnAction}
            tableData={tableData}
            contractsID={contractsID}
            ViewFileBA={ViewFileBA}
            documentType={documentType}
            titlepage="Documents Reports"
          />
        ) : null}
      </Container>

      <Modal isOpen={modalDocType}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemDocType}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocType}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDocType(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFilling}>
        <ModalHeader>
          Doc.Type: {DocTypeName}+<br></br>Filling:{FillingName}
        </ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemFilling}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListFilling}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFilling(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalUnit}>
        <ModalHeader>Unit</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  selectRow={true}
                  onRenderItem={onRenderItem}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListUnit}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {obj.unitID != null && obj.unitID != 0 ? (
            <Button
              color="danger"
              onClick={() => {
                setobj({
                  ...obj,
                  unitID: null,
                });
                setmodalUnit(false);

                setUnitName("");
              }}
            >
              Unselect Unit
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodalUnit(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalSenderReciever}>
        <ModalHeader>Select {RDName}</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemSenderReciever}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={senderrecieverlist}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalSenderReciever(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDiscipline}>
        <ModalHeader>Discipline</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDiscipline}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDiscipline}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDiscipline(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalPhase}>
        <ModalHeader>Phase</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemPhase}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListPhase}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalPhase(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDocTypeTechnical}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDocTypeTechnical}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocTypeTechnical}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDocTypeTechnical(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DocSearch;
