import React, { useMemo, useRef, useState, useEffect, Fragment, useContext } from "react";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_ProjectsList } from "../../../api/Admin/Projects";
import { Api_PlantsList } from "../../../api/Admin/Plants";
import {
  Api_FillingMainStructuresListCompany,
  Api_GetFillingMainStructures,
  Api_EditFillingMainStructures,
  Api_AddFillingMainStructures,
  Api_DeleteFillingMainStructures,
  Api_FillingMainStructuresListCompany_Parent,
} from "../../../api/Admin/Filling";

import { Btn, Spinner } from "../../../AbstractElements";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import DeniReactTreeView from "deni-react-treeview";
import MaterialReactTable from "material-react-table";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const FillingMainStructure = () => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [Loading, setLoading] = useState(false);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [ParentCategoryId, setParentCategoryId] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalparent, setmodalparent] = useState(false);
  const [parentName, setparentName] = useState("");
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [ListParent, setListParent] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
    companyID: DefaultCompanyID,
    projectID: null,
    plantID: null,
    remarks: "",
    sort: 0,
    security: "LowSecret",
    parentCategoryId: null,
    hasVerCode: "Yes",
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);

  useEffect(() => {
    LoadCompanyList();
    onChangeCompany(obj.companyID);
  }, []);

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const LoadFillingCompany = async (id) => {
    try {
      setLoading(true);
      const response = await Api_FillingMainStructuresListCompany(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setTableData(response.data.data);
        setsumRows(response.data.data.length);
        const response2 = await Api_FillingMainStructuresListCompany_Parent(id);
        setListParent(response2.data.data);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const onChangeCompany = (val) => {
    setParentCategoryId(0);
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
        parentCategoryId: null,
      });

      LoadFillingCompany(val);
    } else {
      setobj({
        ...obj,
        companyID: null,
        projectID: null,
        parentCategoryId: null,
      });

      setTableData(null);
    }
  };
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddFillingMainStructures(obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("A new Filing Structure has been added");

          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_EditFillingMainStructures(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setLoadingBtn(false);
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const DeleteContratcs = async (id) => {
    try {
      setLoading(true);
      const response = await Api_DeleteFillingMainStructures(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Filing Structure Is Removed");

        ResetForm();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setModal(false);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetFillingMainStructures(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);

        if (response.data.data.parentCategoryId == null) {
          setParentCategoryId(0);
          setparentName("");
        } else {
          setParentCategoryId(response.data.data.parentCategoryId);
          setparentName(response.data.data.parentCategoryDescription);
        }
        setobj({
          ...obj,
          description: response.data.data.description,
          code: response.data.data.code,
          remarks: response.data.data.remarks,
          companyID: response.data.data.companyID,
          sort: response.data.data.sort,
          id: response.data.data.id,
          plantID: response.data.data.plantID,
          ProjectID: response.data.data.projectID,
          parentCategoryId: response.data.data.parentCategoryId,
          hasVerCode: response.data.data.hasVerCode,
          security: response.data.data.security,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (err) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      id: 0,
      description: "",
      code: "",
      remarks: "",
      sort: 0,
    });
    setSelectedRowID(0);
    LoadFillingCompany(obj.companyID);
    setParentCategoryId(0);
  };
  const themes = ["classic"];

  const onSelectItem = async (item) => {
    try {
      console.log(item);
      setparentName(item.text);
      setParentCategoryId(item.id);
      setobj({
        ...obj,
        parentCategoryId: item.id,
      });
      setmodalparent(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onRemoveParent = async () => {
    setparentName("");
    setParentCategoryId(0);
    setobj({
      ...obj,
      parentCategoryId: null,
    });
    setmodalparent(false);
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Description: item.Description,
      Code: item.Code,
      Remarks: item.Remarks,
    }));
    exportToExcel(customHeadings, "Filing Structure");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.Description, item.Code, item.Remarks]);
    autoTable(doc, {
      head: [["Row Number", "Description", "Code", "Remarks"]],
      body: customRows,
    });
    doc.text("Filing Structure", 14, 10);
    doc.save("Filing Structure.pdf");
  };
  const columns = useMemo(
    () => [
      {
        header: "Description",
        accessorKey: "Description",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.depth == 1 ? (
              <span style={{ fontWeight: "bold" }}>{cell.row.original.Description}</span>
            ) : cell.row.original.depth == 2 ? (
              <span style={{ color: "#16449D", marginLeft: 15, display: "block" }}>{cell.row.original.Description}</span>
            ) : cell.row.original.depth == 3 ? (
              <span style={{ marginLeft: 25, display: "block" }}>{cell.row.original.Description}</span>
            ) : null}
          </>
        ),
      },
      {
        header: "Code",
        accessorKey: "Code",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.depth == 1 ? (
              <span style={{ fontWeight: "bold" }}>{cell.row.original.Code}</span>
            ) : cell.row.original.depth == 2 ? (
              <span style={{ color: "#16449D", marginLeft: 15, display: "block" }}>{cell.row.original.Code}</span>
            ) : cell.row.original.depth == 3 ? (
              <span style={{ marginLeft: 25, display: "block" }}>{cell.row.original.Code}</span>
            ) : null}
          </>
        ),
      },

      {
        header: "Remarks",
        accessorKey: "Remarks",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.depth == 1 ? (
              <span style={{ fontWeight: "bold" }}>{cell.row.original.Remarks}</span>
            ) : cell.row.original.depth == 2 ? (
              <span style={{ color: "#16449D", marginLeft: 15, display: "block" }}>{cell.row.original.Remarks}</span>
            ) : cell.row.original.depth == 3 ? (
              <span style={{ marginLeft: 25, display: "block" }}>{cell.row.original.Remarks}</span>
            ) : null}
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs title={"Filing Structure"} parent="Basic Data Management" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="companyID"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {obj.companyID != 0 ? (
                    <>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input
                                name="description"
                                autoComplete="off"
                                className="form-control"
                                value={obj.description}
                                onChange={onInputChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Parent"}</Label>
                            {ListParent != null ? (
                              <Input
                                className="form-control"
                                value={parentName}
                                onClick={() => {
                                  setmodalparent(true);
                                }}
                                readOnly={true}
                              />
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} autoComplete="off" />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input
                                name="sort"
                                autoComplete="off"
                                type="number"
                                className="form-control"
                                value={obj.sort}
                                onChange={onInputChange}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="8">
                          <FormGroup>
                            <Label>{"Remarks"}</Label>
                            <InputGroup>
                              <Input name="remarks" autoComplete="off" className="form-control" value={obj.remarks} onChange={onInputChange} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Security"}</Label>
                            <label className="txt-danger Required">{"*"}</label>
                            <Input type="select" name="security" className="form-control" value={obj.security} onChange={onInputChange}>
                              <option value="TopSecret" key="TopSecret">
                                Top Secret
                              </option>
                              <option value="Medium" key="Medium">
                                Medium
                              </option>
                              <option value="LowSecret" key="LowSecret">
                                Low Secret
                              </option>
                              <option value="NonSecret" key="NonSecret">
                                Non Secret
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Version Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <Input type="select" name="hasVerCode" className="form-control" value={obj.hasVerCode} onChange={onInputChange}>
                              <option value="Yes" key="Yes">
                                Yes
                              </option>
                              <option value="No" key="No">
                                No
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          {SelectedRowID == 0 ? (
                            <Button type="submit" className="btn btn-success btn-md">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Add"
                              )}
                            </Button>
                          ) : (
                            <>
                              <Button type="submit" className="btn btn-success btn-md m-r-15">
                                {LoadingBtn ? (
                                  <div className="loader-box2">
                                    <Spinner attrSpinner={{ className: "loader-7" }} />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                              {LoadingBtn == false ? (
                                <Btn
                                  attrBtn={{
                                    color: "light",
                                    type: "button",
                                    onClick: () => ResetForm(),
                                  }}
                                >
                                  Cancel
                                </Btn>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null && tableData != "" ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: (originalRow) => ({
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            //  backgroundColor: "red",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        }),
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.Id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      // initialState={{ expanded: true }}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteContratcs(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalparent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListParent}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {ParentCategoryId > 0 ? (
            <Button color="danger" onClick={() => onRemoveParent()}>
              Remove Parent
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodalparent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default FillingMainStructure;
