import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Alerts, Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";

import MaterialReactTable from "material-react-table";

import MemberTypeContext from "../../../_helper/MemberType/index";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const ContractTableList = ({ tableData, titlepage }) => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const { ParamCompanyID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [DocumentName, setDocumentName] = useState("");
  const [modal, setModal] = useState(false);
  const [modalFile, setmodalFile] = useState(false);
  const [CompanyID, setCompanyID] = useState(DefaultCompanyID);
  const [ListCompany, setListCompany] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [ProjectID, setProjectID] = useState(0);
  const [PlantID, setPlantID] = useState(0);
  const [BAmod, setBAmod] = useState("0");
  const [Kind, setKind] = useState("0");
  const [modalConfrim, setmodalConfrim] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [objConfrim, setobjConfrim] = useState({
    id: "",
    confrim: "Waiting",
    docLogType: "Waiting",
    description: "",
  });
  const tableInstanceRef = useRef(null);

  const scollToRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setsumRows(tableData.length);
        scollToRef.current.scrollIntoView();
      } catch (err) {}
    };

    fetchData();
  }, [tableData]);

  const toggle = () => setModal(!modal);
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      ContractsType: item.contractsDescription,
      Kind: item.contractKindType,
      ContractTitle: item.contractTitle,
      SystemCode: item.systemCode,
    }));
    exportToExcel(customHeadings, titlepage);
  };
  const handleExportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
    });
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.contractsDescription, item.contractKindType, item.contractTitle, item.systemCode]);
    autoTable(doc, {
      head: [["Row Number", "Contracts Type", "Kind", "Contract Title", "SystemCode"]],
      body: customRows,
    });
    doc.text(titlepage, 14, 10);
    doc.save(titlepage + ".pdf");
  };
  const columns = useMemo(() => [
    {
      header: "Contracts Type",
      accessorKey: "contractsDescription",
    },
    {
      accessorKey: "contractKindType",
      header: "Kind",
    },
    {
      accessorKey: "contractTitle",
      header: "Contract Title",
    },
    // {
    //   header: "System Code",
    //   Cell: ({ cell }) =>
    //     cell.row.original.confrim == "Yes" ? <span>{cell.row.original.systemCode}</span> : <span>{cell.row.original.systemCode}</span>,
    // },
    {
      header: "System Code",
      accessorKey: "systemCode",

      Cell: ({ cell }) =>
        cell.row.original.confrim == "Yes" && cell.row.original.ammendments != null ? (
          <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.ammendments}</span>
        ) : cell.row.original.confrim == "Yes" && cell.row.original.ammendments == null ? (
          <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
        ) : (
          <span>{cell.row.original.systemCode}</span>
        ),
    },
    {
      header: "Detail Sheet",
      enableGlobalFilter: false,
      enableColumnDragging: false,
      enableExpanding: false,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <>
          <span>
            <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Contracts/edit/${cell.row.id}`)}>
              D
            </Button>
          </span>
        </>
      ),
    },
  ]);
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {tableData != "" && tableData != null ? (
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <div className="lbl-sum-Table">
                          <Row>
                            <Col md="6">
                              <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                            </Col>
                            <Col md="6 text-right" className="export-iconList">
                              <i
                                onClick={() => {
                                  handleExportExcel();
                                }}
                                className="fa fa-file-excel-o"
                              ></i>
                              <i
                                onClick={() => {
                                  handleExportPdf();
                                }}
                                className="fa fa-file-pdf-o"
                              ></i>
                            </Col>
                          </Row>
                        </div>
                        <MaterialReactTable
                          enableStickyHeader
                          muiTableContainerProps={{
                            sx: { maxHeight: "350px" },
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              color: "#fff",
                              borderRadius: "0",
                              border: "0",
                              "& tbody tr:nth-of-type(odd)": {
                                backgroundColor: "#f5f5f5",
                              },
                              "& thead tr": {
                                backgroundColor: "#16449D",
                                color: "#fff",
                              },
                            },
                          }}
                          muiTableHeadCellProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellDragHandleProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellColumnActionsButtonProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellFilterTextFieldProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          getRowId={(originalRow) => originalRow.id}
                          columns={columns}
                          data={tableData}
                          enableColumnOrdering
                          enableExpanding
                          enableColumnDragging={false}
                          enablePagination={false}
                          onRowSelectionChange={setRowSelection}
                          state={{ rowSelection }}
                          enableRowNumbers
                          tableInstanceRef={tableInstanceRef}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : Loading == false ? (
                <Alerts attrAlert={{ color: "danger" }}>{"Empty List"}</Alerts>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default ContractTableList;
