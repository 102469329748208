import React, { Fragment } from "react";

import { Col, Row, Label, FormGroup } from "reactstrap";
const DocCodeShow = ({ objSystemCode }) => {
  return (
    <Fragment>
      <Col sm="4">
        <FormGroup>
          <Label>{"System Code"}</Label>

          <FormGroup className="col-xxl-4 mb-3 d-flex">
            {objSystemCode.companyCode != "" ? <label className="col-form-label lbl-syscode companyCode">{objSystemCode.companyCode}</label> : null}
            {objSystemCode.projectCode != "" && objSystemCode.projectCode != null ? (
              <label className="col-form-label lbl-syscode projectCode">{objSystemCode.projectCode}</label>
            ) : null}
            {objSystemCode.plantCode != "" && objSystemCode.plantCode != null ? (
              <label className="col-form-label lbl-syscode plantCode">{objSystemCode.plantCode}</label>
            ) : null}
            {objSystemCode.characterCode != "" && objSystemCode.characterCode != null ? (
              <label className="col-form-label lbl-syscode characterCode">{objSystemCode.characterCode}</label>
            ) : null}
            {objSystemCode.bidAucationCode != "" && objSystemCode.bidAucationCode != null ? (
              <label className="col-form-label lbl-syscode bidAuctionCode">{objSystemCode.bidAucationCode}</label>
            ) : null}

            {objSystemCode.kindCode != "" && objSystemCode.kindCode != null ? (
              <label className="col-form-label lbl-syscode kindCode">{objSystemCode.kindCode}</label>
            ) : null}
            {objSystemCode.yearCode != "" && objSystemCode.yearCode != null ? (
              <label className="col-form-label lbl-syscode yearCode">{objSystemCode.yearCode}</label>
            ) : null}
          </FormGroup>
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default DocCodeShow;
