import React, { useEffect, Fragment, useMemo, useRef, useContext, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Message from "./Message";
import MailSidebar from "./MailSidebar";
import { Breadcrumbs } from "../../../AbstractElements";
import { useParams } from "react-router-dom";

const MessageInfo = () => {
  const { Mod } = useParams();
  const [Loading, setLoading] = useState(false);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Service Management" title="Message" Loading={Loading} />
        <div className="email-wrap">
          <Row>
            <Col xl="2" md="6" className="xl-20 box-col-2">
              <MailSidebar />
            </Col>
            <Col xl="10" md="12" className="xl-80 box-col-10">
              <Message />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default MessageInfo;
