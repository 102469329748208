import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import useState from "react-usestateref";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { Ribbon, Alerts } from "../../../AbstractElements";
import { Api_MeetingPlansGetForDashboard } from "../../../api/Admin/MeetingPlans";
import MaterialReactTable from "material-react-table";
const MeetingPlans = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState(null);
  const [Loading, setLoading] = useState(false);
  const tableInstanceRef = useRef(null);
  const [rowSelection, setRowSelection] = useState({});
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = async (btnClick) => {
    try {
      setLoading(true);
      const response = await Api_MeetingPlansGetForDashboard();

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const columnsInbox = useMemo(() => [
    {
      header: "Title",
      enableHiding: false,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <>
          <Link className="show" to={`${process.env.PUBLIC_URL}/admin/MeetingPlans/Show/${cell.row.original.id}`}>
            <span>{cell.row.original.title}</span>
          </Link>
        </>
      ),
    },
  ]);

  if (Loading == false) {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Ribbon attrRibbons={{ className: "ribbon ribbon ribbon-clip ribbon-secondary" }}>
                <Link to={`${process.env.PUBLIC_URL}/admin/MeetingPlans`}>Meeting Plans</Link>
              </Ribbon>
            </CardHeader>
            <CardBody>
              {tableData != null && tableData != "" ? (
                <div className="activity-media">
                  {tableData != null ? (
                    <div className="activity-media">
                      {tableData != null ? (
                        <Row>
                          <Col sm="12 hideToolbar">
                            <MaterialReactTable
                              enableStickyHeader
                              muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                              muiTablePaperProps={{
                                elevation: 0,
                                sx: {
                                  color: "#fff",
                                  borderRadius: "0",
                                  border: "0",
                                  "& tbody tr:nth-of-type(odd)": {
                                    backgroundColor: "#f5f5f5",
                                  },
                                  "& thead tr": {
                                    backgroundColor: "#16449D",
                                    color: "#fff",
                                  },
                                },
                              }}
                              muiTableHeadCellProps={{
                                sx: (theme) => ({
                                  color: "#fff",
                                }),
                              }}
                              muiTableBodyCellProps={{
                                sx: (theme) => ({
                                  fontSize: ".7rem",
                                }),
                              }}
                              muiTableHeadCellDragHandleProps={{
                                sx: (theme) => ({
                                  color: "#fff",
                                }),
                              }}
                              muiTableHeadCellColumnActionsButtonProps={{
                                sx: (theme) => ({
                                  color: "#fff",
                                }),
                              }}
                              muiTableHeadCellFilterTextFieldProps={{
                                sx: (theme) => ({
                                  color: "#fff",
                                }),
                              }}
                              getRowId={(originalRow) => originalRow.id}
                              columns={columnsInbox}
                              data={tableData}
                              enableFilters={false}
                              enableColumnOrdering
                              enableDensityToggle={false}
                              onIsFullScreenChange={false}
                              enableFullScreenToggle={false}
                              enableExpanding={false}
                              enablePagination={false}
                              enableBottomToolbar={false}
                              enableGlobalFilter={false}
                              enableColumnResizing={false}
                              enableColumnDragging={false}
                              onRowSelectionChange={setRowSelection}
                              state={{ rowSelection }}
                              tableInstanceRef={tableInstanceRef}
                              rowNumberMode="static"
                              enableToolbarInternalActions={false}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Alerts attrAlert={{ color: "warning dark" }}>{"Empty List"}</Alerts>
                      )}
                    </div>
                  ) : (
                    <Alerts attrAlert={{ color: "warning dark" }}>{"Empty List"}</Alerts>
                  )}
                </div>
              ) : (
                <Alerts attrAlert={{ color: "warning dark" }}>{"Empty List"}</Alerts>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default MeetingPlans;
