import React, { Fragment, useState, useEffect, useContext } from "react";

import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import { DataTypeAccessList } from "../Module/DataTypeAccessList";
import { Api_GetDocumentFiles, Api_GetViewDoucments, Api_DeleteDoucments } from "../../../api/Admin/Document";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
  InputGroupText,
} from "reactstrap";
const DocumentAttach = ({ documentID, disabledbtn, obj, openfrom, fileAttcah }) => {
  const [modalupload, setmodalupload] = useState(false);
  const [modaldelete, setmodaldelete] = useState(false);
  const [HeaderUploader, setHeaderUploader] = useState(false);
  const [DocFileList, setDocFileList] = useState(null);
  const [TransmitalList, setTransmitalList] = useState(null);
  const [LetterList, setLetterList] = useState(null);
  const [RefrencesList, setRefrencesList] = useState(null);
  const [DataTypeUploader, setDataTypeUploader] = useState([]);
  const HandleUpload = (header) => {
    setHeaderUploader(header);
    setmodalupload(true);
  };
  const [obj2, setobj2] = useState({
    id: 0,
    filename: "",
    type: "",
  });
  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Document has been successfully uploaded");
        setmodalupload(false);
        ViewFile();
      } else {
        toast.error("Document could not be loaded");
      }
    }
  };

  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");
    let df = "";
    if (HeaderUploader == "Document File") {
      df = "DocumentFile";
    } else if (HeaderUploader == "Transmital") {
      df = "Transmital";
    } else if (HeaderUploader == "Invoice") {
      df = "Invoice";
    } else if (HeaderUploader == "Refrences") {
      df = "Refrences";
    } else {
      df = "Letter";
    }
    return {
      url: `${URL}${Version}/Document/UploadDocumentFile/${df}?docid=${documentID}`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };
  const SetDataTypeAccess = async () => {
    const _data = await DataTypeAccessList();
    setDataTypeUploader(_data);
  };
  const ViewFile = async () => {
    try {
      const response = await Api_GetDocumentFiles(documentID);
      CreateListDocFile(response.data.data);
      CreateListTransmital(response.data.data);
      CreateListLetter(response.data.data);
      CreateRefrencesList(response.data.data);

      // HanldesetdocumentCopyDto(response.data.data);
    } catch (error) {
      console.log("error:" + error);
    }
  };

  const CreateListDocFile = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "DocumentFile");
      setDocFileList(updatedContact);
    } catch (err) {}
  };
  const CreateListTransmital = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Transmital");
      setTransmitalList(updatedContact);
    } catch (err) {}
  };
  const CreateListLetter = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Letter");
      setLetterList(updatedContact);
    } catch (err) {}
  };
  const CreateRefrencesList = async (tableData) => {
    try {
      const updatedContact = tableData.filter((c) => c.documentType == "Refrences");
      setRefrencesList(updatedContact);
    } catch (err) {}
  };
  useEffect(() => {
    if (documentID != "") {
      ViewFile();
    } else {
    }
    SetDataTypeAccess();
  }, [documentID]);
  const DownloadFile = async (id, documentType, FileName) => {
    const response = await Api_GetViewDoucments(id, documentType);

    if (response.status == "200") {
      fileDownload(response.data, FileName);
      console.log("a");
    } else {
      toast.error("Error");
      console.log("b");
    }
  };
  const DeleteFile = async (id, documentType, FileName) => {
    const response = await Api_DeleteDoucments(id, documentID, documentType);
    console.log(response);
    if (response.status == "200") {
      toast.success("The file was deleted");

      ViewFile();
    } else {
      toast.error("Error");
    }
    setmodaldelete(false);
  };
  const ShowModalDelete = async (id, documentType, FileName) => {
    setobj2({
      ...obj2,
      filename: FileName,
      id: id,
      type: documentType,
    });
    setmodaldelete(true);
  };
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col sm="3">
                  <Button
                    disabled={fileAttcah == false ? true : disabledbtn == true ? true : obj.confrim == "Yes" ? true : false}
                    className={DocFileList != null && DocFileList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                    onClick={() => HandleUpload("Document File")}
                  >
                    Document File
                  </Button>
                  <Row>
                    <Col sm="12">
                      <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                        {DocFileList != null
                          ? DocFileList.map((item) => {
                              return (
                                <li key={item.id} className="list-group-item">
                                  <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm"
                                    onClick={() => {
                                      DownloadFile(item.id, item.documentType, item.fileName);
                                    }}
                                  >
                                    <i className="fa fa-cloud-download txt-primary me-2"></i>
                                    {item.fileName}
                                  </button>
                                  {obj.confrim != "Yes" && openfrom == "doc" ? (
                                    <i
                                      onClick={() => {
                                        ShowModalDelete(item.id, item.documentType, item.fileName);
                                      }}
                                      className="fa fa-trash-o  ml-10 txt-danger me-2"
                                    ></i>
                                  ) : null}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col sm="3">
                  <Button
                    disabled={fileAttcah == false ? true : disabledbtn == true ? true : obj.confrim == "Yes" ? true : false}
                    className={TransmitalList != null && TransmitalList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                    onClick={() => HandleUpload("Transmital")}
                  >
                    Transmital
                  </Button>
                  <Row>
                    <Col sm="12">
                      <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                        {TransmitalList != null
                          ? TransmitalList.map((item) => {
                              return (
                                <li key={item.id} className="list-group-item">
                                  <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm"
                                    onClick={() => {
                                      DownloadFile(item.id, item.documentType, item.fileName);
                                    }}
                                  >
                                    <i className="fa fa-cloud-download txt-primary me-2"></i>
                                    {item.fileName}
                                  </button>

                                  {obj.confrim != "Yes" && openfrom == "doc" ? (
                                    <i
                                      onClick={() => {
                                        ShowModalDelete(item.id, item.documentType, item.fileName);
                                      }}
                                      className="fa fa-trash-o  ml-10 txt-danger me-2"
                                    ></i>
                                  ) : null}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col sm="3">
                  <Button
                    disabled={fileAttcah == false ? true : disabledbtn == true ? true : obj.confrim == "Yes" ? true : false}
                    className={LetterList != null && LetterList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                    onClick={() => HandleUpload("Letter")}
                  >
                    Letter
                  </Button>
                  <Row>
                    <Col sm="12">
                      <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                        {LetterList != null
                          ? LetterList.map((item) => {
                              return (
                                <li key={item.id} className="list-group-item">
                                  <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm"
                                    onClick={() => {
                                      DownloadFile(item.id, item.documentType, item.fileName);
                                    }}
                                  >
                                    <i className="fa fa-cloud-download txt-primary me-2"></i>
                                    {item.fileName}
                                  </button>
                                  {obj.confrim != "Yes" && openfrom == "doc" ? (
                                    <i
                                      onClick={() => {
                                        ShowModalDelete(item.id, item.documentType, item.fileName);
                                      }}
                                      className="fa fa-trash-o  ml-10 txt-danger me-2"
                                    ></i>
                                  ) : null}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col sm="3">
                  <Button
                    disabled={fileAttcah == false ? true : disabledbtn == true ? true : obj.confrim == "Yes" ? true : false}
                    className={RefrencesList != null && RefrencesList != "" ? "btn btn-light btn-md m-r-15" : "btn btn-danger btn-md m-r-15"}
                    onClick={() => HandleUpload("Refrences")}
                  >
                    Refrences
                  </Button>
                  <Row>
                    <Col sm="12">
                      <ul as="ul" className="simple-list list-group" style={{ marginTop: 10 }}>
                        {RefrencesList != null
                          ? RefrencesList.map((item) => {
                              return (
                                <li key={item.id} className="list-group-item">
                                  <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm"
                                    onClick={() => {
                                      DownloadFile(item.id, item.documentType, item.fileName);
                                    }}
                                  >
                                    <i className="fa fa-cloud-download txt-primary me-2"></i>
                                    {item.fileName}
                                  </button>
                                  {obj.confrim != "Yes" && openfrom == "doc" ? (
                                    <i
                                      onClick={() => {
                                        ShowModalDelete(item.id, item.documentType, item.fileName);
                                      }}
                                      className="fa fa-trash-o  ml-10 txt-danger me-2"
                                    ></i>
                                  ) : null}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modalupload}>
        <ModalHeader>
          <Row>
            <Col sm="12">Upload {HeaderUploader}</Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept={DataTypeUploader}
                inputContent="Drop files here or click to upload."
                styles={{
                  dropzone: { width: "100%", height: 50 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setmodalupload(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modaldelete}>
        <ModalBody>
          <Row>
            <Col sm="12">
              <p>Are you sure to delete {obj2.filename}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteFile(obj2.id, obj2.type)}>
            Yes
          </Button>
          <Button
            color="light"
            onClick={() => {
              setmodaldelete(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default DocumentAttach;
