import React, { Fragment, useEffect } from "react";

import { Col, Row, Label, FormGroup, Input, InputGroup } from "reactstrap";
const DocCodeShow = ({ objSystemCode, obj, versionID, strSystemCode, ShowYear, Hanldesetamcode }) => {
  useEffect(() => {
    console.log("objSystemCode a:" + JSON.stringify(objSystemCode));
  }, []);

  if (strSystemCode != "") {
    return (
      <Fragment>
        <Col sm="9">
          <Row>
            <Col sm="8">
              <FormGroup>
                <Label>{"System Code"}</Label>
                <FormGroup className="col-xxl-4 mb-3 d-flex">
                  {strSystemCode.map((item, index) => {
                    return (
                      <label key={index} className="col-form-label lbl-syscode">
                        {item}
                      </label>
                    );
                  })}
                </FormGroup>
              </FormGroup>
            </Col>
            <Col sm="2">
              <FormGroup>
                <Label>{"Serial No."}</Label>
                <InputGroup>
                  <Input className="form-control" value={obj.serialID || ""} readOnly={true} />
                </InputGroup>
              </FormGroup>
            </Col>
            {versionID != "" && obj.ammendments == null ? (
              <Col sm="2">
                <FormGroup>
                  <Label>{"Rev. N0."}</Label>
                  <InputGroup>
                    <Input className="form-control" value={obj.versionID || ""} readOnly={true} />
                  </InputGroup>
                </FormGroup>
              </Col>
            ) : obj.ammendments != "" && obj.ammendments != null ? (
              <Col sm="2">
                <FormGroup>
                  <Label>{"Ammendments"}</Label>
                  <InputGroup>
                    <Input
                      className="form-control"
                      value={obj.ammendments || ""}
                      onChange={(val) => {
                        Hanldesetamcode(val.target.value);
                      }}
                      type="number"
                      maxLength={2}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Fragment>
    );
  } else {
    //Contractors & Vendors
    if (objSystemCode.doctypeCode.toUpperCase() == "V") {
      //Contractors & Vendors
      return (
        <Fragment>
          <Col sm="10">
            <Row>
              <Col sm="8">
                <FormGroup>
                  <Label>{"System Code"}</Label>
                  <FormGroup className="col-xxl-4 mb-3 d-flex">
                    {objSystemCode.companyCode != "" ? <label className="col-form-label lbl-syscode">{objSystemCode.companyCode}</label> : null}
                    {objSystemCode.projectCode != "" && objSystemCode.projectCode != null ? (
                      <label className="col-form-label lbl-syscode projectCode">{objSystemCode.projectCode}</label>
                    ) : null}
                    {objSystemCode.plantCode != "" && objSystemCode.plantCode != null ? (
                      <label className="col-form-label lbl-syscode plantCode">{objSystemCode.plantCode}</label>
                    ) : null}
                    {objSystemCode.doctypeCode != "" && objSystemCode.doctypeCode != null ? (
                      <label className="col-form-label lbl-syscode doctypeCode">{objSystemCode.doctypeCode}</label>
                    ) : null}
                    {objSystemCode.fillingCode != "" && objSystemCode.fillingCode != null ? (
                      <label className="col-form-label lbl-syscode fillingCode">{objSystemCode.fillingCode}</label>
                    ) : null}
                    {objSystemCode.disciplinecode != "" && objSystemCode.disciplinecode != null ? (
                      <label className="col-form-label lbl-syscode disciplinecode">{objSystemCode.disciplinecode}</label>
                    ) : null}
                    {objSystemCode.year != "" && ShowYear == true ? <label className="col-form-label lbl-syscode">{objSystemCode.year}</label> : null}
                  </FormGroup>
                </FormGroup>
              </Col>
              {obj.serialID != "" && obj.serialID != 0 ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Serial No."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.serialID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}

              {versionID != "" ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Rev. N0."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.versionID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Fragment>
      );
    }
    //Correspondences
    else if (objSystemCode.doctypeCode.toUpperCase() == "L") {
      //Correspondences
      return (
        <Fragment>
          <Col sm="10">
            <Row>
              <Col sm="8">
                <FormGroup>
                  <Label>{"System Code"}</Label>
                  <FormGroup className="col-xxl-4 mb-3 d-flex">
                    {objSystemCode.companyCode != "" ? <label className="col-form-label lbl-syscode companyCode">{objSystemCode.companyCode}</label> : null}
                    {objSystemCode.projectCode && objSystemCode.projectCode != "" ? <label className="col-form-label lbl-syscode projectCode">{objSystemCode.projectCode}</label> : null}
                    {objSystemCode.plantCode && objSystemCode.plantCode !== "" ? <label className="col-form-label lbl-syscode plantCode">{objSystemCode.plantCode}</label> : null}
                    {objSystemCode.doctypeCode && objSystemCode.doctypeCode != "" ? <label className="col-form-label lbl-syscode doctypeCode">{objSystemCode.doctypeCode}</label> : null}
                    {objSystemCode.fillingCode && objSystemCode.fillingCode != "" ? <label className="col-form-label lbl-syscode fillingCode">{objSystemCode.fillingCode}</label> : null}
                    {objSystemCode.rd && objSystemCode.rd != "" ? <label className="col-form-label lbl-syscode rd">{objSystemCode.rd}</label> : null}
                    {objSystemCode.producerode && objSystemCode.producerode != "" ? <label className="col-form-label lbl-syscode producerode">{objSystemCode.producerode}</label> : null}
                    {objSystemCode.year  && objSystemCode.year != "" ? <label className="col-form-label lbl-syscode year">{objSystemCode.year}</label> : null}{" "}
                  </FormGroup>
                </FormGroup>
              </Col>
              {obj.serialID != "" && obj.serialID != 0 ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Serial No."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.serialID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
              {versionID != "" ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Rev. N0."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.versionID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Fragment>
      );
    }
    //Contracts
    else if (objSystemCode.doctypeCode.toUpperCase() == "C") {
      return (
        <Fragment>
          <Col sm="10">
            <Row>
              <Col sm="8">
                <FormGroup>
                  <Label>{"System Code"}</Label>

                  <FormGroup className="col-xxl-4 mb-3 d-flex">
                    {objSystemCode.companyCode != "" ? (
                      <label className="col-form-label lbl-syscode companyCode">{objSystemCode.companyCode}</label>
                    ) : null}
                    {objSystemCode.projectCode != "" && objSystemCode.projectCode != null ? (
                      <label className="col-form-label lbl-syscode projectCode">{objSystemCode.projectCode}</label>
                    ) : null}
                    {objSystemCode.plantCode != "" && objSystemCode.plantCode != null ? (
                      <label className="col-form-label lbl-syscode plantCode">{objSystemCode.plantCode}</label>
                    ) : null}
                    {objSystemCode.doctypeCode != "" && objSystemCode.doctypeCode != null ? (
                      <label className="col-form-label lbl-syscode doctypeCode">{objSystemCode.doctypeCode}</label>
                    ) : null}
                    {objSystemCode.fillingCode != "" && objSystemCode.fillingCode != null ? (
                      <label className="col-form-label lbl-syscode fillingCode">{objSystemCode.fillingCode}</label>
                    ) : null}
                    {objSystemCode.year != "" && ShowYear == true ? <label className="col-form-label lbl-syscode">{objSystemCode.year}</label> : null}
                  </FormGroup>
                </FormGroup>
              </Col>
              {obj.serialID != "" && obj.serialID != 0 ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Serial No."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.serialID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}

              <Col sm="2">
                <FormGroup>
                  <Label>{"Ammendments"}</Label>
                  <InputGroup>
                    <Input
                      className="form-control"
                      name="versionID"
                      type="number"
                      value={obj.ammendments || ""}
                      maxLength={2}
                      onChange={(val) => {
                        Hanldesetamcode(val.target.value);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Col sm="10">
            <Row>
              <Col sm="8">
                <FormGroup>
                  <Label>{"System Code"}</Label>

                  <FormGroup className="col-xxl-4 mb-3 d-flex">
                    {objSystemCode.companyCode != "" ? (
                      <label className="col-form-label lbl-syscode companyCode">{objSystemCode.companyCode}</label>
                    ) : null}
                    {objSystemCode.projectCode != "" && objSystemCode.projectCode != null ? (
                      <label className="col-form-label lbl-syscode projectCode">{objSystemCode.projectCode}</label>
                    ) : null}
                    {objSystemCode.plantCode != "" && objSystemCode.plantCode != null ? (
                      <label className="col-form-label lbl-syscode plantCode">{objSystemCode.plantCode}</label>
                    ) : null}
                    {objSystemCode.doctypeCode != "" && objSystemCode.doctypeCode != null ? (
                      <label className="col-form-label lbl-syscode doctypeCode">{objSystemCode.doctypeCode}</label>
                    ) : null}

                    {objSystemCode.unitcode != "" && objSystemCode.unitcode != null ? (
                      <label className="col-form-label lbl-syscode unitcode">{objSystemCode.unitcode}</label>
                    ) : null}

                    {objSystemCode.phasecode != "" && objSystemCode.phasecode != null ? (
                      <label className="col-form-label lbl-syscode phasecodes">{objSystemCode.phasecode}</label>
                    ) : null}
                    {objSystemCode.disciplinecode != "" && objSystemCode.disciplinecode != null ? (
                      <label className="col-form-label lbl-syscode disciplinecode">{objSystemCode.disciplinecode}</label>
                    ) : null}
                    {objSystemCode.docTypeTechnicalcode != "" && objSystemCode.docTypeTechnicalcode != null ? (
                      <label className="col-form-label lbl-syscode docTypeTechnicalcode">{objSystemCode.docTypeTechnicalcode}</label>
                    ) : null}

                    {objSystemCode.fillingCode != "" && objSystemCode.fillingCode != null ? (
                      <label className="col-form-label lbl-syscode fillingCode">{objSystemCode.fillingCode}</label>
                    ) : null}
                    {objSystemCode.rd != "" && objSystemCode.rd != null ? (
                      <label className="col-form-label lbl-syscode rd">{objSystemCode.rd}</label>
                    ) : null}
                    {objSystemCode.year != "" && ShowYear == true ? <label className="col-form-label lbl-syscode">{objSystemCode.year}</label> : null}
                  </FormGroup>
                </FormGroup>
              </Col>
              {obj.serialID != "" && obj.serialID != 0 ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Serial No."}</Label>
                    <InputGroup>
                      <Input className="form-control" value={obj.serialID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
              {versionID != "" ? (
                <Col sm="2">
                  <FormGroup>
                    <Label>{"Rev. N0."}</Label>
                    <InputGroup>
                      <Input className="form-control" type="number" value={obj.versionID || ""} readOnly={true} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Fragment>
      );
    }
  }
};
export default DocCodeShow;
