import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MemberTypeContext from "../../../_helper/MemberType/index";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Btn, Spinner, UL, LI } from "../../../AbstractElements";
import { Api_AddMember, Api_GetMember, Api_EditMember, Api_DecList } from "../../../api/Admin/Member";
import { Api_NationalList } from "../../../api/Admin/National";
import { Api_DepartmentList_Parent } from "../../../api/Admin/Department";
import { Api_RoleList } from "../../../api/Admin/Role";
import { Api_JobPositionList } from "../../../api/Admin/JobPosition";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_GetUserAccessSec } from "../../../api/Admin/UserSecurityAccess";
import DeniReactTreeView from "deni-react-treeview";
import { useForm } from "react-hook-form";

const CreateMember = () => {
  const { userID } = useParams();
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [modalRole, setmodalRole] = useState(false);
  const [modaldepartment, setmodaldepartment] = useState(false);
  const [departmentName, setdepartmentName] = useState("");
  const [requiredpass, setrequiredpass] = useState(true);
  const [disable, setdisable] = useState(false);
  const [CompanyList, setCompanyList] = useState([]);
  const [showDescription, setshowDescription] = useState(false);
  const [defaultStatus, setdefaultStatus] = useState(false);
  const [RoleList, setRoleList] = useState([]);
  const [NationalList, setNationalList] = useState([]);
  const [decList, setdecList] = useState([]);
  const [securityList, setsecurityList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [JobPositionList, setJobPositionList] = useState([]);
  const [MemberObj, setMemberObj] = useState({
    name: "",
    family: "",
    contactNo: "",
    departmanetID: 0,
    companyID: DefaultCompanyID,
    jobPositionID: 0,
    status: true,
    email: "",
    corporationType: "Permanent",
    roleName: "",
    code: "",
    gender: "Male",
    nationalID: 0,
    officeTel: "",
    mobile: "",
    description: "",
    securityList: [],
  });
  const onSelectItem = async (item) => {
    try {
      setdepartmentName(item.text);
      setMemberObj({
        ...MemberObj,
        departmanetID: item.id,
      });
      setmodaldepartment(false);
    } catch (error) {}
  };
  const onRemoveParent = async () => {
    setdepartmentName("");

    setMemberObj({
      ...MemberObj,
      departmanetID: null,
    });
    setmodaldepartment(false);
  };
  const toggle = () => setmodalRole(!modalRole);
  const themes = ["classic"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: response1 } = await Api_CompanyListBasic();
        setCompanyList(response1.data);

        LoadDepartment(MemberObj.companyID);

        const { data: response2 } = await Api_JobPositionList();
        setJobPositionList(response2.data);

        const { data: response5 } = await Api_RoleList();
        setRoleList(response5.data);

        const { data: response6 } = await Api_NationalList();
        setNationalList(response6.data);
        setLoading(false);
        if (userID) {
          setdisable(true);
          setrequiredpass(false);

          const response4 = await Api_GetMember(userID);
          AllAccessSec(userID);

          const { data: response } = await Api_GetUserAccessSec(userID);
          setsecurityList(response.data);
          for (const [key, value] of Object.entries(response.data)) {
            if (value.checked == true) {
              selectedOption.push(value);
            }
          }

          if (response4.status && response4.status == 200 && response4.data.isSuccess == true) {
            LoadDepartment(response4.data.data.companyID);
            setMemberObj({
              ...MemberObj,
              gender: response4.data.data.gender,
              name: response4.data.data.name,
              family: response4.data.data.family,
              contactNo: response4.data.data.contactNo,
              departmanetID: response4.data.data.departmanetID,
              nationalID: response4.data.data.nationalID,
              companyID: response4.data.data.companyID,
              jobPositionID: response4.data.data.jobPositionID,
              status: response4.data.data.status,
              email: response4.data.data.email,
              code: response4.data.data.code,
              roleName: response4.data.data.roleName,
              contractType: response4.data.data.contractType,
              mobile: response4.data.data.mobile,
              officeTel: response4.data.data.officeTel,
              description: response4.data.data.description,
              securityList: selectedOption,
            });
            setdefaultStatus(response4.data.data.status);
            if (response4.data.data.description != null && response4.data.data.description != "") {
              setshowDescription(true);
            }
            setdepartmentName(response4.data.data.companyDepartmentDescription);

            const response5 = await Api_DecList(userID);
            if (response5.status && response5.status == 200 && response5.data.isSuccess == true) {
              setdecList(response5.data.data);
            }
          } else if (response4.data && response4.data.statusCode && response4.data.statusCode == "UnAuthorized") {
            toast.error("You do not have access permission");
            setTimeout(() => {
              navigate(`${process.env.PUBLIC_URL}/admin/User`, { replace: true });
            }, 1000);
          } else if (response4.response && response4.response.data.isSuccess == false) {
            toast.error(response4.response.data.message);
          } else {
            toast.error("Error - 1");
          }
        } else {
          const { data: response5 } = await Api_RoleList();
          setRoleList(response5.data);
          AllAccessSec(0);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const LoadDepartment = async (id) => {
    try {
      setLoading(true);
      const { data: response } = await Api_DepartmentList_Parent(id);
      setDepartmentList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const [selectedOption, setSelectedOption] = useState([]);
  const AllAccessSec = async (id) => {
    try {
      if (id == 0) {
        setLoading(true);
        const { data: response } = await Api_GetUserAccessSec(id);
        setsecurityList(response.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleChange = (selectedOption) => {
    setMemberObj({
      ...MemberObj,
      securityList: selectedOption,
    });
  };
  const onMemberChange = async (event) => {
    setMemberObj({
      ...MemberObj,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "companyID") {
      LoadDepartment(event.target.value);
    }
  };

  const onChangeActive = (val) => {
    if (userID && defaultStatus != val) {
      setshowDescription(true);
    } else {
      setshowDescription(false);
    }
    setMemberObj({
      ...MemberObj,
      status: val,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoading(true);
      setLoadingBtn(true);
      if (userID) {
        const response = await Api_EditMember(userID, MemberObj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("User Edited");
          navigate(`${process.env.PUBLIC_URL}/admin/User/${MemberObj.companyID}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_AddMember(MemberObj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("New User Added");
          navigate(`${process.env.PUBLIC_URL}/admin/User/${MemberObj.companyID}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoading(false);
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          {userID ? (
            <Breadcrumbs parent="Users Management" title="Edit User" Loading={Loading} />
          ) : (
            <Breadcrumbs Loading={Loading} parent="Users Management" title="Add User" />
          )}
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    {(userID && MemberObj.companyID > 0 && MemberObj.departmanetID > 0 && DepartmentList != "") || requiredpass == true ? (
                      <>
                        <Row>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                              {CompanyList != "" ? (
                                <Input
                                  type="select"
                                  name="companyID"
                                  className="form-control digits"
                                  disabled={disable}
                                  defaultValue={MemberObj.companyID}
                                  onChange={onMemberChange}
                                >
                                  {CompanyList.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                              <InputGroup>
                                <Input
                                  name="name"
                                  maxLength={100}
                                  className="form-control"
                                  autoComplete="off"
                                  value={MemberObj.name}
                                  onChange={onMemberChange}
                                  required={true}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Family"}</Label> <label className="txt-danger Required">{"*"}</label>
                              <InputGroup>
                                <Input
                                  name="family"
                                  maxLength={100}
                                  className="form-control"
                                  value={MemberObj.family}
                                  onChange={onMemberChange}
                                  required={true}
                                  autoComplete="off"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Gender"}</Label> <label className="txt-danger Required">{"*"}</label>
                              <Input type="select" name="gender" className="form-control" value={MemberObj.gender} onChange={onMemberChange}>
                                <option value="Male" key="Male">
                                  Male
                                </option>
                                <option value="Female" key="Female">
                                  Female
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Department"}</Label>

                              <Input
                                className="form-control"
                                value={departmentName}
                                onClick={() => {
                                  if (DepartmentList != null && DepartmentList != "") {
                                    setmodaldepartment(true);
                                  }
                                }}
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Job Position"}</Label>
                              <label className="txt-danger Required">{"*"}</label>
                              <Input
                                type="select"
                                name="jobPositionID"
                                className="form-control"
                                value={MemberObj.jobPositionID}
                                onChange={onMemberChange}
                              >
                                <option key={0}>Select...</option>
                                {JobPositionList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Corporation Type"}</Label>{" "}
                              <label className="txt-danger Required">{"*"}</label>
                              <Input
                                type="select"
                                name="corporationType"
                                className="form-control"
                                value={MemberObj.contractType}
                                onChange={onMemberChange}
                              >
                                <option value="Permanent" key="Permanent">
                                  Permanent
                                </option>
                                <option value="Temporary" key="Temporary">
                                  Temporary
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Contact No"}</Label>
                              <InputGroup>
                                <Input
                                  name="contactNo"
                                  maxLength={256}
                                  className="form-control"
                                  autoComplete="off"
                                  value={MemberObj.contactNo || ""}
                                  onChange={onMemberChange}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Office Tel"}</Label>
                              <InputGroup>
                                <Input
                                  name="officeTel"
                                  maxLength={15}
                                  className="form-control"
                                  autoComplete="off"
                                  value={MemberObj.officeTel || ""}
                                  onChange={onMemberChange}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Mobile"}</Label>
                              <InputGroup>
                                <Input
                                  name="mobile"
                                  maxLength={15}
                                  className="form-control"
                                  autoComplete="off"
                                  value={MemberObj.mobile || ""}
                                  onChange={onMemberChange}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"Mail Box"}</Label> <label className="txt-danger Required">{"*"}</label>
                              <InputGroup>
                                <Input
                                  name="email"
                                  maxLength={256}
                                  className="form-control"
                                  value={MemberObj.email || ""}
                                  autoComplete="off"
                                  onChange={onMemberChange}
                                  required={true}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Nationality"}</Label>
                              <label className="txt-danger Required">{"*"}</label>
                              <Input type="select" name="nationalID" className="form-control" value={MemberObj.nationalID} onChange={onMemberChange}>
                                <option key={0}>Select...</option>
                                {NationalList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect9">{"Access Level"}</Label>
                              <label className="txt-danger Required">{"*"}</label>
                              <Input type="select" name="roleName" className="form-control" value={MemberObj.roleName} onChange={onMemberChange}>
                                <option key={0}>Select...</option>
                                {RoleList.map((item) => {
                                  return (
                                    <option key={item.name} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="8">
                            <FormGroup>
                              <Label>{"Security Access"}</Label>
                              {Loading == false ? (
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={handleChange}
                                  options={securityList}
                                  className="js-example-basic-single col-sm-12"
                                  isMulti
                                />
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            sm="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              alignSelf: "center",
                            }}
                          >
                            <FormGroup className="form-group">
                              <div className="checkbox">
                                <Input
                                  id="checkbox1"
                                  type="checkbox"
                                  defaultChecked={MemberObj.status}
                                  onClick={() => onChangeActive(!MemberObj.status)}
                                />
                                <Label className="text-muted" for="checkbox1">
                                  Active
                                </Label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="4">
                            <FormGroup>
                              <Label>{"User Code "}</Label>
                              <InputGroup>
                                <Input
                                  name="code"
                                  autoComplete="off"
                                  maxLength={20}
                                  className="form-control"
                                  value={MemberObj.code || ""}
                                  onChange={onMemberChange}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {showDescription == true ? (
                          <Row>
                            <Col sm="12">
                              <FormGroup>
                                <Label>{"Description"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                <InputGroup>
                                  <Input
                                    name="description"
                                    className="form-control"
                                    maxLength={500}
                                    required
                                    value={MemberObj.description || ""}
                                    onChange={onMemberChange}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null}
                        {decList != "" ? (
                          <CardBody>
                            <UL>
                              <LI attrLI={{ className: "list-group-item-action", tag: "a", href: "#javascript", active: true }}>Log</LI>
                              {decList.map((item) => {
                                return (
                                  <LI key={item.id}>
                                    {item.dateAdd}
                                    <span> || </span>
                                    {item.description}
                                  </LI>
                                );
                              })}
                            </UL>
                          </CardBody>
                        ) : null}
                        <Row>
                          <Col sm="12">
                            <hr />
                            <Button className="btn btn-success btn-md m-r-15">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : userID ? (
                                "Save"
                              ) : (
                                "Add"
                              )}
                            </Button>
                            {userID ? (
                              <Btn
                                attrBtn={{
                                  color: "light",
                                  type: "button",
                                  // onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/User/${MemberObj.companyID}`),
                                  onClick: () => console.log(selectedOption),
                                }}
                              >
                                Cancel
                              </Btn>
                            ) : null}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modaldepartment}>
        <ModalHeader>Department</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={DepartmentList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {MemberObj.departmanetID != null && MemberObj.departmanetID ? (
            <Button color="danger" onClick={() => onRemoveParent()}>
              Remove Department
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodaldepartment(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CreateMember;
