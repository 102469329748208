import React, { useEffect, useState, Fragment, useMemo, useRef } from "react";
import { Api_CompanyListAll, Api_DeleteCompany } from "../../../api/Admin/Company";
import { Btn, H6, Image } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import { Api_GethasTopChart, Api_GetViewTopChart } from "../../../api/Admin/Document";
import MaterialReactTable from "material-react-table";
const ListCompany = () => {
  const navigate = useNavigate();
  const [SelectedCompanyID, setSelectedCompanyID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [CompanyName, setCompanyName] = useState("");
  const [FileID, setFileID] = useState("");
  const [fileext, setfileext] = useState("");
  const [modal, setModal] = useState(false);
  const [modalupload, setmodalupload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListAll();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setTableData(response.data.data);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
        setTimeout(() => {
          navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
        }, 1000);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  const DeleteMember = async (SelectedCompanyID) => {
    try {
      const response = await Api_DeleteCompany(SelectedCompanyID);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Company Is Removed");
        removeFromList(SelectedCompanyID);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };

  const removeFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const toggle = () => setModal(!modal);

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Top Chart has been successfully uploaded");
        setmodalupload(false);
      } else {
        toast.error("Top Chart could not be loaded");
      }
    }
  };

  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");

    return {
      url: `${URL}${Version}/Document/UploadTopChart/${SelectedCompanyID}`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };

  const ViewFile = async (id) => {
    const response = await Api_GethasTopChart(id);
    if (response.status == "200" && response.data.statusCode == "Success") {
      console.log(response.data.data.id);
      console.log(response.data.data.fileExt);
      setFileID(response.data.data.id);
      setfileext(response.data.data.fileExt);
    } else if (response.status == "200" && response.data.statusCode == "NotFound") {
      setFileID("");
      setfileext("");
      setCompanyName("");
    } else {
      setFileID("");
      setfileext("");
      setCompanyName("");
    }
    console.log(SelectedCompanyID);
    console.log(response.status);
    console.log(JSON.stringify(response));
  };

  const DownloadFile = async () => {
    const response = await Api_GetViewTopChart(SelectedCompanyID);
    console.log(response);

    fileDownload(response.data, CompanyName + fileext);
    console.log("res 2:" + JSON.stringify(response));
    if (response.status == "200") {
    } else {
      setFileID("");
      setfileext("");
      setCompanyName("");
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        header: "Top Chart",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <i
            className="fa fa-file-image-o"
            onClick={() => {
              setCompanyName(cell.row.original.name);
              setmodalupload(true);
              ViewFile(cell.row.id);
              setSelectedCompanyID(cell.row.id);
            }}
          ></i>
        ),
      },
      {
        accessorKey: "webSite",
        header: "Web Site",
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
      },
      {
        accessorKey: "active",
        header: "Position",
        Cell: ({ cell }) =>
          cell.row.original.active == true ? <span className="txt-success">Active</span> : <span className="txt-danger">Inactive</span>,
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Company/edit/${cell.row.id}`)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedCompanyID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Basic Data Management" title="Companies List" Loading={Loading} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <MaterialReactTable
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                    muiTablePaperProps={{
                      elevation: 0,
                      sx: {
                        color: "#fff",
                        borderRadius: "0",
                        border: "0",
                        "& tbody tr:nth-of-type(odd)": {
                          backgroundColor: "#f5f5f5",
                        },
                        "& thead tr": {
                          backgroundColor: "#16449D",
                          color: "#fff",
                        },
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellDragHandleProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellColumnActionsButtonProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellFilterTextFieldProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    getRowId={(originalRow) => originalRow.id}
                    columns={columns}
                    data={tableData}
                    enableColumnOrdering
                    enableExpanding
                    enablePagination={false}
                    onRowSelectionChange={setRowSelection}
                    state={{ rowSelection }}
                    tableInstanceRef={tableInstanceRef}
                    enableRowNumbers
                    rowNumberMode="static"
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteMember(SelectedCompanyID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalupload}>
        {FileID != "" ? (
          <ModalHeader>
            <Row>
              <Col sm="12">
                <Button color="primary" onClick={DownloadFile}>
                  Download {CompanyName} Top Chart
                </Button>
              </Col>
            </Row>
          </ModalHeader>
        ) : null}

        <ModalBody>
          <Row>
            <Col sm="12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept=".jpg,.pdf,.jpeg"
                inputContent="Drop files here or click to upload."
                styles={{
                  dropzone: { width: "100%", height: 50 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setmodalupload(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListCompany;
