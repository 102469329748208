import React, { useEffect, Fragment, useContext } from "react";
import { Btn, Spinner, Ribbon } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroupText,
} from "reactstrap";
import { Api_CompanyListBasic, Api_CompanyCode } from "../../../api/Admin/Company";
import { Api_ContractsList, Api_ContractTypeCode } from "../../../api/Admin/ContractsType";
import { Api_ProjectsList, Api_GetProjectCode } from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import { Api_ProducerCompanyList } from "../../../api/Admin/ProducerCompany";
import { Api_CheckAccess } from "../../../api/Admin/Doc";
import { Api_Add, Api_Get, Api_Edit } from "../../../api/Admin/Contracts";
import { Api_CurrencyList, Api_NationalList } from "../../../api/Admin/National";
import { Api_GetUserAccessSecForDoc } from "../../../api/Admin/UserSecurityAccess";
import { Api_VendorsCompanyList } from "../../../api/Admin/VendorsCompany";
import { ContractAttach, DocLog, DocCodeShow } from ".";
import DatePicker from "react-datepicker";
import MemberTypeContext from "../../../_helper/MemberType/index";
const Contract = () => {
  const { contractsID } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [BreadcrumbsTitle, setBreadcrumbsTitle] = useState("");
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [disabledFiled, setdisabledFiled] = useState(false);
  const [securityList, setsecurityList] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(false);
  const [LisCurrency, setLisCurrency] = useState([]);
  const [NationalList, setNationalList] = useState([]);
  const [ContractTypeList, setContractTypeList] = useState([]);
  const [PartnerList, setPartnerList] = useState([]);

  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    contractTypeId: 0,
    contractTitle: "",
    remarks: "",
    currency: "USD $",
    scopeOfWork: "",
    changesWork: "",
    costCenter: "",
    costCenterCode: "",
    estimation: "",
    position: "Active",
    confrim: "Waiting",
    contractStatus: "NotStarted",
    producerID: null,
    producerode: "",
    registerDate: new Date(),
    duration: "",
    lastRenewalDate: "",
    companyCode: "",
    projectCode: "",
    plantCode: "",
    bidAucationCode: "",
    characterCode: "C",
    contractCode: "",
    security: "TopSecret",
    contractKindType: "Public",
    totalContractAmount: "",
    totalPaymentsPrice: "",
    totalPaymentsPercent: "",
    totalAmendmentsAmountPrice: "",
    totalAmendmentsAmountPercent: "",
    totalContractAmendmentsAmount: "",
    lastRenewalDuration: "",
    elapsedTime: "",
    physicalDelay: "",
    physicalProgressActual: "",
    physicalProgressPlan: "",
    partnerID: 0,
    partner: "",
    nationalID: "",
    priority: "Medium",
    yearCode: "",
  });

  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
    fileAttcah: false,
  });
  const themes = ["classic"];
  const [RegisterDate, setRegisterDate] = useState(new Date());
  useEffect(() => {
    const fetchData = async () => {
      try {
        LoadCompanyList();
        LoadCurrencyList();
        LoadNationalList();
        LoadContractTypeList();
        CheckAccessBtn();
        AllAccessSec();
      } catch (err) {}
    };
    if (contractsID) {
      onChangeCompany(obj.companyID, false);
      setBreadcrumbsTitle("Edit Contract");
      GetDataForEdit(contractsID);
    } else {
      onChangeCompany(obj.companyID, true);
      setBreadcrumbsTitle("New Contract");
    }
    fetchData();
  }, []);
  const CheckAccessBtn = async () => {
    try {
      setLoading(true);
      const response = await Api_CheckAccess();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const AllAccessSec = async () => {
    try {
      setLoading(true);
      const response = await Api_GetUserAccessSecForDoc();

      setsecurityList(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("Api_GetUserAccessSecForDoc error:" + error);
      setLoading(false);
    }
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);

      LoadProducerList(obj.companyID);
      setLoading(false);

      LoadPartner(obj.companyID);
    } catch (error) {}
  };
  const LoadContractTypeList = async () => {
    try {
      setLoading(true);
      const response = await Api_ContractsList();
      setContractTypeList(response.data.data);

      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val, IsSetObj) => {
    LoadProjectList(val);
    LoadPlantList(val);
    GetCompanyCode(val, IsSetObj);
    LoadProducerList(val);
  };
  const GetCompanyCode = async (id, IsSetObj) => {
    try {
      if (id > 0 && IsSetObj == true) {
        const response = await Api_CompanyCode(id);
        setobj({
          ...obj,
          companyID: id,
          companyCode: response.data.data.code,
          projectID: 0,
          plantID: 0,
          yearCode: new Date().getFullYear().toString().substring(2, 4),
        });
      }
    } catch (error) {}
  };
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeProject = async (val) => {
    if (val > 0) {
      const response = await Api_GetProjectCode(val);
      setobj({
        ...obj,
        projectID: val,
        projectCode: response.data.data.code,
        plantID: 0,
        plantCode: "",
      });
    } else {
      setobj({
        ...obj,
        projectID: 0,
        plantID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangePlant = async (val) => {
    if (val > 0) {
      const response = await Api_PlantsCode(val);
      setobj({
        ...obj,
        plantID: val,
        plantCode: response.data.data.code,
        projectID: 0,
        projectCode: "",
      });
    } else {
      setobj({
        ...obj,
        plantID: 0,
        projectID: 0,
        plantCode: "",
        projectCode: "",
      });
    }
  };
  const onChangeContractType = async (val) => {
    if (val > 0) {
      const response = await Api_ContractTypeCode(val);
      setobj({
        ...obj,
        contractTypeId: val,
        contractCode: response.data.data.code,
      });
    } else {
      setobj({
        ...obj,
        contractTypeId: 0,
        contractCode: "",
      });
    }
  };
  const handleRegisterDate = (date) => {
    setRegisterDate(date);
    setobj({
      ...obj,
      registerDate: date,
      yearCode: date.getFullYear().toString().substring(2, 4),
    });
  };
  const LoadCurrencyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CurrencyList();

      setLisCurrency(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadNationalList = async () => {
    try {
      setLoading(true);
      const response = await Api_NationalList();
      setNationalList(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async (val) => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(val);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadPartner = async (val) => {
    try {
      setLoading(true);
      const response = await Api_VendorsCompanyList(val);
      setPartnerList(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeKind = (val) => {
    setobj({
      ...obj,
      contractKindType: val,
    });
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const CloseForm = () => {
    navigate(`${process.env.PUBLIC_URL}/admin/Contracts/add`);
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_Get(id);

      if (response.status == "200" && response.data.isSuccess == true) {
        setobj({
          projectID: response.data.data.projectID,
          plantID: response.data.data.plantID,
          companyID: response.data.data.companyID,
          contractTypeId: response.data.data.contractTypeId,
          priority: response.data.data.priority,
          security: response.data.data.security,
          producerID: response.data.data.producerID,
          position: response.data.data.position,
          confrim: response.data.data.confrim,
          contractTitle: response.data.data.contractTitle,
          id: response.data.data.id,
          remarks: response.data.data.remarks,
          currency: response.data.data.currency,
          scopeOfWork: response.data.data.scopeOfWork,
          changesWork: response.data.data.changesWork,
          bidAuctionType: response.data.data.bidAuctionType,
          estimation: response.data.data.estimation,
          contractStatus: response.data.data.contractStatus,
          producerode: response.data.data.producerode,
          registerDate: response.data.data.registerDate,
          bidAucationType: response.data.data.bidAucationType,
          duration: response.data.data.duration,
          companyCode: response.data.data.companyCode,
          projectCode: response.data.data.projectCode,
          yearCode: response.data.data.yearCode,
          plantCode: response.data.data.plantCode,
          bidAucationCode: response.data.data.bidAucationCode,
          ammendments: response.data.data.ammendments,
          characterCode: response.data.data.characterCode,
          contractKindType: response.data.data.contractKindType,
          userCode: response.data.data.userCode,
          costCenterCode: response.data.data.costCenterCode,
          costCenter: response.data.data.costCenter,
          nationalID: response.data.data.nationalID,
          totalContractAmount: response.data.data.totalContractAmount,
          totalPaymentsPrice: response.data.data.totalPaymentsPrice,
          totalPaymentsPercent: response.data.data.totalPaymentsPercent,
          totalAmendmentsAmountPrice: response.data.data.totalAmendmentsAmountPrice,
          totalAmendmentsAmountPercent: response.data.data.totalAmendmentsAmountPercent,
          totalContractAmendmentsAmount: response.data.data.totalContractAmendmentsAmount,
          lastRenewalDuration: response.data.data.lastRenewalDuration,
          physicalDelay: response.data.data.physicalDelay,
          elapsedTime: response.data.data.elapsedTime,
          physicalProgressActual: response.data.data.physicalProgressActual,
          physicalProgressPlan: response.data.data.physicalProgressPlan,
          partnerID: response.data.data.partnerID,
          partner: response.data.data.partner,
          characterCode: response.data.data.characterCode,
        });

        if (response.data.data.registerDate != null) {
          const dateadd = new Date(response.data.data.registerDate.toString().substring(0, 10));
          setRegisterDate(dateadd);
        }

        if (response.data.data.confrim == "Yes") {
          setdisabledFiled(true);
        }
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }

      setLoading(false);
    } catch (err) {
      console.log("err:" + err.message);
    }
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (contractsID) {
        const response = await Api_Edit(contractsID, objRef.current);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Your Contract has been edited");
          navigate(`${process.env.PUBLIC_URL}/admin/Contracts/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        const response = await Api_Add(objRef.current);

        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("New Contract Added");
          navigate(`${process.env.PUBLIC_URL}/admin/Contracts/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  if (Loading == false) {
    return (
      <Fragment>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
          <Container fluid={true}>
            <Breadcrumbs parent="Contract Management" title={BreadcrumbsTitle} Loading={Loading} />
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="companyID"
                            className="form-control"
                            value={obj.companyID}
                            disabled={disabledFiled ? true : false}
                            onChange={(txt) => {
                              onChangeCompany(txt.target.value, true);
                            }}
                          >
                            {ListCompany != null
                              ? ListCompany.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Project"}</Label>
                          <Input
                            type="select"
                            name="projectID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.projectID != null ? obj.projectID : 0}
                            onChange={(txt) => {
                              onChangeProject(txt.target.value);
                            }}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListProject != null
                              ? ListProject.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Plant"}</Label>
                          <Input
                            type="select"
                            name="plantID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.plantID != null ? obj.plantID : 0}
                            onChange={(txt) => {
                              onChangePlant(txt.target.value);
                            }}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListPlant != null
                              ? ListPlant.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Contract Type"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="contractTypeId"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.contractTypeId != null ? obj.contractTypeId : 0}
                            onChange={(txt) => {
                              onChangeContractType(txt.target.value);
                            }}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ContractTypeList != null
                              ? ContractTypeList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.description}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Bid / Auction Kind"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="contractKindType"
                            className="form-control"
                            value={obj.contractKindType}
                            onChange={(txt) => {
                              onChangeKind(txt.target.value);
                            }}
                          >
                            <option value="Public" key="Public">
                              Public
                            </option>
                            <option value="TowStages" key="TowStages">
                              Tow Stages
                            </option>
                            <option value="Limited" key="Limited">
                              Limited
                            </option>
                            <option value="LeavingFormalities" key="LeavingFormalities">
                              Leaving Formalities
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="8"></Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Contract Titile"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input
                              name="contractTitle"
                              maxLength={255}
                              className="form-control"
                              value={obj.contractTitle || ""}
                              onChange={onObjChange}
                              required={true}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Remarks"}</Label>
                          <InputGroup>
                            <Input
                              name="remarks"
                              type="text"
                              maxLength={255}
                              className="form-control"
                              value={obj.remarks || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"ScopeOf Work"}</Label>
                          <InputGroup>
                            <Input
                              name="scopeOfWork"
                              type="textarea"
                              maxLength={4000}
                              className="form-control"
                              value={obj.scopeOfWork || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Changes Work"}</Label>
                          <InputGroup>
                            <Input
                              name="changesWork"
                              type="textarea"
                              maxLength={4000}
                              className="form-control"
                              value={obj.changesWork || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Currency"}</Label>
                          <Input
                            type="select"
                            name="currency"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.currency}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {LisCurrency != null
                              ? LisCurrency.map((item) => {
                                  return (
                                    <option key={item.abbreviation} value={item.abbreviation}>
                                      {item.abbreviation}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Priority"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                            <option value="Top" key="Top">
                              Top
                            </option>
                            <option value="Medium" key="Medium">
                              Medium
                            </option>
                            <option value="Low" key="Low">
                              Low
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Security"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="security" className="form-control" value={obj.security} onChange={onObjChange}>
                            {securityList != null && securityList != ""
                              ? securityList.map((item) => {
                                  return (
                                    <option value={item.value} key={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Estimation"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              id="input-example"
                              name="estimation"
                              className="form-control"
                              placeholder=""
                              value={obj.estimation}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => setobj({ ...obj, estimation: value })}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Contract Amount"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalContractAmount"
                              className="form-control"
                              placeholder=""
                              value={obj.totalContractAmount}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => {
                                if (value != "" && value != 0 && obj.totalPaymentsPrice != "") {
                                  setobj({
                                    ...obj,
                                    totalContractAmount: value,
                                    totalPaymentsPercent: ((100 * obj.totalPaymentsPrice) / value).toFixed(2).replace(/\.0+$/, ""),
                                    totalContractAmendmentsAmount: parseInt(obj.totalAmendmentsAmountPrice) + parseInt(value),
                                  });
                                } else {
                                  setobj({ ...obj, totalContractAmount: value, totalPaymentsPercent: "" });
                                }
                              }}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Payments (so far)"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalPaymentsPrice"
                              className="form-control"
                              placeholder=""
                              value={obj.totalPaymentsPrice}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => {
                                if (value != "" && obj.totalContractAmount != "") {
                                  setobj({
                                    ...obj,
                                    totalPaymentsPrice: value,
                                    totalPaymentsPercent: ((100 * value) / obj.totalContractAmount).toFixed(2).replace(/\.0+$/, ""),
                                  });
                                } else {
                                  setobj({ ...obj, totalPaymentsPrice: value, totalPaymentsPercent: "" });
                                }
                              }}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Payments (so far)"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalPaymentsPercent"
                              className="form-control"
                              placeholder=""
                              readOnly={true}
                              value={obj.totalPaymentsPercent}
                              decimalsLimit={2}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Amendments Amount"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalAmendmentsAmountPrice"
                              className="form-control"
                              placeholder=""
                              value={obj.totalAmendmentsAmountPrice}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => {
                                if (value != "" && obj.totalContractAmount != "") {
                                  setobj({
                                    ...obj,
                                    totalAmendmentsAmountPrice: value,
                                    totalAmendmentsAmountPercent: ((100 * value) / obj.totalContractAmount).toFixed(2).replace(/\.0+$/, ""),
                                    totalContractAmendmentsAmount: parseInt(obj.totalContractAmount) + parseInt(value),
                                  });
                                } else {
                                  setobj({ ...obj, totalAmendmentsAmountPrice: value, totalAmendmentsAmountPercent: "" });
                                }
                              }}
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Amendments Amount"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalAmendmentsAmountPercent"
                              className="form-control"
                              placeholder=""
                              value={obj.totalAmendmentsAmountPercent}
                              decimalsLimit={2}
                              readOnly={true}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Total Contract & Amendments Amount"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="totalContractAmendmentsAmount"
                              className="form-control"
                              placeholder=""
                              value={obj.totalContractAmendmentsAmount}
                              decimalsLimit={2}
                              readOnly={true}
                              // onValueChange={(value, name, values) =>
                              //   setobj({
                              //     ...obj,
                              //     totalContractAmendmentsAmount: value,
                              //   })
                              // }
                            />
                            <InputGroupText>{obj.currency}</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Contract Duration"}</Label>
                          <InputGroup>
                            <Input
                              name="duration"
                              type="number"
                              maxLength={7}
                              className="form-control"
                              value={obj.duration || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                            <InputGroupText>Days</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Last Renewal Duration"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="lastRenewalDuration"
                              className="form-control"
                              placeholder=""
                              value={obj.lastRenewalDuration}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) =>
                                setobj({
                                  ...obj,
                                  lastRenewalDuration: value,
                                })
                              }
                            />
                            <InputGroupText>Days</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Physical Progress Plan (so far)"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="physicalProgressPlan"
                              className="form-control"
                              placeholder=""
                              value={obj.physicalProgressPlan}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => {
                                setobj({
                                  ...obj,
                                  physicalProgressPlan: value,
                                  physicalDelay: obj.physicalProgressActual != "" ? parseInt(obj.physicalProgressActual) - parseInt(value) : "",
                                });
                              }}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Physical Progress Actual (so far)"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="physicalProgressActual"
                              className="form-control"
                              placeholder=""
                              value={obj.physicalProgressActual}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) => {
                                setobj({
                                  ...obj,
                                  physicalProgressActual: value,
                                  physicalDelay: obj.physicalProgressPlan != "" ? parseInt(value) - parseInt(obj.physicalProgressPlan) : "",
                                });
                              }}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Physical Delay (so far)"}</Label>
                          <InputGroup>
                            <Input
                              name="physicalDelay"
                              className="form-control"
                              placeholder=""
                              value={obj.physicalDelay != "" && obj.physicalDelay > 0 ? "No Delay" : obj.physicalDelay}
                              readOnly={true}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Partner"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="partnerID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.partnerID != null ? obj.partnerID : 0}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {PartnerList != null
                              ? PartnerList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Other Partners"}</Label>

                          <InputGroup>
                            <Input
                              name="partner"
                              maxLength={255}
                              className="form-control"
                              value={obj.partner || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Nationality"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="nationalID"
                            disabled={disabledFiled ? true : false}
                            className="form-control"
                            value={obj.nationalID != null ? obj.nationalID : 0}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {NationalList != null
                              ? NationalList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Elapsed Time (so far)"}</Label>
                          <InputGroup>
                            <CurrencyInput
                              name="elapsedTime"
                              className="form-control"
                              placeholder=""
                              value={obj.elapsedTime}
                              decimalsLimit={2}
                              onValueChange={(value, name, values) =>
                                setobj({
                                  ...obj,
                                  elapsedTime: value,
                                })
                              }
                            />
                            <InputGroupText>Days</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Register Date</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <DatePicker
                            className="datepicker-here form-control"
                            dateFormat="MMMM d, yyyy"
                            selected={RegisterDate}
                            onChange={handleRegisterDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Position"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="position" className="form-control" value={obj.position} onChange={onObjChange}>
                            <option value="Active" key="Active">
                              Active
                            </option>
                            <option value="Inactive" key="Inactive">
                              Inactive
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Status"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="contractStatus" className="form-control" value={obj.contractStatus} onChange={onObjChange}>
                            <option value="Negotiations" key="Negotiations">
                              Negotiations
                            </option>
                            <option value="Signed" key="Signed">
                              Signed
                            </option>
                            <option value="NotStarted" key="NotStarted">
                              Not Started
                            </option>
                            <option value="Hold" key="Hold">
                              Hold
                            </option>
                            <option value="InProgress" key="InProgress">
                              In Progress
                            </option>
                            <option value="Finished" key="Finished">
                              Finished
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Producer"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="producerID"
                            className="form-control"
                            value={obj.producerID == null ? 0 : obj.producerID}
                            onChange={onObjChange}
                          >
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {ListProducer != null
                              ? ListProducer.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Producer  Code"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input
                              name="producerode"
                              maxLength={255}
                              className="form-control"
                              value={obj.producerode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <hr></hr>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"User Code"}</Label>
                          <InputGroup>
                            <Input
                              name="userCode"
                              maxLength={100}
                              className="form-control"
                              value={obj.userCode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Cost Center"}</Label>
                          <InputGroup>
                            <Input
                              name="costCenter"
                              maxLength={255}
                              className="form-control"
                              value={obj.costCenter || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Cost Center Code"}</Label>
                          <InputGroup>
                            <Input
                              name="costCenterCode"
                              maxLength={100}
                              className="form-control"
                              value={obj.costCenterCode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Bid , Auction Code"}</Label>
                          <InputGroup>
                            <Input
                              name="bidAucationCode"
                              maxLength={50}
                              className="form-control"
                              value={obj.bidAucationCode || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Ammendments"}</Label>
                          <InputGroup>
                            <Input
                              name="ammendments"
                              maxLength={10}
                              className="form-control"
                              value={obj.ammendments || ""}
                              onChange={onObjChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <DocCodeShow objSystemCode={objRef.current} />
                    </Row>
                    <Row>
                      <Col sm="12">
                        {contractsID && obj.confrim != "Deleted" ? (
                          <Button className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : contractsID ? (
                              "Save"
                            ) : (
                              "Add"
                            )}
                          </Button>
                        ) : (
                          <>
                            <Button className="btn btn-success btn-md m-r-15">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : contractsID ? (
                                "Save"
                              ) : (
                                "Add"
                              )}
                            </Button>
                          </>
                        )}

                        {contractsID ? (
                          <>
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => CloseForm(),
                              }}
                            >
                              Close
                            </Btn>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {contractsID ? (
              <ContractAttach disabledbtn={false} obj={obj} contractsID={contractsID} />
            ) : (
              <ContractAttach disabledbtn={true} obj={obj} contractsID={0} />
            )}
            {contractsID ? <DocLog contractsID={contractsID} /> : null}
          </Container>
        </Form>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default Contract;
