import React, { useEffect, useState, Fragment, useRef, useMemo, useContext } from "react";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import {
  Api_SenderRecieverCode,
  Api_SenderRecieverList,
  Api_GetSenderReciever,
  Api_EditSenderReciever,
  Api_AddSenderReciever,
  Api_DeleteSenderReciever,
  Api_SenderRecieverListParent,
} from "../../../api/Admin/RecieverSender";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const RecieverSender = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
    sort: 0,
    senderRecieverCategory: "Sender",
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadList();
  }, []);

  const LoadList = async () => {
    const response = await Api_SenderRecieverList();

    if (response.data.data != "") {
      setsumRows(response.data.data.length);
      setTableData(response.data.data);
    } else {
      setTableData(null);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setLoadingBtn(true);
    if (SelectedRowID == 0) {
      const response = await Api_AddSenderReciever(obj);
      setLoadingBtn(false);
      if (response.status == "200" && response.data.isSuccess == true) {
        toast.success("A new Item has been added");

        LoadList();
        setobj({
          ...obj,
          description: "",
          code: "",
          sort: 0,
          id: 0,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }
    } else {
      const response = await Api_EditSenderReciever(SelectedRowID, obj);
      console.log(SelectedRowID);
      console.log(obj);
      console.log(response);
      setLoadingBtn(false);
      if (response.status == "200" && response.data.isSuccess == true) {
        toast.success("Information edited successfully");
        setSelectedRowID(0);
        LoadList();
        setobj({
          ...obj,
          description: "",
          code: "",

          id: 0,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
          console.log(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    const response = await Api_DeleteSenderReciever(id);
    setModal(false);
    if (response.status == "200" && response.data.isSuccess == true) {
      toast.success("Item Is Removed");
      RemoveFromList(id);
    } else {
      if (response.response.status == "500") {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error");
      }
    }
  };

  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    } finally {
      setSelectedRowID(0);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetSenderReciever(id);
      setSelectedRowID(id);
      if (response.status == "200" && response.data.isSuccess == true) {
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          id: response.data.data.id,
          sort: response.data.data.sort,
          senderRecieverCategory: response.data.data.senderRecieverCategory,
        });
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }
    } catch (err) {
      console.log(err.message);
      // setLoading(false);
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      id: 0,
      sort: 0,
    });
    setSelectedRowID(0);
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Description: item.description,
      Code: item.code,
      Type: item.senderRecieverCategory,
    }));
    exportToExcel(customHeadings, "Sender & Reciever");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.description, item.code, item.senderRecieverCategory]);
    autoTable(doc, {
      head: [["Row Number", "Description", "Code", "Type"]],
      body: customRows,
    });
    doc.text("Sender & Reciever", 14, 10);
    doc.save("Sender & Reciever.pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        accessorKey: "senderRecieverCategory",
        header: "Type",
      },
      {
        accessorKey: "sort",
        header: "Sort",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Sender & Reciever" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input
                            name="description"
                            autoComplete="off"
                            className="form-control"
                            value={obj.description}
                            onChange={onInputChange}
                            required
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Type"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="senderRecieverCategory"
                          className="form-control"
                          value={obj.senderRecieverCategory}
                          onChange={onInputChange}
                        >
                          <option value="Sender" key="Sender">
                            Sender
                          </option>
                          <option value="Reciever" key="Reciever">
                            Reciever
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} required autoComplete="off" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input
                            name="sort"
                            className="form-control"
                            inputMode="numeric"
                            value={obj.sort}
                            onChange={onInputChange}
                            required
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default RecieverSender;
