import React, { Fragment } from "react";
import { H4, H6, P, UL, H5 } from "../../../AbstractElements";
const FormHeader = (props) => {
  return (
    <Fragment>
      <div>
        <H5>{"In God We Trust"}</H5>
        <H6>{"Welcome to PZ IDMS Software"}</H6>
      </div>
    </Fragment>
  );
};
export default FormHeader;
