import React, { Fragment, useEffect, useState } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from "react-simple-captcha";
const Captcha = () => {
  useEffect(() => {
    const fetchData = async () => {
      loadCaptchaEnginge(6);
    };

    fetchData();
  }, []);
  return <LoadCanvasTemplate reloadText="New Captcha" reloadColor="blue" />;
};

export default Captcha;
