import React, { Fragment, useMemo, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Card, Row, Label, CardBody, Form, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { UL, LI, Btn } from "../../../AbstractElements";
import {
  Api_MessengerList,
  Api_MessengerListSent,
  Api_GetAllByFolder,
  Api_setActionMulti,
  Api_MoveToFloderMulti,
} from "../../../api/Admin/Messenger";
import MaterialReactTable from "material-react-table";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import { Api_MessengerFolderList } from "../../../api/Admin/MessengerFolder";
const MailContain = () => {
  const navigate = useNavigate();
  const { Mod, FolderName } = useParams();
  const [Loading, setLoading] = useState(false);
  const [objaction, setobjaction, objactionRef] = useState({
    ids: [],
  });
  const [tableData, setTableData] = useState(null);
  const [modstr, setmodstr] = useState("s");
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const [modalFolder, setmodalFolder] = useState(false);
  const [folderList, setfolderList] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [obj, setobj] = useState({
    messengerCategory: Mod,
  });
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = async () => {
    try {
      setLoading(true);
      if (!FolderName) {
        if (obj.messengerCategory == "Sent") {
          setmodstr("s");
          const response = await Api_MessengerListSent(obj);

          if (response.status && response.status == 200 && response.data.isSuccess == true) {
            setTableData(response.data.data);
            setsumRows(response.data.data.length);
          } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
            toast.error("You do not have access permission");
          } else if (response.response && response.response.data.isSuccess == false) {
            toast.error(response.response.data.message);
          } else {
            toast.error("Error - 1");
          }
        } else {
          setmodstr("i");
          const response = await Api_MessengerList(obj);

          if (response.status && response.status == 200 && response.data.isSuccess == true) {
            setTableData(response.data.data);
            setsumRows(response.data.data.length);
          } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
            toast.error("You do not have access permission");
          } else if (response.response && response.response.data.isSuccess == false) {
            toast.error(response.response.data.message);
          } else {
            toast.error("Error - 1");
          }
        }
      } else {
        const response = await Api_GetAllByFolder(FolderName);

        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error - 0");
      setLoading(false);
    }
  };
  const LoadFolder = async () => {
    try {
      const response = await Api_MessengerFolderList();

      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setfolderList(response.data.data);
          setmodalFolder(true);
        } else {
          setfolderList(null);
          toast.warn("You have not created a folder yet");
        }
      }
    } catch (error) {}
  };

  const setAction = async (act) => {
    try {
      let selectedRows = [];
      for (const [key, value] of Object.entries(rowSelection)) {
        selectedRows.push(key);
      }
      setobjaction({
        ...objaction,
        ids: selectedRows,
      });

      const response = await Api_setActionMulti(Mod, act, objactionRef.current);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        LoadData();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {}
  };
  const MoveToFolder = async (folderid) => {
    try {
      setLoading(true);
      let selectedRows = [];
      for (const [key, value] of Object.entries(rowSelection)) {
        selectedRows.push(key);
        console.log(key);
      }
      setobjaction({
        ...objaction,
        ids: selectedRows,
      });

      const response = await Api_MoveToFloderMulti(Mod, folderid, objactionRef.current);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setmodalFolder(false);
        toast.success("Moved To Folder");
        LoadData();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Messenger/Inbox`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {}
  };
  const columnsSent = useMemo(() => [
    {
      header: "Subject",
      Cell: ({ cell }) => (
        <>
          <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Message/${modstr}/${cell.row.original.id}`}>
            {cell.row.original.subject}
          </Link>
        </>
      ),
    },
    {
      accessorKey: "dateAdd",
      header: "Date",
      size: 50,
      maxSize: 50,
    },
    {
      header: "Attach",
      enableGlobalFilter: false,
      enableColumnDragging: false,
      enableExpanding: false,
      enableColumnActions: false,
      size: 20,
      Cell: ({ cell }) => (
        <>
          {cell.row.original.isAttach == true ? (
            <span>
              <i className="icon-link"></i>
            </span>
          ) : null}
        </>
      ),
    },
  ]);
  const columnsInbox = useMemo(() => [
    {
      accessorKey: "sender",
      header: "From",
      size: 100,
      maxSize: 100,
    },
    {
      header: "Subject",
      Cell: ({ cell }) => (
        <>
          <Link
            className="show"
            to={`${process.env.PUBLIC_URL}/admin/Message/${cell.row.original.sender == "Me" ? "s" : modstr}/${cell.row.original.messengerID}`}
          >
            {cell.row.original.messengerMessengerCategory == "Reply" ? (
              <span>
                <i className="fa fa-mail-reply"></i> {cell.row.original.messengerSubject}
              </span>
            ) : cell.row.original.messengerMessengerCategory == "Forward" ? (
              <span>
                <i className="fa fa-mail-forward"></i>
                {cell.row.original.messengerSubject}
              </span>
            ) : (
              cell.row.original.messengerSubject
            )}
          </Link>
        </>
      ),
    },

    {
      accessorKey: "messengerDateAdd",
      header: "Date",
      size: 50,
    },
    {
      header: "Attach",
      enableGlobalFilter: false,
      enableColumnDragging: false,
      enableExpanding: false,
      enableColumnActions: false,
      size: 20,
      Cell: ({ cell }) => (
        <>
          {cell.row.original.messengerIsAttach == true ? (
            <span>
              <i className="icon-link"></i>
            </span>
          ) : null}
        </>
      ),
    },
  ]);
  const columnsFolder = useMemo(() => [
    {
      accessorKey: "sender",
      header: "From",
      size: 100,
      maxSize: 100,
    },
    {
      header: "Subject",
      Cell: ({ cell }) => (
        <>
          <Link className="show" to={`${process.env.PUBLIC_URL}/admin/Message/${cell.row.original.modStr}/${cell.row.original.messengerID}`}>
            <span>{cell.row.original.subject}</span>
          </Link>
        </>
      ),
    },

    {
      accessorKey: "dateAdd",
      header: "Date",
      size: 50,
    },
    {
      header: "Attach",
      enableGlobalFilter: false,
      enableColumnDragging: false,
      enableExpanding: false,
      enableColumnActions: false,
      size: 20,
      Cell: ({ cell }) => (
        <>
          {cell.row.original.isAttach == true ? (
            <span>
              <i className="icon-link"></i>
            </span>
          ) : null}
        </>
      ),
    },
  ]);

  return (
    <Fragment>
      <Form className="theme-form">
        <div className="email-right-aside">
          <Card className="email-body">
            <div className="email-content">
              {tableData != null ? (
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm="6">
                            <div className="lbl-sum-Table">
                              <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                            </div>
                          </Col>

                          <Col sm="6">
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn-sm"
                                title="Delete"
                                onClick={() => {
                                  setAction("delete");
                                }}
                              >
                                <i className="icon-trash"></i>
                              </button>
                              {obj.messengerCategory != "Archive" ? (
                                <button
                                  type="button"
                                  className="btn-sm"
                                  title="Archive"
                                  onClick={() => {
                                    setAction("archive");
                                  }}
                                >
                                  <i className="icon-archive"></i>
                                </button>
                              ) : null}
                              <button
                                className="btn-sm"
                                type="button"
                                title="Move To"
                                onClick={() => {
                                  LoadFolder();
                                }}
                              >
                                <i className="fa fa-toggle-up"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <MaterialReactTable
                          enableStickyHeader
                          muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                          muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                              color: "#fff",
                              borderRadius: "0",
                              border: "0",
                              "& tbody tr:nth-of-type(odd)": {
                                backgroundColor: "#f5f5f5",
                              },
                              "& thead tr": {
                                backgroundColor: "#16449D",
                                color: "#fff",
                              },
                            },
                          }}
                          muiTableHeadCellProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableBodyCellProps={{
                            sx: (theme) => ({
                              fontSize: ".7rem",
                            }),
                          }}
                          muiTableHeadCellDragHandleProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellColumnActionsButtonProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          muiTableHeadCellFilterTextFieldProps={{
                            sx: (theme) => ({
                              color: "#fff",
                            }),
                          }}
                          getRowId={(originalRow) => (Mod == "Sent" ? originalRow.id : originalRow.messengerID)}
                          // getRowId={(originalRow) => originalRow.id}
                          columns={FolderName ? columnsFolder : obj.messengerCategory == "Sent" ? columnsSent : columnsInbox}
                          data={tableData}
                          enableColumnOrdering
                          enableExpanding={false}
                          enablePagination={false}
                          enableColumnResizing={true}
                          enableColumnDragging={false}
                          onRowSelectionChange={setRowSelection}
                          state={{ rowSelection }}
                          tableInstanceRef={tableInstanceRef}
                          rowNumberMode="static"
                          enableRowSelection={true}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Card>
        </div>
      </Form>
      <Modal isOpen={modalFolder}>
        <ModalHeader>Move To Folder</ModalHeader>
        <ModalBody>
          {folderList != null ? (
            <UL>
              {folderList.map((item) => {
                return (
                  <LI
                    key={item.id}
                    attrBtn={{
                      color: "light",
                      type: "button",
                      key: item.id,
                    }}
                    attrLI={{
                      onClick: (event) => {
                        event.preventDefault();
                        MoveToFolder(item.id);
                      },
                      className: "list-group-item-action",
                      tag: "a",
                      href: "#javascript",
                    }}
                  >
                    {item.title}
                  </LI>
                );
              })}
            </UL>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Btn
            attrBtn={{
              color: "light",
              type: "button",
              onClick: () => setmodalFolder(false),
            }}
          >
            Cancel
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default MailContain;
