import axios from "axios";
import { URL, Version } from "../URL";

const Api_BlogCategoryList = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BlogCategory/GetALl`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetBlogCategory_Parent = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BlogCategory/GetAll_Parent/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetBlogCategory = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/BlogCategory/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_EditBlogCategory = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/BlogCategory?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddBlogCategory = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/BlogCategory`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteBlogCategory = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/BlogCategory/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};

export { Api_BlogCategoryList, Api_GetBlogCategory, Api_EditBlogCategory, Api_AddBlogCategory, Api_DeleteBlogCategory, Api_GetBlogCategory_Parent };
