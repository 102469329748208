import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn, Spinner } from "../../../AbstractElements";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Api_EditCompany, Api_GetCompany } from "../../../api/Admin/Company";

const EditCompany = () => {
  const { CompanyID } = useParams();
  const history = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [obj, setobj] = useState({
    name: "",
    code: "",
    webSite: "",
    remarks: "",
    sort: 0,
    active: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await Api_GetCompany(CompanyID);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          setobj(response.data.data);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
          history(`${process.env.PUBLIC_URL}/admin/Company`);
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setLoadingBtn(true);
    const response = await Api_EditCompany(CompanyID, obj);
    setLoadingBtn(false);
    if (response.status == "200" && response.data.isSuccess == true) {
      toast.success("Information edited successfully");
      history(`${process.env.PUBLIC_URL}/admin/Company`);
    } else {
      if (response.response.status == "500") {
        toast.error(response.response.data.message);
      } else if (response.data && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        toast.error("Error");
      }
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Edit Company" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <InputGroup>
                            <Input name="name" className="form-control" value={obj.name || ""} onChange={onInputChange} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>{"Code"}</Label>
                          <InputGroup>
                            <Input name="code" className="form-control" value={obj.code || ""} onChange={onInputChange} required autoComplete="off" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"WebSite"}</Label>
                          <InputGroup>
                            <Input name="webSite" className="form-control" value={obj.webSite || ""} onChange={onInputChange} autoComplete="off" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>{"Sort"}</Label>
                          <InputGroup>
                            <Input
                              name="sort"
                              className="form-control"
                              value={obj.sort || ""}
                              onChange={onInputChange}
                              required
                              autoComplete="off"
                              type="number"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Remarks"}</Label>
                          <InputGroup>
                            <Input name="remarks" className="form-control" value={obj.remarks || ""} onChange={onInputChange} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Position"}</Label>
                          <label className="txt-danger Required">{"*"}</label>
                          <Input type="select" name="active" className="form-control" value={obj.active} onChange={onInputChange}>
                            <option value="1" key="1">
                              Active
                            </option>
                            <option value="0" key="0">
                              Inactive
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button type="submit" className="btn btn-success btn-md m-r-15">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </Button>
                        <Btn
                          attrBtn={{
                            color: "light",
                            type: "button",
                            onClick: () => history(`${process.env.PUBLIC_URL}/admin/Company`),
                          }}
                        >
                          Cancel
                        </Btn>
                      </Col>
                    </Row>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default EditCompany;
