import React, { Fragment, useState, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { Edit, Video, Activity, Film } from "react-feather";
import { Breadcrumbs, Image, P, H5, H4, LI, UL, H6, Btn } from "../../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Api_GetInfoAdviceQuestion } from "../../../api/Admin/AdviceQuestion";
import {
  Api_GetAdviceQuestionResponseList,
  Api_AddAdviceQuestionResponse,
  Api_DeleteAdviceQuestionResponse,
} from "../../../api/Admin/AdviceQuestionResponse";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";

const AdviceResponse = () => {
  const { adviceID } = useParams();
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [obj, setobj] = useState({
    id: 0,
    title: 0,
    text: "",
    user: "",
    dateAdd: "",
  });
  const [objResponse, setobjResponse] = useState({
    adviceQuestionID: adviceID,
    text: "",
  });
  useEffect(() => {
    if (adviceID) {
      GetDataForEdit(adviceID);
      LoadData();
    }
  }, []);
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetInfoAdviceQuestion(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setobj({
          text: response.data.data.text,
          title: response.data.data.adviceQuestionTitleCategoryTitle,
          user: response.data.data.user,
          dateAdd: response.data.data.dateAdd,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Advices`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const LoadData = async () => {
    try {
      setLoading(true);

      const response = await Api_GetAdviceQuestionResponseList(adviceID);
      console.log(JSON.stringify(response.data.data));
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const toggleEmojiPicker = () => {
    console.log("show");
    setShowEmojiPicker(!showEmojiPicker);
  };
  const addEmoji = (emoji) => {
    const text = `${objResponse.text}${emoji.emoji}`;
    setShowEmojiPicker(false);
    setobjResponse({
      ...objResponse,
      text: text,
    });
  };
  const handleMessageChange = (message) => {
    setobjResponse({
      ...objResponse,
      text: message,
    });
  };
  const handleMessagePress = async (e) => {
    if (e.key === "Enter" || e === "send") {
      if (objResponse.text.length > 0) {
        const response = await Api_AddAdviceQuestionResponse(objResponse);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Your answer has been recorded");
          setobjResponse({
            ...objResponse,
            text: "",
          });
          LoadData();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
          toast.error("Your desired information was not found");
          navigate(`${process.env.PUBLIC_URL}/admin/Advices`);
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    }
  };
  const toggle = () => setModal(!modal);
  const Delete = async (id) => {
    try {
      const response = await Api_DeleteAdviceQuestionResponse(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Response Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };
  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  return (
    <Fragment>
      <Breadcrumbs parent="Services Management" title="Advices Response" Loading={Loading} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="activity-media">
                  <Row>
                    <Col sm="12">
                      <div className="blog-single">
                        <div className="blog-box blog-details">
                          <Card>
                            <CardBody>
                              <div className="blog-details">
                                <UL
                                  attrUL={{
                                    className: "blog-social plan flex-row simple-list",
                                  }}
                                >
                                  <LI>Advice Submitted by : {obj.user}</LI>
                                  <LI>Date : {obj.dateAdd}</LI>
                                </UL>
                                <H4
                                  attrH4={{
                                    className: "plan-Title",
                                  }}
                                >
                                  <span>Title :</span> <span>{obj.title}</span>
                                </H4>
                                <>
                                  <div className="plan-Title">
                                    <H4>
                                      <span> Advice :</span>
                                    </H4>

                                    <div className="box-readmore" dangerouslySetInnerHTML={{ __html: obj.text }}></div>
                                  </div>
                                </>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
              <CardBody>
                {tableData != null ? (
                  <VerticalTimeline layout={"1-column"}>
                    <div className="activity-media user-NoBack">
                      {tableData.map((item) => {
                        return (
                          <VerticalTimelineElement
                            key={item.id}
                            className="vertical-timeline-element--work"
                            animate={true}
                            date={item.dateAdd}
                            icon={
                              <Image
                                attrImage={{
                                  className: "img-40 rounded-circle",
                                  alt: "",
                                  src: `${item.userImageName}`,
                                }}
                              />
                            }
                          >
                            {item.allowEdit == true ? (
                              <div className="boxTrash">
                                <i
                                  onClick={() => {
                                    setSelectedRowID(item.id);
                                    toggle();
                                  }}
                                  className="fa fa-trash"
                                ></i>
                              </div>
                            ) : null}
                            <H5 attrH4={{ className: "vertical-timeline-element-subtitle" }}>
                              {item.userName} {item.userFamily}
                            </H5>

                            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                          </VerticalTimelineElement>
                        );
                      })}
                    </div>
                  </VerticalTimeline>
                ) : null}
              </CardBody>
              <CardBody>
                <Row className="chat-box">
                  <Col className="chat-right-aside">
                    <div className="chat">
                      <div className="chat-message clearfix">
                        <Row>
                          <div>{showEmojiPicker ? <EmojiPicker set="apple" emojiSize={30} onEmojiClick={addEmoji} /> : null}</div>
                          <Col xl="12" className="d-flex">
                            <div className="smiley-box bg-primary">
                              <div className="picker" onClick={() => toggleEmojiPicker()}>
                                <Image attrImage={{ src: `${require("../../../assets/images/smiley.png")}`, alt: "" }} />
                              </div>
                            </div>
                            <InputGroup className="text-box">
                              <Input
                                type="text"
                                className="form-control input-txt-bx"
                                placeholder="Type a message......"
                                value={objResponse.text}
                                onKeyPress={(e) => handleMessagePress(e)}
                                onChange={(e) => handleMessageChange(e.target.value)}
                              />
                              <Btn attrBtn={{ color: "primary", onClick: () => handleMessagePress("send") }}>Send</Btn>
                            </InputGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default AdviceResponse;
