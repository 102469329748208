import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import {
  Api_AddProducerCompany,
  Api_DeleteProducerCompany,
  Api_EditProducerCompany,
  Api_GetProducerCompany,
  Api_ProducerCompanyList,
} from "../../../api/Admin/ProducerCompany";
import CKEditors from "react-ckeditor-component";
import { Api_NationalList } from "../../../api/Admin/National";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
import { Api_GetChildsByCode } from "../../../api/Admin/Filling";
import { Api_DisciplineList } from "../../../api/Admin/Discipline";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import { Api_StaticTextList, Api_GetStaticText, Api_EditStaticText } from "../../../api/Admin/StaticText";
const StaticTex = () => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListCompany, setListCompany] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [ListNatioanl, setListNatioanl] = useState([]);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [sumRows, setsumRows] = useState(0);
  const [ListKind, setListKind] = useState(null);
  const [ListDiscipline, setListDiscipline] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    title: "",
    code: "",
    body: "",
    companyID: DefaultCompanyID,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadCompanyList();
    LoadData(DefaultCompanyID);
  }, []);

  const LoadData = async (id) => {
    try {
      setLoading(true);

      const response = await Api_StaticTextList(id);
      console.log(response);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setTableData(response.data.data);
          setsumRows(response.data.data.length);
        } else {
          setTableData(null);
          setsumRows(0);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };
  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
      LoadData(val);
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
      LoadData(null);
    }
  };

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      console.log("obj:" + JSON.stringify(obj));
      const response = await Api_EditStaticText(SelectedRowID, obj);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setLoadingBtn(false);
        toast.success("Information edited successfully");
        setSelectedRowID(0);
        LoadData(obj.companyID);
        ResetForm();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoadingBtn(false);
    } catch (error) {
      toast.error("Error");
      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setobj({
      ...obj,
      body: newContent,
    });
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetStaticText(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          title: response.data.data.title,
          code: response.data.data.code,
          id: response.data.data.id,
          body: response.data.data.body,
          companyID: response.data.data.companyID,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      title: "",
      code: "",
      body: "",
    });
    setSelectedRowID(0);
    setListDiscipline(null);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "code",
        header: "Code",
      },

      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Static Text" Loading={Loading} />

          {SelectedRowID != 0 ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <Input
                            type="select"
                            name="select"
                            className="form-control digits"
                            value={obj.companyID}
                            onChange={(txt) => {
                              onChangeCompany(txt.target.value);
                            }}
                          >
                            {ListCompany != null
                              ? ListCompany.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input name="title" className="form-control" maxLength={100} value={obj.title} readOnly={true} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                          <InputGroup>
                            <Input name="code" className="form-control" readOnly={true} maxLength={10} value={obj.code} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Body"}</Label>
                          <CKEditors
                            activeclassName="p10"
                            content={obj.body}
                            events={{
                              change: onChange,
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {SelectedRowID == 0 ? (
                          <Button type="submit" className="btn btn-success btn-md">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Add"
                            )}
                          </Button>
                        ) : (
                          <>
                            <Button type="submit" className="btn btn-success btn-md m-r-15">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Save"
                              )}
                            </Button>
                            {LoadingBtn == false ? (
                              <Btn
                                attrBtn={{
                                  color: "light",
                                  type: "button",
                                  onClick: () => ResetForm(),
                                }}
                              >
                                Cancel
                              </Btn>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      className="Table-Style-1"
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableRowNumbers
                      rowNumberMode="static"
                      enableExpanding
                      onRowSelectionChange={setRowSelection}
                      enablePagination={false}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
    </Fragment>
  );
};

export default StaticTex;
