import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import {
  Api_AddJobPosition,
  Api_EditJobPosition,
  Api_JobPositionList,
  Api_DeleteJobPosition,
  Api_GetJobPosition,
} from "../../../api/Admin/JobPosition";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { URL, Version } from "../../../api/URL";
import MaterialReactTable from "material-react-table";
const ListJobPosition = () => {
  const navigate = useNavigate();
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    try {
      const response = await Api_JobPositionList();
      if (response.data.isSuccess == true && response.status == 200) {
        if (response.data.data != "") {
          setTableData(response.data.data);
        } else {
          setTableData(null);
        }
      } else if (response.data.isSuccess == false && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
        setTimeout(() => {
          navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
        }, 1000);
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddJobPosition(obj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Job Position has been added");
          LoadData();
          setobj({
            ...obj,
            description: "",
            code: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        const response = await Api_EditJobPosition(SelectedRowID, obj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadData();
          setobj({
            ...obj,
            description: "",
            code: "",
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    try {
      const response = await Api_DeleteJobPosition(id);
      setModal(false);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Job Position Is Removed");
        RemoveFromList(id);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
        setModal(false);
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };

  const RemoveFromList = async (id) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== id);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    } finally {
      setSelectedRowID(0);
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetJobPosition(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          remarks: response.data.data.remarks,
          companyID: response.data.data.companyID,
          id: response.data.data.id,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      id: 0,
    });
    setSelectedRowID(0);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Job Position" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="10">
                      <FormGroup>
                        <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input
                            name="description"
                            autoComplete="off"
                            className="form-control"
                            value={obj.description}
                            onChange={onInputChange}
                            required
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} autoComplete="off" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListJobPosition;
