import React, { useEffect, useState, Fragment, useContext } from "react";
import {
  Api_AddRole,
  Api_DeleteRole,
  Api_EditRole,
  Api_GetRole,
  Api_RoleListAll,
  Api_RolePermisionList,
  Api_PermisionList,
} from "../../../api/Admin/Role";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ScrollBar from "react-perfect-scrollbar";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";

const RoleList = () => {
  const navigate = useNavigate();
  const [PermisionList, setPermisionList] = useState([]);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [modalRole, setmodalRole] = useState(false);
  const [obj, setobj] = useState({
    id: 0,
    name: "",
    sort: 0,
    description: "",
    listRole: [],
  });

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async (id) => {
    const response = await Api_RoleListAll(id);
    if (response.status && response.status == 200 && response.data.isSuccess == true) {
      if (response.data.data != "") {
        setTableData(response.data.data);
      } else {
        setTableData(null);
      }

      const { data: response5 } = await Api_PermisionList();
      setPermisionList(response5.data);
    } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
      toast.error("You do not have access permission");
    } else if (response.response && response.response.data.isSuccess == false) {
      toast.error(response.response.data.message);
    } else {
      toast.error("Error - 1");
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const toggleRole = () => setmodalRole(!modalRole);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setLoadingBtn(true);
    if (SelectedRowID == 0) {
      const response = await Api_AddRole(obj);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("A new Access Level has been added");
        ResetForm();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
      setLoadingBtn(false);
    } else {
      const response = await Api_EditRole(SelectedRowID, obj);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Information edited successfully");
        ResetForm();
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoadingBtn(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const Delete = async (id) => {
    const response = await Api_DeleteRole(id);
    if (response.status && response.status == 200 && response.data.isSuccess == true) {
      toast.success("Access Level Is Removed");
      ResetForm();
    } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
      toast.error("You do not have access permission");
    } else if (response.response && response.response.data.isSuccess == false) {
      toast.error(response.response.data.message);
    } else {
      toast.error("Error - 1");
    }
    setModal(false);
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetRole(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        const { data: response5 } = await Api_RolePermisionList(id);
        setPermisionList(response5.data);

        setobj({
          name: response.data.data.name,
          description: response.data.data.description,
          id: response.data.data.id,
          listRole: response5.data,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      name: "",
      sort: 0,
      description: "",
      id: 0,
      listRole: [],
    });
    setSelectedRowID(0);
    LoadData();
  };

  const onChangeRole = async (id, name, val) => {
    const AllData = [...PermisionList];
    const DataIndex = AllData.findIndex((p) => p.id == id);
    const TempData = AllData[DataIndex];
    if (name == "see") {
      TempData.see = val;
    } else if (name == "add") {
      TempData.add = val;
    } else if (name == "delete") {
      TempData.delete = val;
    } else if (name == "fileAttach") {
      TempData.fileAttach = val;
    } else TempData.edit = val;
    AllData[DataIndex] = TempData;
    setPermisionList(AllData);
    setobj({
      ...obj,
      listRole: AllData,
    });
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Access Levels" />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <FormGroup>
                        <Label>{"Name"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="name" autoComplete="off" className="form-control" value={obj.name || ""} onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <InputGroup>
                          <Input name="sort" className="form-control" value={obj.sort || "0"} type="number" onChange={onInputChange} required />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"Permission Level"}</Label>
                        <InputGroup>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () => toggleRole(),
                            }}
                          >
                            ...
                          </Btn>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="bg-primary">
                          <tr>
                            <th scope="col">{"Name"}</th>
                            <th scope="col">{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>
                                <>
                                  <span>
                                    <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(item.id)}>
                                      Edit
                                    </Button>
                                  </span>
                                  <span>
                                    <Button
                                      className="btn btn-danger btn-xs"
                                      onClick={() => {
                                        setSelectedRowID(item.id);
                                        toggle();
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </span>
                                </>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => Delete(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalRole}>
        <ModalHeader>User Permission Level Edit</ModalHeader>
        <ModalBody>
          <Row>
            <div className="col-sm-12">
              <div className="card">
                <ScrollBar className="vertical-scroll both-side-scroll">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">See</th>
                          <th scope="col">Add</th>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                          <th scope="col">File Attach</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PermisionList.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td className="p-0" scope="row">
                                {item.deep == null ? (
                                  <span style={{ fontWeight: "bold", display: "block" }}>{item.title}</span>
                                ) : item.deep == 1 ? (
                                  <span style={{ display: "block", marginLeft: 5 }}>{item.title}</span>
                                ) : item.deep == 2 ? (
                                  <span style={{ display: "block", marginLeft: 10 }}>{item.title}</span>
                                ) : null}
                              </td>
                              <td className="p-0 text-center">
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.id + "checkboxSee"}
                                      type="checkbox"
                                      defaultChecked={item.see}
                                      onClick={() => onChangeRole(item.id, "see", !item.see)}
                                    />
                                    <Label className="text-muted" for={item.id + "checkboxSee"}></Label>
                                  </div>
                                </FormGroup>
                              </td>
                              <td className="p-0 text-center">
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.id + "checkboxAdd"}
                                      type="checkbox"
                                      disabled={!item.enable}
                                      defaultChecked={item.add}
                                      onClick={() => onChangeRole(item.id, "add", !item.add)}
                                    />
                                    <Label className="text-muted" for={item.id + "checkboxAdd"}></Label>
                                  </div>
                                </FormGroup>
                              </td>
                              <td className="p-0 text-center">
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.id + "checkboxEdit"}
                                      type="checkbox"
                                      disabled={!item.enable}
                                      defaultChecked={item.edit}
                                      onClick={() => onChangeRole(item.id, "edit", !item.edit)}
                                    />
                                    <Label className="text-muted" for={item.id + "checkboxEdit"}></Label>
                                  </div>
                                </FormGroup>
                              </td>
                              <td className="p-0 text-center">
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.id + "checkboxDelete"}
                                      type="checkbox"
                                      disabled={!item.enable}
                                      defaultChecked={item.delete}
                                      onClick={() => onChangeRole(item.id, "delete", !item.delete)}
                                    />
                                    <Label className="text-muted" for={item.id + "checkboxDelete"}></Label>
                                  </div>
                                </FormGroup>
                              </td>
                              <td className="p-0 text-center">
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.id + "checkboxfileAttach"}
                                      type="checkbox"
                                      disabled={!item.enableFileAttach}
                                      defaultChecked={item.fileAttach}
                                      onClick={() => onChangeRole(item.id, "fileAttach", !item.fileAttach)}
                                    />
                                    <Label className="text-muted" for={item.id + "checkboxfileAttach"}>
                                      {item.enableFileAttach}
                                    </Label>
                                  </div>
                                </FormGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </ScrollBar>
              </div>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleRole}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default RoleList;
