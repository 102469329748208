import axios from "axios";
import { URL, Version } from "../URL";
const Api_GetAdviceQuestionResponseList = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/AdviceQuestionResponse/GetAll/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddAdviceQuestionResponse = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/AdviceQuestionResponse`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteAdviceQuestionResponse = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/AdviceQuestionResponse/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};
export { Api_GetAdviceQuestionResponseList, Api_AddAdviceQuestionResponse, Api_DeleteAdviceQuestionResponse };
