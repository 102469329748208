import axios from "axios";
import { URL, Version } from "../URL";

const Api_AdviceQuestionList = async (data) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.post(`${URL}${Version}/AdviceQuestion/GetAll`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetAdviceQuestion = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/AdviceQuestion/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetInfoAdviceQuestion = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/AdviceQuestion/GetInfo/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditAdviceQuestion = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/AdviceQuestion?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddAdviceQuestion = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/AdviceQuestion`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteAdviceQuestion = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/AdviceQuestion/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (er) {
    return er;
  }
};

export {
  Api_GetInfoAdviceQuestion,
  Api_AdviceQuestionList,
  Api_GetAdviceQuestion,
  Api_EditAdviceQuestion,
  Api_AddAdviceQuestion,
  Api_DeleteAdviceQuestion,
};
