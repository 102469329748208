import React, { useEffect, useState, Fragment, useMemo, useRef, useContext } from "react";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import {
  Api_DepartmentList,
  Api_AddDepartment,
  Api_DeleteDepartment,
  Api_GetDepartment,
  Api_EditDepartment,
  Api_DepartmentList_Parent,
} from "../../../api/Admin/Department";
import { Btn, H6, Image, P, Spinner, H5 } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MaterialReactTable from "material-react-table";
import { URL, Version } from "../../../api/URL";
import fileDownload from "js-file-download";
import DeniReactTreeView from "deni-react-treeview";
import { Api_GethasDepartmentChart, Api_GetViewDepartmentChart } from "../../../api/Admin/Document";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const ListDepartment = () => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [ParentCategoryId, setParentCategoryId] = useState(0);
  const [modalparent, setmodalparent] = useState(false);
  const [parentName, setparentName] = useState("");
  const [Loading, setLoading] = useState(false);
  const [sumRows, setsumRows] = useState(0);
  const [FileName, setFileName] = useState("");
  const [FileID, setFileID] = useState("");
  const [fileext, setfileext] = useState("");
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [ListCompany, setListCompany] = useState(null);
  const [ListParent, setListParent] = useState(null);
  const [modalupload, setmodalupload] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [obj, setobj] = useState({
    id: 0,
    description: "",
    code: "",
    companyID: DefaultCompanyID,
    remarks: "",
    parentCategoryId: null,
    sort: 0,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  useEffect(() => {
    LoadCompanyList();
    onChangeCompany(obj.companyID);
  }, []);

  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();

      setListCompany(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const themes = ["classic"];
  const LoadDepartmentList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_DepartmentList(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setTableData(response.data.data);
        CreateParentList(response.data.data);
        setsumRows(response.data.data.length);
        const response2 = await Api_DepartmentList_Parent(id);
        setListParent(response2.data.data);

        setLoading(false);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const onChangeCompany = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        companyID: val,
      });
      LoadDepartmentList(val);
    } else {
      setobj({
        ...obj,
        companyID: 0,
      });
      setTableData(null);
    }
  };

  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        const response = await Api_AddDepartment(obj);

        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new department has been added");

          LoadDepartmentList(obj.companyID);
          setSelectedRowID(0);
          setobj({
            ...obj,
            description: "",
            code: "",
            remarks: "",
            sort: 0,
            id: 0,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }

        setLoadingBtn(false);
      } else {
        const response = await Api_EditDepartment(SelectedRowID, obj);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          LoadDepartmentList(obj.companyID);
          setParentCategoryId(0);
          setparentName("");
          setobj({
            ...obj,
            description: "",
            code: "",
            remarks: "",
            sort: 0,
            id: 0,
            parentCategoryId: null,
          });
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }

        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const CreateParentList = async (data) => {
    if (data != null) {
      const updatedContact = data.filter((c) => c.parentCategoryId == null);
      setListParent(updatedContact);
    } else {
      setListParent(null);
    }
  };

  const DeleteDepartment = async (id) => {
    try {
      const response = await Api_DeleteDepartment(id);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        toast.success("Department Is Removed");
        LoadDepartmentList(obj.companyID);
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setModal(false);
    } catch (error) {
      setModal(false);
      toast.error("Error - 0");
    }
  };

  const GetDataForEdit = async (id) => {
    try {
      const response = await Api_GetDepartment(id);

      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          description: response.data.data.description,
          code: response.data.data.code,
          remarks: response.data.data.remarks,
          parentCategoryId: response.data.data.parentCategoryId,
          companyID: response.data.data.companyID,
          id: response.data.data.id,
          sort: response.data.data.sort,
        });
        if (response.data.data.parentCategoryId == null) {
          setParentCategoryId(0);
          setparentName("");
        } else {
          setParentCategoryId(response.data.data.parentCategoryId);
          setparentName(response.data.data.parentCategoryDescription);
        }
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }
    } catch (err) {
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      description: "",
      code: "",
      remarks: "",
      parentCategoryId: null,
      id: 0,
    });
    setSelectedRowID(0);
    setParentCategoryId(0);
    setparentName("");
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Chart has been successfully uploaded");
        setmodalupload(false);
      } else {
        toast.error("Chart could not be loaded");
      }
    }
  };

  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");

    return {
      url: `${URL}${Version}/Document/UploadDepartmentChart/${SelectedRowID}`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };

  const ViewFile = async (id) => {
    const response = await Api_GethasDepartmentChart(id);

    if (response.status == "200" && response.data.statusCode == "Success") {
      setFileID(response.data.data.id);
      setfileext(response.data.data.fileExt);
    } else if (response.status == "200" && response.data.statusCode == "NotFound") {
      setFileID("");
      setfileext("");
      setFileName("");
    } else {
      setFileID("");
      setfileext("");
      setFileName("");
    }
  };

  const DownloadFile = async () => {
    const response = await Api_GetViewDepartmentChart(SelectedRowID);
    fileDownload(response.data, FileName + fileext);
    if (response.status == "200") {
    } else {
      setFileID("");
      setfileext("");
      setFileName("");
    }
  };
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      Description: item.Description,
      Code: item.Code,
      Remarks: item.Remarks,
    }));
    exportToExcel(customHeadings, "Company Departments");
  };
  const handleExportPdf = () => {
    const doc = new jsPDF();
    let RowNumber = 1;
    const customRows = tableData.map((item) => [RowNumber++, item.Description, item.Code, item.Remarks]);
    autoTable(doc, {
      head: [["Row Number", "Description", "Code", "Remarks"]],
      body: customRows,
    });
    doc.text("Company Departments", 14, 10);
    doc.save("Company Departments.pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "Description",
        header: "Description",
      },
      {
        accessorKey: "Code",
        header: "Code",
      },
      {
        header: "Chart",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <i
            className="fa fa-file-image-o"
            onClick={() => {
              setFileName(cell.row.original.Description);
              setmodalupload(true);
              ViewFile(cell.row.id);
              setSelectedRowID(cell.row.id);
            }}
          ></i>
        ),
      },
      {
        accessorKey: "Remarks",
        header: "Remarks",
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => GetDataForEdit(cell.row.id)}>
                Edit
              </Button>
            </span>
            <span>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectedRowID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  const onSelectItem = async (item) => {
    try {
      setparentName(item.text);
      setParentCategoryId(item.id);
      setobj({
        ...obj,
        parentCategoryId: item.id,
      });
      setmodalparent(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onRemoveParent = async () => {
    setparentName("");
    setParentCategoryId(0);
    setobj({
      ...obj,
      parentCategoryId: null,
    });
    setmodalparent(false);
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Basic Data Management" title="Company Departments" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          value={obj.companyID}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {obj.companyID != 0 ? (
                    <>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Description"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input name="description" className="form-control" value={obj.description} onChange={onInputChange} required />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Parent"}</Label>
                            {ListParent != null ? (
                              <Input
                                className="form-control"
                                value={parentName}
                                onClick={() => {
                                  setmodalparent(true);
                                }}
                                readOnly={true}
                              />
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label>{"Code"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input name="code" className="form-control" value={obj.code} onChange={onInputChange} required autoComplete="off" />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <FormGroup>
                            <Label>{"Sort"}</Label> <label className="txt-danger Required">{"*"}</label>
                            <InputGroup>
                              <Input
                                name="sort"
                                className="form-control"
                                value={obj.sort}
                                onChange={onInputChange}
                                type="number"
                                required
                                autoComplete="off"
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="10">
                          <FormGroup>
                            <Label>{"Remarks"}</Label>
                            <InputGroup>
                              <Input name="remarks" className="form-control" value={obj.remarks} onChange={onInputChange} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          {SelectedRowID == 0 ? (
                            <Button type="submit" className="btn btn-success btn-md">
                              {LoadingBtn ? (
                                <div className="loader-box2">
                                  <Spinner attrSpinner={{ className: "loader-7" }} />
                                </div>
                              ) : (
                                "Add"
                              )}
                            </Button>
                          ) : (
                            <>
                              <Button type="submit" className="btn btn-success btn-md m-r-15">
                                {LoadingBtn ? (
                                  <div className="loader-box2">
                                    <Spinner attrSpinner={{ className: "loader-7" }} />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                              {LoadingBtn == false ? (
                                <Btn
                                  attrBtn={{
                                    color: "light",
                                    type: "button",
                                    onClick: () => ResetForm(),
                                  }}
                                >
                                  Cancel
                                </Btn>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {tableData != null ? (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="lbl-sum-Table">
                      <Row>
                        <Col md="6">
                          <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                        </Col>
                        <Col md="6 text-right" className="export-iconList">
                          <i
                            onClick={() => {
                              handleExportExcel();
                            }}
                            className="fa fa-file-excel-o"
                          ></i>
                          <i
                            onClick={() => {
                              handleExportPdf();
                            }}
                            className="fa fa-file-pdf-o"
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    <MaterialReactTable
                      enableStickyHeader
                      muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                      muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                          color: "#fff",
                          borderRadius: "0",
                          border: "0",
                          "& tbody tr:nth-of-type(odd)": {
                            backgroundColor: "#f5f5f5",
                          },
                          "& thead tr": {
                            backgroundColor: "#16449D",
                            color: "#fff",
                          },
                        },
                      }}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellDragHandleProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellColumnActionsButtonProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      muiTableHeadCellFilterTextFieldProps={{
                        sx: (theme) => ({
                          color: "#fff",
                        }),
                      }}
                      getRowId={(originalRow) => originalRow.Id}
                      columns={columns}
                      data={tableData}
                      enableColumnOrdering
                      enableExpanding
                      enablePagination={false}
                      onRowSelectionChange={setRowSelection}
                      state={{ rowSelection }}
                      tableInstanceRef={tableInstanceRef}
                      enableRowNumbers
                      rowNumberMode="static"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteDepartment(SelectedRowID)}>
            Yes
          </Button>
          <Button color="light" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalupload}>
        {FileID != "" ? (
          <ModalHeader>
            <Row>
              <Col sm="12">
                <Button color="primary" onClick={DownloadFile}>
                  Download {FileName} Chart
                </Button>
              </Col>
            </Row>
          </ModalHeader>
        ) : null}

        <ModalBody>
          <Row>
            <Col sm="12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept=".jpg,.pdf,.jpeg"
                inputContent="Drop files here or click to upload."
                styles={{
                  dropzone: { width: "100%", height: 50 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setmodalupload(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalparent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItem.bind(this)}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListParent}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {ParentCategoryId > 0 ? (
            <Button color="danger" onClick={() => onRemoveParent()}>
              Remove Parent
            </Button>
          ) : null}
          <Button color="light" onClick={() => setmodalparent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListDepartment;
